import { useEffect, useState } from 'react'
import Loader from './components/Loader'
import useAppDispatch from './hooks/useAppDispatch'
import useAppSelector from './hooks/useAppSelector'
import { getUserInfo } from './store/user/user.actions'
import { Routing } from './components/Routing'
import { addLocale, locale } from 'primereact/api'
import http from './http'
import { actions } from './store/user/user.slice'
import {
  connect,
  connectToMessages,
  connectToNotifications,
  connectToPractices,
  disconnect,
  Topic,
} from './utils/ws/ws'
import { baseApi } from './api'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

const {
  setProfileCompleted,
  addNotification,
  removeNotification,
  addMessage,
  removeMessage,
  addPractice,
  removePractice,
} = actions

function App() {
  const { user, isLoading, profileCompleted } = useAppSelector(
    state => state.userReducer
  )
  const [triedToLogin, setTriedToLogin] = useState(false)
  const dispatch = useAppDispatch()

  addLocale('ru', {
    firstDayOfWeek: 1,
    dayNames: [
      'воскресенье',
      'понедельник',
      'втроник',
      'среда',
      'четверг',
      'пятница',
      'суббота',
    ],
    dayNamesShort: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'],
    dayNamesMin: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'],
    monthNames: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    monthNamesShort: [
      'Янв',
      'Фев',
      'Мар',
      'Апр',
      'Май',
      'Июн',
      'Июл',
      'Авг',
      'Сен',
      'Окт',
      'Ноя',
      'Дек',
    ],
    startsWith: 'Начинается c',
    matchAll: 'Все',
    matchAny: 'Один из',
    today: 'Сегодня',
    clear: 'Убрать',
    apply: 'Применить',
    accept: 'Да',
    reject: 'Нет',
    addRule: 'Добавить правило',
    emptyMessage: 'Нет данных',
    contains: 'Содержит',
    notContains: 'Не содержит',
    endsWith: 'Заканчивается на',
    equals: 'Равняется',
    notEquals: 'Не равняется',
    emptyFilterMessage: 'Ничего не найдено',
  })

  locale('ru')

  useEffect(() => {
    if (localStorage.getItem('refresh-token')) {
      dispatch(getUserInfo())
        .unwrap()
        .then(response => {
          const role = response.role
          const topics: Array<Topic> = []
          topics.push(
            ...connectToNotifications(
              response.id,
              notification => dispatch(addNotification(notification)),
              notifications => dispatch(removeNotification(notifications))
            )
          )
          if (role === 'student') {
            topics.push(
              ...connectToPractices(
                response.id,
                () => {
                  dispatch(addPractice())
                  dispatch(baseApi.util.invalidateTags(['Practice']))
                },
                () => {
                  dispatch(removePractice())
                  dispatch(baseApi.util.invalidateTags(['Practice']))
                }
              )
            )
          }
          if (role !== 'admin' && role !== 'student')
            topics.push(
              ...connectToMessages(
                response.id,
                () => {
                  dispatch(addMessage())
                  dispatch(baseApi.util.invalidateTags(['ChatMessage']))
                },
                () => {
                  dispatch(removeMessage())
                  dispatch(baseApi.util.invalidateTags(['ChatMessage']))
                }
              )
            )
          connect(topics)
        })
    }

    setTriedToLogin(true)
    return () => {
      disconnect()
    }
  }, [])

  useEffect(() => {
    if (user.id === -1) return
    if (user.role === 'student') {
      async function checkProfileCompleted() {
        const { data } = await http.get('/students/checkIsRegistrationUser', {
          params: {
            id: user.id,
          },
        })

        dispatch(setProfileCompleted(data))
      }

      checkProfileCompleted()
    }
  }, [user])

  if (isLoading || !triedToLogin) {
    return <Loader />
  }

  return (
    <>
      <ToastContainer />
      <Routing role={user.role} profileCompleted={profileCompleted} />
    </>
  )
}

export default App
