import {
  DataTable as PrimeDataTable,
  DataTablePFSEvent,
  DataTableProps as PrimeDataTableProps,
} from 'primereact/datatable'
import { useMemo, useState } from 'react'

export interface DatatableResponse<T> {
  data: {
    content: T
  }
  metaData: {
    totalRecordsElement: number
    totalOffset: number
  }
}

export interface DataTableArgs {
  offset: number
  limit: number
}

export interface DataTableConfig extends DataTableArgs {
  totalRecords: number
}

interface DataTableProps extends PrimeDataTableProps {
  config?: DataTableConfig
  onPagination?: (dataTableArgs: DataTableArgs) => void
}

export const usePagination = () => {
  return useState<DataTableArgs>({
    offset: 1,
    limit: 10,
  })
}

export const DataTable = ({
  onPagination,
  config,
  ...rest
}: DataTableProps) => {
  function onPage(e: DataTablePFSEvent) {
    if (typeof onPagination !== 'function') return
    onPagination({
      offset: e.page ? e.page + 1 : 1,
      limit: e.rows,
    })
  }
  const first = useMemo(() => {
    if (!config) return 0
    return (config?.offset - 1) * config?.limit || 0
  }, [config])
  return (
    <PrimeDataTable
      responsiveLayout={'scroll'}
      paginator
      removableSort
      lazy={Boolean(config)}
      first={first}
      rows={config ? config?.limit : 10}
      totalRecords={config?.totalRecords}
      rowsPerPageOptions={[5, 10, 25]}
      onPage={config ? onPage : undefined}
      {...rest}
    />
  )
}
