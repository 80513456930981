import { baseApi } from '../../baseApi'
import {
  GetShortStatisticsArg,
  GetShortStatisticsResult,
  GetStatisticsArg,
  GetStatisticsResult,
} from './statistics.types'

const api = baseApi.injectEndpoints({
  endpoints: builder => ({
    getShortStatistics: builder.query<
      GetShortStatisticsResult,
      GetShortStatisticsArg
    >({
      providesTags: ['Practice'],
      query: arg => {
        return {
          url: 'statistics/getShortStatistics',
          method: 'get',
          params: arg,
          errorMessage:
            'Не удалось получить информацию о практических работах!',
        }
      },
    }),
    getStatistics: builder.query<GetStatisticsResult, GetStatisticsArg>({
      providesTags: ['Practice'],
      query: arg => ({
        url: 'statistics/getStatistics',
        params: arg,
        method: 'get',
        errorMessage:
          'Не удалось получить информацию о количестве практических работ!',
      }),
    }),
  }),
})

export const { useGetShortStatisticsQuery, useGetStatisticsQuery } = api
