import React, { cloneElement, ReactNode } from 'react'
import { FormikProps } from 'formik'
import { classNames } from 'primereact/utils'

interface FormItem<T> {
  formik: FormikProps<T>
  name: keyof T
  label: ReactNode
  children: ReactNode
  value: any
}

export const FormItem = <Form,>({
  formik,
  name,
  value,
  label,
  children,
}: FormItem<Form>) => {
  const isFormFieldValid = (name: keyof Form) =>
    !!(formik.touched[name] && formik.errors[name])
  const getFormErrorMessage = (name: keyof Form) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    )
  }

  return (
    <div className="field">
      <span className="p-float-label">
        {cloneElement(children, {
          onChange: formik.handleChange,
          id: name,
          value,
          name,
          className: classNames(children?.props?.className, {
            'p-invalid': isFormFieldValid(name),
          }),
        })}
        <label
          htmlFor={name}
          className={classNames({
            'p-error': isFormFieldValid(name),
          })}
        >
          {label}
        </label>
      </span>
      {getFormErrorMessage(name)}
    </div>
  )
}
