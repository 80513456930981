import { StudentLayout } from '../../../components/Layout/student/StudentLayout'
import { Card } from '../../../components/Card/Card'
import { Column } from 'primereact/column'
import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import { requireField } from '../../../constants/constants'
import { Toast } from 'primereact/toast'
import { NotificationTypes, notify } from '../../../utils/notify'
import { Modal } from '../../../components/Modal/Modal'
import Loader from '../../../components/Loader'
import { InputText } from 'primereact/inputtext'
import { DataTable, UserTemplate } from '../../../components/DataTable'
import {
  AddStudentAccountArg,
  StudentResult,
  UpdateStudentAccountArg,
  useAddStudentAccountMutation,
  useGetStudentsQuery,
  useUpdateStudentAccountMutation,
} from '../../../api/endpoints/students'
import { FormItem } from '../../../components/Form'
import { useGetGroupsQuery } from '../../../api'
import { Dropdown } from '../../../components/Dropdown'
import { map } from 'lodash'
import { generatePass } from '../../../utils/password/password'
import { Toolbar } from '../../../components/Toolbar'
import { EduPaths } from '../../../components/Routing/Routing.types'

export const Students = () => {
  const [modal, setModal] = useState(false)
  const toast = useRef<Toast>(null)
  const { data, isLoading, isFetching } = useGetStudentsQuery()
  const [add, { isLoading: isAddLoading }] = useAddStudentAccountMutation()
  const [update, { isLoading: isUpdateLoading }] =
    useUpdateStudentAccountMutation()
  const [initialState, setInitialState] =
    useState<UpdateStudentAccountArg | null>(null)

  function onCreate(form: Form) {
    return add(form)
      .unwrap()
      .then(() => {
        notify(
          toast,
          {
            type: NotificationTypes.success,
            content: 'Вы успешно добавили студента!',
          },
          true
        )
      })
  }

  function onUpdate(form: UpdateStudentAccountArg) {
    return update(form)
      .unwrap()
      .then(() => {
        setModal(false)
        setInitialState(null)
        notify(
          toast,
          {
            type: NotificationTypes.success,
            content: 'Вы успешно обновили студента!',
          },
          true
        )
      })
  }

  return (
    <StudentLayout>
      <Helmet title={'Студенты'} />
      <Toast ref={toast} />
      <Modal
        header={`${initialState ? 'Обновление' : 'Добавление'} студента`}
        visible={modal}
        onHide={() => {
          setInitialState(null)
          setModal(false)
        }}
      >
        <ModalContent
          initialState={initialState}
          onSubmit={initialState ? onUpdate : onCreate}
          update={initialState?.studentId}
          loading={initialState ? isUpdateLoading : isAddLoading}
        />
      </Modal>
      <Card headerClassName={'mt-24'} header={'Студенты'}>
        {isLoading ? (
          <Loader />
        ) : (
          <DataTable
            header={
              <Toolbar
                add={{ onClick: () => setModal(true), loading: isAddLoading }}
              />
            }
            loading={isFetching}
            value={data}
            dataKey={'id'}
          >
            <Column
              filter
              sortable
              field={'name'}
              header="Имя"
              body={rowData => (
                <UserTemplate
                  rowData={rowData}
                  link={`${EduPaths.statistics}/${rowData.id}`}
                />
              )}
            />
            <Column filter sortable field={'surname'} header="Фамилия" />
            <Column filter sortable field={'patronymic'} header="Отчество" />
            <Column filter sortable field={'group'} header="Группа" />
            <Column filter sortable field={'login'} header="Логин" />
            <Column filter sortable field={'phoneNumber'} header="Телефон" />
            <Column filter sortable field={'email'} header="Почта" />
            <Column filter sortable field={'address'} header="Адрес" />
            <Column
              body={row => {
                const rowData = row as StudentResult
                return (
                  <Button
                    icon="pi pi-pencil"
                    onClick={() => {
                      setModal(true)
                      setInitialState({
                        studentId: rowData.id,
                        curGroup: rowData.curGroup,
                        contract: rowData.contractNumber,
                        password: null,
                      })
                    }}
                  />
                )
              }}
            />
          </DataTable>
        )}
      </Card>
    </StudentLayout>
  )
}

type Form = AddStudentAccountArg

const ModalContent = ({
  onSubmit,
  initialState,
  update,
  loading,
}: {
  onSubmit: (form: Form) => Promise<void>
  loading: boolean
  update: boolean
}) => {
  const formik = useFormik({
    initialValues: initialState || {
      login: '',
      password: '',
      curGroup: null,
      contract: '',
    },
    validate: ({ login, password, curGroup, contract }) => {
      let errors: Partial<Record<keyof Form, any>> = {}
      if (!update && !login) errors.login = requireField
      if (!update && !password) errors.password = requireField
      if (!curGroup) errors.curGroup = requireField
      if (!contract) errors.contract = requireField
      return errors
    },
    onSubmit: values => {
      onSubmit(values).then(() => {
        formik.resetForm()
      })
    },
  })
  function generate() {
    formik.setFieldValue('password', generatePass())
  }
  const { login, password, curGroup, contract } = formik.values
  const { data, isLoading, isFetching } = useGetGroupsQuery()

  return (
    <form onSubmit={formik.handleSubmit} className="p-fluid">
      {update ? null : (
        <FormItem value={login} name={'login'} label={'Логин'} formik={formik}>
          <InputText />
        </FormItem>
      )}
      <FormItem
        value={contract}
        name={'contract'}
        label={'Договор'}
        formik={formik}
      >
        <InputText />
      </FormItem>
      <FormItem
        value={curGroup}
        name={'curGroup'}
        label={'Группа'}
        formik={formik}
      >
        <Dropdown
          loading={isLoading || isFetching}
          options={map(data, ({ id, curGroup }) => ({
            value: id,
            label: curGroup,
          }))}
        />
      </FormItem>
      <FormItem
        value={password}
        name={'password'}
        label={'Пароль'}
        formik={formik}
      >
        <InputText />
      </FormItem>
      <Button
        type={'button'}
        className={'mb-24'}
        style={{ width: 'auto', display: 'block' }}
        onClick={generate}
      >
        Сгенерировать пароль
      </Button>
      <Button
        loading={loading}
        type="submit"
        label={update ? 'Обновить' : 'Сохранить'}
        className={'w-auto'}
      />
    </form>
  )
}
