export type Type =
  | 'Домашнее задание'
  | 'Классная работа'
  // | 'Тестирование'
  | 'Контрольная работа'
  // | 'Практическая работа'
  // | 'Курсовая работа'
  | 'Экзамен'

export enum PointTypeColor {
  'Домашнее задание' = '#79C294',
  'Классная работа' = '#C5A06A',
  // 'Тестирование' = '#6AAFC5',
  'Контрольная работа' = '#6A7EC5',
  // 'Практическая работа' = '#9D6AC5',
  // 'Курсовая работа' = '#C56AA0',
  'Экзамен' = '#CF4E4E',
}

export interface IPoint {
  point: number
  date: string
  theme: string
  comment?: string
  type: Type
}

interface IMeanPoint {
  point: number
  month: string
}

export interface IProgressData {
  curMeanPoint: number
  prevMeanPoints: IMeanPoint[]
  lastPoints: IPoint[]
  chartPoints: IPoint[]
  startYear: number
}

export interface IProgressState {
  data: IProgressData
  isLoading: boolean
  hasLoaded: boolean
  hasToastShown: boolean
}
