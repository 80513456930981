import React from 'react'
import styles from '../Homework.module.scss'
import { classNames } from 'primereact/utils'

interface MarkProps {
  mark: number
  active?: boolean
  disabled?: boolean
  onClick?: () => void
}

function getMarkClassname(mark: number) {
  return mark > 0 && mark < 4
    ? styles.bad
    : mark > 3 && mark < 8
    ? styles.ok
    : styles.good
}

export const Mark = ({ mark, onClick, active, disabled }: MarkProps) => {
  return (
    <button
      type={'button'}
      onClick={onClick}
      className={classNames(
        styles.mark,
        { [styles.active]: active && !disabled, [styles.disabled]: disabled },
        disabled ? null : getMarkClassname(mark)
      )}
    >
      {mark}
    </button>
  )
}
