import { baseApi } from '../../baseApi'
import {
  createMessageArg,
  getDataForCreateMessageResult,
  getRequestMessageResult,
} from './chat.types'
import { DataTableArgs } from '../../../components/DataTable'

const api = baseApi.injectEndpoints({
  endpoints: builder => ({
    getCountUnreadRequestMessage: builder.query<number, void | null>({
      providesTags: ['ChatMessage'],
      query: _ => ({
        url: 'chat/getCountUnreadRequestMessage',
        method: 'get',
        errorMessage: 'Не удалось получить информацию о количестве заявок!',
      }),
    }),
    getDataForCreateMessage: builder.query<getDataForCreateMessageResult, void>(
      {
        query: _ => ({
          url: 'chat/getDataForCreateMessage',
          method: 'get',
          errorMessage:
            'Не удалось получить информацию о данных для создания заявки!',
        }),
      }
    ),
    getRequestMessages: builder.query<getRequestMessageResult, DataTableArgs>({
      providesTags: ['ChatMessage'],
      query: params => ({
        url: 'chat/getRequestMessage',
        method: 'get',
        params,
        errorMessage: 'Не удалось получить информацию о заявках!',
      }),
    }),
    createMessage: builder.mutation<void, createMessageArg>({
      invalidatesTags: ['ChatMessage'],
      query: arg => ({
        url: 'chat/createMessage',
        method: 'post',
        data: arg,
        errorMessage: 'Не удалось создать обращение!',
      }),
    }),
  }),
})

export const {
  useLazyGetCountUnreadRequestMessageQuery,
  useCreateMessageMutation,
  useGetDataForCreateMessageQuery,
  useGetRequestMessagesQuery,
} = api
