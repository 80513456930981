import { IMaterialData } from './material.types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../../http'

export const getMaterials = createAsyncThunk<IMaterialData, undefined>(
  'student/material/getMaterials',
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get('/materials/getMaterialData')

      return data
    } catch (e) {
      return thunkAPI.rejectWithValue(
        'Страница с учебными материалами не смогла загрузиться, что же делать, что же делать..'
      )
    }
  }
)
