import { ReactNode } from 'react'
import style from './ColorIndicator.module.scss'
import classNames from 'classnames'

interface ColorIndicatorProps {
  text: ReactNode
  danger?: boolean
  info?: boolean
}

export const ColorIndicator = ({ text, info, danger }: ColorIndicatorProps) => {
  return (
    <div className={classNames(style.wrapper, 'flex gap-2')}>
      <div
        className={classNames(style.indicator, {
          [style.info]: info,
          [style.danger]: danger,
        })}
      />
      <span>-</span>
      {text}
    </div>
  )
}
