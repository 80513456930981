import React, { ReactNode } from 'react'
import { classNames } from 'primereact/utils'
import styles from './ClassType.module.scss'

interface ClassTypeProps {
  children: ReactNode
  active?: boolean
  onClick?: () => void
}

export const ClassType: React.FC<ClassTypeProps> = ({
  children,
  onClick,
  active,
}) => {
  return (
    <button
      type={'button'}
      onClick={onClick}
      className={classNames(styles.classType, {
        [styles.active]: active,
      })}
    >
      {children}
    </button>
  )
}
