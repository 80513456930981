import { useNavigate } from 'react-router-dom'
import useAppDispatch from '../useAppDispatch'
import { errorSlice } from '../../store/error/error.slice'

export const useErrorHandler = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { openErrorPage } = errorSlice.actions

  return (error: string) => {
    dispatch(openErrorPage(error))
    navigate('/error/')
  }
}
