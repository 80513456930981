import { createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../../http'
import { IMaterialType } from './materials.types'
import { Form } from '../../../pages/Materials/MaterialsPage'
import { size } from 'lodash'

export const getMaterials = createAsyncThunk<
  IMaterialType,
  void | { subjectId: number }
>('teacher/materials/getMaterialData', async (params, thunkAPI) => {
  try {
    const { data } = await http.get('/materials/getMaterialDataTeacher', {
      params,
    })
    return data
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось получить данные о материалах!')
  }
})

export const deleteMaterial = createAsyncThunk<unknown, { id: number }>(
  'teacher/materials/deleteMaterial',
  async (params, thunkAPI) => {
    try {
      const { data } = await http.delete('/materials/deleteMaterial', {
        params,
      })
      if (data?.message) return thunkAPI.rejectWithValue(data.message)
      return data
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось удалить материал!')
    }
  }
)

function appendFile(
  formData: FormData,
  file: { key: string; file: Array<File> | undefined },
  link?: { key: string; link: string | undefined }
) {
  if (!file.file) return
  if (size(file.file)) {
    for (let i = 0; i < file.file.length; i++) {
      formData.append(file.key, file.file[i]!, file.file[i]?.name)
    }
  } else if (link?.link?.trim().length) {
    formData.append(link.key, link.link)
  }
}

export const createMaterial = createAsyncThunk<unknown, Form>(
  'teacher/materials/createMaterial',
  async (
    { homeWorkFile, practice, presentation, file, link, ...rest },
    thunkAPI
  ) => {
    try {
      const formData = new FormData()
      for (const key in rest) {
        formData.append(key, rest[key])
      }
      appendFile(formData, { key: 'presentationFile', file: presentation })
      appendFile(formData, { key: 'practiceFile', file: practice })
      appendFile(formData, { key: 'homeworkFile', file: homeWorkFile })
      appendFile(formData, { key: 'files', file }, { key: 'link', link })
      await http.post('/materials/createMaterial', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось создать материал!')
    }
  }
)
