import { IMaterialData, IMaterialState } from './material.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getMaterials } from './material.actions'

const initialState: IMaterialState = {
  data: {
    lastMaterials: [],
    materials: [],
    subjects: [],
  },
  isLoading: false,
  hasLoaded: false,
}

export const materialSlice = createSlice({
  name: 'student/materialSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getMaterials.pending.type]: state => {
      state.isLoading = true
    },

    [getMaterials.fulfilled.type]: (
      state,
      action: PayloadAction<IMaterialData>
    ) => {
      state.isLoading = false
      state.hasLoaded = true
      state.data = action.payload
    },

    [getMaterials.rejected.type]: state => {
      state.isLoading = false
    },
  },
})

export default materialSlice.reducer
