import { parseIOSDate } from './parseIOSDate'

export function getDateMonth(date_: string): string {
  const date = parseIOSDate(date_)

  const MONTHS = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ]

  return MONTHS[date.getMonth()]
}
