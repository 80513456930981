import { baseApi } from '../../baseApi'
import {
  AddStudentAccountArg,
  StudentResult,
  UpdateStudentAccountArg,
} from './students.types'

const api = baseApi.injectEndpoints({
  endpoints: builder => ({
    getStudents: builder.query<Array<StudentResult>, void>({
      providesTags: ['Student'],
      query: _ => ({
        url: 'users/getAllStudents',
        method: 'get',
        errorMessage: 'Не удалось получить информацию о студентах!',
      }),
    }),
    addStudentAccount: builder.mutation<void, AddStudentAccountArg>({
      invalidatesTags: ['Student'],
      query: arg => ({
        url: 'students/registrationStudentAccount',
        method: 'post',
        data: arg,
        errorMessage: 'Не удалось добавить студента!',
      }),
    }),
    updateStudentAccount: builder.mutation<void, UpdateStudentAccountArg>({
      invalidatesTags: ['Student'],
      query: arg => ({
        url: 'students/updateStudentAccount',
        method: 'post',
        data: arg,
        errorMessage: 'Не удалось обновить студента!',
      }),
    }),
  }),
})

export const {
  useGetStudentsQuery,
  useAddStudentAccountMutation,
  useUpdateStudentAccountMutation,
} = api
