import React, { useEffect, useState } from 'react'
import { HomeworkHomework } from '../../../../store/admin/homework/homework.types'
import { Button } from 'primereact/button'
import { useFormik } from 'formik'
import { maxGrading } from '../../../../constants/constants'
import { InputTextarea } from 'primereact/inputtextarea'
import { classNames } from 'primereact/utils'
import { Mark } from './Mark'
import { ClassType } from '../../../../components/ClassType/ClassType'
import { map } from 'lodash'
import { downloadFile } from '../../../../downloadFile'
import styles from '../Homework.module.scss'
import useAppSelector from '../../../../hooks/useAppSelector'

interface ModalContentProps {
  homework: HomeworkHomework
  onSubmit: (form: Form) => void
}

export interface Form {
  comment: string
  mark: number
  status: string
}

enum Types {
  completed = 'Выполнено',
  notCompleted = 'Не выполнено',
  retry = 'Пересдача',
}
interface Loading {
  teacher: boolean
  student: boolean
}

export const ModalContent = ({ homework, onSubmit }: ModalContentProps) => {
  const { fioStudent, date, fileStudent, fileTeacher } = homework
  const [loadingHomework, setLoadingHomework] = useState<Loading>({
    teacher: false,
    student: false,
  })
  const { pending } = useAppSelector(
    state => state.adminHomeWorkSlice.checkHomework
  )
  const formik = useFormik<Form>({
    initialValues: {
      comment: '',
      mark: 0,
      status: '',
    },
    validate: data => {
      let errors: Partial<Record<keyof Form, any>> = {}
      if (status === Types.completed) {
        if (!data.mark) errors.mark = 'Выберите оценку!'
      }
      return errors
    },
    onSubmit,
  })
  const isFormFieldValid = (name: keyof Form) =>
    !!(formik.touched[name] && formik.errors[name])
  const getFormErrorMessage = (name: keyof Form) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    )
  }

  function download(link: string, key: keyof Loading) {
    if (link) {
      if (link.startsWith('http')) {
        window.open(link, '_blank')!.focus()
      } else {
        setLoadingHomework(prevState => ({ ...prevState, [key]: true }))
        downloadFile(link).finally(() => {
          setLoadingHomework(prevState => ({ ...prevState, [key]: false }))
        })
      }
    }
  }

  const { mark, comment, status } = formik.values
  useEffect(() => {
    if (status === Types.notCompleted) {
      formik.setFieldValue('mark', 0)
      formik.setFieldValue('comment', '')
    }
  }, [status])

  return (
    <>
      <small className={'date'}>Студент</small>
      <div className={styles.fio}>{fioStudent}</div>
      {date ? <small className={'date mt-24'}>Загружено: {date}</small> : null}
      <div className={'flex gap-24 mt-24'}>
        {fileStudent ? (
          <div>
            <small className={'date'}>Файл студента</small>
            <Button
              loading={loadingHomework.student}
              className={'mt-6'}
              onClick={() => download(fileStudent, 'student')}
            >
              Скачать файл
            </Button>
          </div>
        ) : null}
        {fileTeacher ? (
          <div>
            <small className={'date'}>Файл преподавателя</small>
            <Button
              loading={loadingHomework.teacher}
              className={'mt-6'}
              onClick={() => download(fileTeacher, 'teacher')}
            >
              Скачать файл
            </Button>
          </div>
        ) : null}
      </div>
      {homework.status === 'Выполнено' ? null : (
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="field">
            <span className="p-label">
              <small
                className={classNames('date', {
                  'p-error': isFormFieldValid('status'),
                })}
              >
                Статус
              </small>
              <div className={'flex gap-24 flex-wrap mt-6'}>
                {map(Types, (type, index) => {
                  if (type === Types.notCompleted) return null
                  return (
                    <ClassType
                      key={index}
                      active={status === type}
                      onClick={() => formik.setFieldValue('status', type)}
                    >
                      {type}
                    </ClassType>
                  )
                })}
              </div>
            </span>
            {getFormErrorMessage('status')}
          </div>
          <div className="field">
            <span className="p-float-label">
              <InputTextarea
                id="comment"
                name="comment"
                value={comment}
                onChange={formik.handleChange}
                className={classNames({
                  'p-invalid': isFormFieldValid('comment'),
                })}
              />
              <label
                htmlFor="comment"
                className={classNames({
                  'p-error': isFormFieldValid('comment'),
                })}
              >
                Комментарий
              </label>
            </span>
            {getFormErrorMessage('comment')}
          </div>
          {status === Types.completed ? (
            <div className="field">
              <span className="p-label">
                <label
                  htmlFor="mark"
                  className={classNames({
                    'p-error': isFormFieldValid('mark'),
                  })}
                >
                  Оценка
                </label>
                <div className={'flex gap-24 flex-wrap'}>
                  {Array.from({ length: maxGrading }, (_, i) => (
                    <Mark
                      key={i}
                      mark={i + 1}
                      active={mark === i + 1}
                      onClick={() => formik.setFieldValue('mark', i + 1)}
                    />
                  ))}
                </div>
              </span>
              {getFormErrorMessage('mark')}
            </div>
          ) : null}
          <Button
            loading={pending}
            type="submit"
            label="Сохранить"
            className={'w-auto'}
          />
        </form>
      )}
    </>
  )
}
