import { ISubject } from '../material/material.types'

export type Status =
  | 'Не сделано'
  | 'В процессе'
  | 'Выполнено'
  | 'Просрочено'
  | 'На пересдаче'

export enum HomeworkStatusColor {
  'Не сделано' = '#D97706',
  'В процессе' = '#D97706',
  'Выполнено' = '#16A34A',
  'Просрочено' = '#dc2626',
  'На пересдаче' = '#D97706',
}

export interface ITask {
  id: number
  name: string
  theme: string
  description: string
  createdDate: string
  deadline: string
  status: Status
  lessonLink: string
}

export interface IHomeworkData {
  allTasks: number
  curTasks: number
  passedTasks: number
  completedTasks: number
  loadedTasks: number
  tasks: ITask[]
  subjects: ISubject[]
}

export interface IHomeworkState {
  data: IHomeworkData
  isLoading: boolean
  hasLoaded: boolean
}
