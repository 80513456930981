export enum StudentPaths {
  index = '/',
  progress = '/progress',
  materials = '/materials',
  homework = '/homework',
  mail = '/mail',
  timetable = '/timetable',
  payment = '/payment',
  faq = '/faq',
  profile = '/profile',
  notifications = '/notifications',
  practices = '/practices',
}

export enum AdminPaths {
  index = '/',
  attendant = '/attendant',
  materials = '/materials',
  homework = '/homework',
  timetable = '/timetable',
  payment = '/payment',
  faq = '/faq',
  profile = '/profile',
  notifications = '/notifications',
}

export enum EduPaths {
  materials = '/materials',
  subjects = '/subjects',
  timetable = '/timetable',
  notifications = '/notifications',
  groups = '/groups',
  students = '/students',
  attendant = '/attendant',
  mail = '/mail',
  classrooms = '/classrooms',
  statistics = '/statistics',
  attendanceStatistic = '/attendanceStatistic',
}

export enum DirectorFilialPaths {
  users = '/users',
  materials = '/materials',
  subjects = '/subjects',
  timetable = '/timetable',
  notifications = '/notifications',
  groups = '/groups',
  students = '/students',
  attendant = '/attendant',
  mail = '/mail',
  classrooms = '/classrooms',
}

export enum DirectorCompanyPaths {
  users = '/users',
  notifications = '/notifications',
  mail = '/mail',
}
