import { IHomeworkData } from './homework.types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../../http'

export const getHomeworks = createAsyncThunk<IHomeworkData, undefined>(
  'student/homework/getHomeworks',
  async (_, thunkAPI) => {
    try {
      // todo filter by date and status

      const { data } = await http.get('/hometask/getHomeTaskData')

      return data
    } catch (e) {
      return thunkAPI.rejectWithValue(
        'Страница с домашними заданиями не смогла загрузиться, что же делать, что же делать..'
      )
    }
  }
)

export const loadHomework = createAsyncThunk<
  number,
  {
    link?: string
    files?: FileList | File[] | undefined
    id: number
    homeworkID: number
  }
>(
  'student/homework/loadHomeworks',
  async ({ link, files, id, homeworkID }, thunkAPI) => {
    try {
      const formData = new FormData()

      formData.append('user_id', id.toString())
      formData.append('task_id', homeworkID.toString())

      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append('files', files[i]!, files[i]?.name)
        }
      } else if (link?.trim().length) {
        formData.append('link', link)
      }

      await http.post('/students/uploadHomeWorkStudent', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      return homeworkID
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить домашнее задание')
    }
  }
)
