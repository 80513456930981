import { IFAQ, IFAQState } from './faq.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getFAQ } from './faq.actions'

const initialState: IFAQState = {
  data: [],
  isLoading: false,
  hasLoaded: false,
}

export const faqSlice = createSlice({
  name: 'student/faqSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getFAQ.pending.type]: state => {
      state.isLoading = true
    },

    [getFAQ.fulfilled.type]: (state, action: PayloadAction<IFAQ[]>) => {
      state.isLoading = false
      state.hasLoaded = true
      state.data = action.payload
    },

    [getFAQ.rejected.type]: state => {
      state.isLoading = false
    },
  },
})

export default faqSlice.reducer
