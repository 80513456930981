import React, { ReactNode } from 'react'
import styles from '../Homework.module.scss'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import img from '../../../../static/Avatar_TWoW_Neytiri_Textless_Poster.webp'
import { Mark } from './Mark'
import { baseURL } from '../../../../http'

export enum HomeworkCardStatuses {
  'Выполнено' = 'completed',
  'В процессе' = 'progress',
  'Не сделано' = 'notCompleted',
  'Просрочено' = 'over',
}

interface HomeworkCardProps {
  status: keyof typeof HomeworkCardStatuses
  fio: ReactNode
  statusText: string
  onClick: () => void
  date: ReactNode
  mark: number
  avatar: string
}

export const HomeworkCard = ({
  status,
  statusText,
  onClick,
  fio,
  date,
  avatar,
  mark,
}: HomeworkCardProps) => {
  return (
    <div
      className={classNames(
        styles.homeWork,
        styles[HomeworkCardStatuses[status]]
      )}
    >
      <div className={styles.headerWrapper}>
        <div className={styles.header}>{statusText}</div>
        {date ? <div className={styles.date}>{date}</div> : null}
      </div>
      <div className={styles.content}>
        <div className={'flex'}>
          <img className={styles.avatar} src={`${baseURL}/${avatar}`} />
          <div className={styles.name}>{fio}</div>
        </div>
        <div className={styles.buttons}>
          <Button onClick={onClick}>
            {status === 'Выполнено' ? 'Просмотреть' : 'Проверить'}
          </Button>
          {status === 'Выполнено' ? <Mark mark={mark} disabled /> : null}
        </div>
      </div>
    </div>
  )
}
