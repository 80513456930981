import { StudentLayout } from '../../../components/Layout/student/StudentLayout'
import { Card } from '../../../components/Card/Card'
import { DataTable } from '../../../components/DataTable'
import { Column } from 'primereact/column'
import React, { useEffect, useRef, useState } from 'react'
import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import { Button } from 'primereact/button'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import { requireField } from '../../../constants/constants'
import { classNames } from 'primereact/utils'
import { Form } from '../../../store/admin/subject/subject.types'
import {
  createSubject,
  getFilterForProgrammeName,
  getProgrammeNames,
} from '../../../store/admin/subject/subject.actions'
import { Toast } from 'primereact/toast'
import { NotificationTypes, notify } from '../../../utils/notify'
import { Modal } from '../../../components/Modal/Modal'
import Loader from '../../../components/Loader'
import { map } from 'lodash'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { CanAttachPackageSelector } from '../../../store/selectors'
import { Toolbar } from '../../../components/Toolbar'

export const Subjects = () => {
  const dispatch = useAppDispatch()
  const id = useAppSelector(state => state.userReducer.user.id)
  const [modal, setModal] = useState(false)
  const toast = useRef<Toast>(null)
  const { themes } = useAppSelector(state => state.adminMaterialReducer)
  const { getSubjects } = useAppSelector(state => state.adminSubjectSlice)
  const canAttachPackage = useAppSelector(CanAttachPackageSelector)
  const { fulfilled, pending } = getSubjects

  function getData() {
    dispatch(getProgrammeNames({ id }))
      .unwrap()
      .catch(e => {
        notify(toast, { type: NotificationTypes.error, content: e })
      })
  }

  useEffect(() => {
    getData()
  }, [])

  function onSubmit(name: string) {
    return dispatch(createSubject({ name }))
      .unwrap()
      .then(() => {
        notify(
          toast,
          {
            type: NotificationTypes.success,
            content: 'Вы успешно создали предмет!',
          },
          true
        )
      })
      .then(_ => {
        getData()
      })
      .catch(e => {
        notify(toast, { type: NotificationTypes.error, content: e })
      })
  }

  return (
    <StudentLayout>
      <Helmet title={'Предметы'} />
      <Toast ref={toast} />
      {canAttachPackage ? (
        <Modal
          header={'Создание предмета'}
          visible={modal}
          onHide={() => setModal(false)}
        >
          <ModalContent onSubmit={onSubmit} />
        </Modal>
      ) : null}
      <Card headerClassName={'mt-24'} header={'Предметы'}>
        {pending && !fulfilled ? (
          <Loader />
        ) : (
          <DataTable
            header={
              canAttachPackage ? (
                <Toolbar add={{ onClick: () => setModal(true) }} />
              ) : null
            }
            value={themes}
          >
            <Column filter sortable field={'name'} header="Название" />
          </DataTable>
        )}
      </Card>
    </StudentLayout>
  )
}

function formatPreview(...args: Array<string | undefined>) {
  return map(args, arg => {
    return arg || ''
  }).join('.')
}
const ModalContent = ({
  onSubmit,
}: {
  onSubmit: (preview: string) => Promise<void>
}) => {
  const formik = useFormik<Partial<Form>>({
    initialValues: {
      route: undefined,
      trainingProgram: undefined,
      yearsEducation: undefined,
      subjectName: undefined,
    },
    validate: ({ route, trainingProgram, yearsEducation, subjectName }) => {
      let errors: Partial<Record<keyof Form, any>> = {}
      if (!route) errors.route = requireField
      if (!trainingProgram) errors.trainingProgram = requireField
      if (!yearsEducation) errors.yearsEducation = requireField
      if (!subjectName) errors.subjectName = requireField
      return errors
    },
    onSubmit: values => {
      onSubmit(preview).then(() => formik.resetForm())
    },
  })
  const dispatch = useAppDispatch()
  const { filters } = useAppSelector(state => state.adminSubjectSlice)
  useEffect(() => {
    dispatch(getFilterForProgrammeName())
  }, [])
  const isFormFieldValid = (name: keyof Form) =>
    !!(formik.touched[name] && formik.errors[name])
  const getFormErrorMessage = (name: keyof Form) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    )
  }

  const { createSubject } = useAppSelector(state => state.adminSubjectSlice)
  const { pending } = createSubject
  const { route, trainingProgram, yearsEducation, subjectName } = formik.values

  useEffect(() => {
    formik.setFieldValue('trainingProgram', undefined)
    formik.setFieldValue('yearsEducation', undefined)
    formik.setFieldValue('subjectName', undefined)
  }, [route])

  useEffect(() => {
    formik.setFieldValue('yearsEducation', undefined)
    formik.setFieldValue('subjectName', undefined)
  }, [trainingProgram])

  useEffect(() => {
    formik.setFieldValue('subjectName', undefined)
  }, [yearsEducation])

  const preview = formatPreview(
    route?.route,
    trainingProgram?.name,
    yearsEducation?.course,
    subjectName
  )

  return (
    <form onSubmit={formik.handleSubmit} className="p-fluid">
      <div className="field">
        <span className="p-float-label">
          <Dropdown
            filter
            id="route"
            name="route"
            options={map(filters, route => ({
              label: route.route,
              value: route,
            }))}
            value={route}
            onChange={formik.handleChange}
            className={classNames({
              'p-invalid': isFormFieldValid('route'),
            })}
          />
          <label
            htmlFor="route"
            className={classNames({
              'p-error': isFormFieldValid('route'),
            })}
          >
            Направление
          </label>
        </span>
        {getFormErrorMessage('route')}
      </div>
      {route ? (
        <div className="field">
          <span className="p-float-label">
            <Dropdown
              filter
              id="trainingProgram"
              name="trainingProgram"
              options={map(route.trainingProgramme, program => ({
                label: program.name,
                value: program,
              }))}
              value={trainingProgram}
              onChange={formik.handleChange}
              className={classNames({
                'p-invalid': isFormFieldValid('trainingProgram'),
              })}
            />
            <label
              htmlFor="trainingProgram"
              className={classNames({
                'p-error': isFormFieldValid('trainingProgram'),
              })}
            >
              Программа
            </label>
          </span>
          {getFormErrorMessage('trainingProgram')}
        </div>
      ) : null}
      {trainingProgram ? (
        <div className="field">
          <span className="p-float-label">
            <Dropdown
              filter
              id="yearsEducation"
              name="yearsEducation"
              options={map(trainingProgram.yearsEducations, yearsEducation => ({
                label: yearsEducation.course,
                value: yearsEducation,
              }))}
              value={yearsEducation}
              onChange={formik.handleChange}
              className={classNames({
                'p-invalid': isFormFieldValid('yearsEducation'),
              })}
            />
            <label
              htmlFor="yearsEducation"
              className={classNames({
                'p-error': isFormFieldValid('yearsEducation'),
              })}
            >
              Курс
            </label>
          </span>
          {getFormErrorMessage('yearsEducation')}
        </div>
      ) : null}
      {yearsEducation ? (
        <div className="field">
          <span className="p-float-label">
            <Dropdown
              filter
              id="subjectName"
              name="subjectName"
              options={map(yearsEducation.subjectNames, subject => ({
                label: subject,
                value: subject,
              }))}
              value={subjectName}
              onChange={formik.handleChange}
              className={classNames({
                'p-invalid': isFormFieldValid('subjectName'),
              })}
            />
            <label
              htmlFor="subjectName"
              className={classNames({
                'p-error': isFormFieldValid('subjectName'),
              })}
            >
              Предмет
            </label>
          </span>
          {getFormErrorMessage('subjectName')}
        </div>
      ) : null}
      <div className="field">
        <span className="p-float-label">
          <InputText disabled value={preview} />
          <label>Предпросмотр</label>
        </span>
      </div>
      <Button
        loading={pending}
        type="submit"
        label="Сохранить"
        className={'w-auto'}
      />
    </form>
  )
}
