import {
  Dropdown as PrimeDropdown,
  DropdownProps as PrimeDropdownProps,
} from 'primereact/dropdown'

interface DropdownProps extends PrimeDropdownProps {
  loading?: boolean
}

export const Dropdown = ({ disabled, loading, ...rest }: DropdownProps) => {
  return (
    <PrimeDropdown
      filter
      {...rest}
      disabled={disabled || loading}
      dropdownIcon={loading ? 'pi pi-spin pi-spinner' : undefined}
    />
  )
}
