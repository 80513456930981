import { classNames } from 'primereact/utils'
import { FC, useState } from 'react'
import useAppSelector from '../../../hooks/useAppSelector'
import { FloatingButton } from '../../FloatingButton/FloatingButton'
import { StudentHeader } from '../../Header/StudentHeader'
import Loader from '../../Loader'
import { Sidebar } from '../../Sidebar/Sidebar'
import { IProps } from './StudentLayout.types'

export const StudentLayout: FC<IProps> = ({ pageName = '', children }) => {
  const [isVisible, setIsVisible] = useState(false)
  const { isShortLoading, shortInfo } = useAppSelector(
    state => state.infoReducer
  )

  if (isShortLoading) {
    return <Loader />
  }

  return (
    <div className={classNames('w-full flex', pageName)}>
      <Sidebar
        isVisible={isVisible}
        onHide={() => setIsVisible(false)}
        mobileData={{
          curGroup: shortInfo.curGroup,
          coins: shortInfo.coins,
        }}
      />
      <div className="w-full container" style={{ overflow: 'clip' }}>
        <StudentHeader
          setIsVisible={setIsVisible}
          curGroup={shortInfo.curGroup}
          coins={shortInfo.coins}
        />
        {children}
      </div>
      <FloatingButton />
    </div>
  )
}
