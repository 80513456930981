import { ReactNode, RefObject } from 'react'
import { Toast, ToastMessage } from 'primereact/toast'
import { soundNotification } from './soundNotification'
import { toast } from 'react-toastify'

export enum NotificationTypes {
  success = 'success',
  error = 'error',
}

const defaultHeaders = {
  [NotificationTypes.success]: 'Успех!',
  [NotificationTypes.error]: 'Что-то пошло не так...',
}

interface Options extends ToastMessage {
  type: NotificationTypes
  header?: ReactNode
  content?: ReactNode
}

export function notify(
  ref: RefObject<Toast> | Toast,
  { type, header, content, ...rest }: Options,
  sound?: boolean
) {
  toast[type](content, {
    position: 'top-center',
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  })
  if (sound) soundNotification()
}
