import { baseURL } from './http'

export const downloadFile = async (link: string) => {
  const headers = new Headers()
  headers.set('authorization', localStorage.getItem('access-token')!)

  const resp = await fetch(baseURL + '/students/download?fileName=' + link, {
    headers,
  })
  const blob = await resp.blob()

  const file = window.URL.createObjectURL(blob)
  window.location.assign(file)
}
