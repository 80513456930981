import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import useAppSelector from '../../../hooks/useAppSelector'
import './IndexPage.scss'
import { getStudentInfo } from '../../../store/student/info/info.actions'
import useAppDispatch from '../../../hooks/useAppDispatch'
import Loader from '../../../components/Loader'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'
import useDateFormatter from '../../../hooks/useDateFormatter'
import { StudentLayout } from '../../../components/Layout/student/StudentLayout'
import { classNames } from 'primereact/utils'
import { getTime } from '../../../utils/date/getTime'
import useErrorHandler from '../../../hooks/useErrorHandler'

export const IndexPage: FC = () => {
  // redux
  const { isLoading, hasLoaded, info } = useAppSelector(
    state => state.infoReducer
  )
  const dispatch = useAppDispatch()

  // state and ref
  const [homeworkHeight, setHomeworkHeight] = useState(0)
  const [addLineFeed, setAddLineFeed] = useState(false)
  const progressContainerRef = useRef<HTMLDivElement>(null)

  // hooks
  const _ = useDateFormatter()
  const errorHandler = useErrorHandler()

  useEffect(() => {
    document.title = 'Главная'

    if (!hasLoaded) {
      dispatch(getStudentInfo())
        .unwrap()
        .catch(error => {
          errorHandler(error)
        })
    }
  }, [])

  useLayoutEffect(() => {
    function updateSize() {
      setHomeworkHeight(progressContainerRef.current?.clientHeight || 0)

      if (window.innerWidth <= 430) {
        setAddLineFeed(true)
      } else {
        setAddLineFeed(false)
      }
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return (
    <StudentLayout>
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="grid">
              <div className="lg:col-5 col-12 card py-0">
                <div className="header flex align-items-center justify-content-between points-bg">
                  <div className="title">Успеваемость</div>
                  <Link to="/progress/">
                    <Button
                      icon="pi pi-chevron-right white"
                      className="p-button-rounded p-button-outlined"
                    />
                  </Link>
                </div>
                <div className="content" ref={progressContainerRef}>
                  <div className="area bg" style={{ height: '137.117px' }}>
                    <div className="title points-text">Средний балл</div>
                    <div className="flex align-items-end text">
                      <div className="points-text h1 border">
                        {info.points.curMeanPoint}
                      </div>
                      {info.points.prevMeanPoints?.map((point, idx) => (
                        <div
                          className={`h${idx + 2 > 3 ? 3 : idx + 2} border`}
                          key={point}
                        >
                          {point}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="grid">
                    {info.points.points.length ? (
                      info.points.points.map((point, idx) => (
                        <div
                          className="col-4 py-0 xs-col-6"
                          id={classNames({
                            first: idx <= 1,
                          })}
                          key={point.date + point.point}
                        >
                          <div className="area bg" style={{ height: 152.233 }}>
                            <div className="title">Получено</div>
                            <div className="h1 dark">{point.point}</div>
                            <small className="date">{_(point.date)}</small>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-4 py-0 xs-col-6" id="first">
                        <div className="area bg" style={{ height: 152.233 }}>
                          <div className="title">Пока нет оценок</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="lg:col-7 col-12 card py-0 md-mt-24">
                <div className="header flex align-items-center justify-content-between homework-bg">
                  <div className="title">Домашнее задание</div>
                  <Link to="/homework/">
                    <Button
                      icon="pi pi-chevron-right white"
                      className="p-button-rounded p-button-outlined"
                    />
                  </Link>
                </div>
                <div className="content">
                  <div className="grid mt-0 h-full">
                    <div
                      className={
                        (info.homework.curTask?.name ? 'col-7' : 'col-12') +
                        ' xs-col-12 py-0 relative'
                      }
                    >
                      <div className="grid mt-0">
                        <div className="col-6 py-0">
                          <div className="area bg" style={{ height: 137.117 }}>
                            <div className="title homework-text">
                              Всего{addLineFeed ? <br /> : ' '}заданий
                              <br />
                            </div>
                            <div className="h1 dark">
                              {info.homework.allTasks}
                            </div>
                          </div>
                        </div>
                        <div className="col-6 py-0">
                          <div className="area bg" style={{ height: 137.117 }}>
                            <div className="title">Текущие задания</div>
                            <div className="h1 dark">
                              {info.homework.curTasks}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid">
                        <div className="col-6 py-0">
                          <div className="area bg" style={{ height: 152.233 }}>
                            <div className="title">Просрочено заданий</div>
                            <div className="h1 dark">
                              {info.homework.passedTasks}
                            </div>
                          </div>
                        </div>
                        <div className="col-6 py-0">
                          <div className="area bg" style={{ height: 152.233 }}>
                            <div className="title">Проверено заданий</div>
                            <div className="h1 dark">
                              {info.homework.completedTasks}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {info.homework.curTask?.name ? (
                      <div className="col-5 xs-col-12 py-0">
                        <div className="area overflow-y-scroll flex flex-column justify-content-between h-full">
                          <div>
                            <div className="title light sm-mt-24">
                              Ближайшее
                            </div>
                            <div className="text bold fz-20">
                              {info.homework.curTask.name}
                            </div>
                            <small className="text">
                              {info.homework.curTask.description}
                            </small>
                            <small className="text bold">
                              {info.homework.curTask.tags}
                            </small>
                          </div>
                          <small className="text date">
                            До {_(info.homework.curTask.deadline)}
                          </small>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="lg:col-4 col-12 card py-0">
                <div className="header flex align-items-center justify-content-between materials-bg">
                  <div className="title">Учебные материалы</div>
                  <Link to="/materials/">
                    <Button
                      icon="pi pi-chevron-right white"
                      className="p-button-rounded p-button-outlined"
                    />
                  </Link>
                </div>
                <div className="content" style={{ height: 305.35 }}>
                  {info.materials?.name ? (
                    <div className="area flex flex-column justify-content-between h-full overflow-y-scroll">
                      <div>
                        <div className="flex justify-content-between">
                          <div className="title materials-text">
                            Последнее поступление:
                          </div>
                          {/*<div className="preview">
                            <img
                              src={info.materials.previewImage}
                              alt="preview"
                            />
                          </div>*/}
                        </div>
                        <small className="date">
                          {_(info.materials.createdDate)}
                        </small>
                        <div className="text fz-20 mt-6">
                          {info.materials.theme}
                        </div>
                        <div className="text fz-20 bold mt-24">
                          {info.materials.name}
                        </div>
                      </div>
                      <small className="date mt-24">
                        {info.materials.source}
                      </small>
                    </div>
                  ) : (
                    <div className="text fz-20">Здесь пока ничего нет</div>
                  )}
                </div>
              </div>
              <div className="lg:col-4 col-12 card py-0 md-mt-24">
                <div className="header flex align-items-center justify-content-between timetable-bg">
                  <div className="title">Расписание</div>
                  <Link to="/timetable/">
                    <Button
                      icon="pi pi-chevron-right white"
                      className="p-button-rounded p-button-outlined"
                    />
                  </Link>
                </div>
                <div className="content" style={{ height: 305.35 }}>
                  {info.timetable?.theme ? (
                    <div className="area overflow-y-scroll max-h-full">
                      <div className="title timetable-text">
                        Следующее занятие:
                      </div>
                      <div className="text fz-20 bold mt-6">
                        {_(info.timetable.datetime)}
                        <br />
                        {getTime(info.timetable.datetime)}
                      </div>
                      <small className="date mt-24">Тема занятия:</small>
                      <div className="text fz-20 bold mt-6">
                        {info.timetable.theme}
                      </div>
                    </div>
                  ) : (
                    <div className="text fz-20">Здесь пока ничего нет</div>
                  )}
                </div>
              </div>
              <div className="lg:col-4 col-12 card py-0 md-mt-24 payment-card">
                <div className="header flex align-items-center justify-content-between payment-bg">
                  <div className="title">Оплата</div>
                  {/* <Link to="/payment/">
                    <Button
                      icon="pi pi-chevron-right white"
                      className="p-button-rounded p-button-outlined"
                    />
                  </Link> */}
                </div>
                <div
                  className="content overflow-y-scroll max-h-full"
                  style={{ height: 305.35 }}
                >
                  {info.payment ? (
                    <>
                      <div className="area bg">
                        <div
                          className={classNames('title', {
                            'payment-text': info.payment.necessaryToPay === 0,
                            'danger-text': info.payment.necessaryToPay !== 0,
                          })}
                        >
                          Необходимо оплатить
                        </div>
                        <div className="h1 dark money">
                          {info.payment.necessaryToPay} ₽
                        </div>
                      </div>
                      <div className="area mt-24 px-18">
                        <div className="title payment-text">Условия оплаты</div>
                        <div className="h1 dark money">
                          {info.payment.tariff} ₽
                        </div>
                        <small className="mt-0">
                          {info.payment.paymentPeriod}
                        </small>
                      </div>
                    </>
                  ) : (
                    <div className="text fz-20">Здесь пока ничего нет</div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </StudentLayout>
  )
}
