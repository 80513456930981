import React from 'react'
import style from './Card.module.scss'
import { classNames } from 'primereact/utils'

interface Props {
  className?: string
  header: React.ReactNode
  headerClassName?: string
  contentClassName?: string
  children: React.ReactNode
}

export const Card: React.FC<Props> = ({
  children,
  header,
  className,
  headerClassName,
  contentClassName,
}) => {
  return (
    <div className={classNames(style.card, className)}>
      <div className={classNames(style.header, headerClassName)}>{header}</div>
      <div
        className={classNames(style.content, contentClassName)}
        id="admin-timetable-container"
      >
        {children}
      </div>
    </div>
  )
}
