import { baseApi } from '../../baseApi'
import {
  CreateAudienceArg,
  GetAllAudienceResult,
  UpdateAudienceArg,
} from './classroom.types'

const api = baseApi.injectEndpoints({
  endpoints: builder => ({
    updateAudience: builder.mutation<null, UpdateAudienceArg>({
      invalidatesTags: ['Audience'],
      query: data => ({
        url: 'audience/updateAudience',
        method: 'put',
        data,
        errorMessage: 'Не удалось обновить аудиторию!',
      }),
    }),
    createAudience: builder.mutation<null, CreateAudienceArg>({
      invalidatesTags: ['Audience'],
      query: data => ({
        url: 'audience/createAudience',
        method: 'post',
        data,
        errorMessage: 'Не удалось создать аудиторию!',
      }),
    }),
    getAllAudience: builder.query<GetAllAudienceResult, null | void>({
      providesTags: ['Audience'],
      query: params => ({
        url: 'audience/getAllAudience',
        method: 'get',
        params,
        errorMessage: 'Не удалось получить информацию об аудиториях!',
      }),
    }),
  }),
})

export const {
  useCreateAudienceMutation,
  useUpdateAudienceMutation,
  useGetAllAudienceQuery,
} = api
