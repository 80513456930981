import { confirmPopup, ConfirmPopupProps } from 'primereact/confirmpopup'
import { confirmDialog, ConfirmDialogProps } from 'primereact/confirmdialog'

interface Options extends ConfirmPopupProps {
  message: string
}

export function confirmTooltip(
  target: HTMLElement,
  { message, ...rest }: Options
) {
  confirmPopup({
    target,
    message,
    icon: 'pi pi-exclamation-triangle',
    ...rest,
  })
}

export function confirmModal({ message, ...rest }: ConfirmDialogProps) {
  confirmDialog({
    message,
    icon: 'pi pi-info-circle',
    ...rest,
  })
}
