import { getProgressData } from './progress.actions'
import { IProgressData, IProgressState } from './progress.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: IProgressState = {
  data: {
    curMeanPoint: 0,
    prevMeanPoints: [],
    lastPoints: [],
    chartPoints: [],
    startYear: 0,
  },
  isLoading: false,
  hasLoaded: false,
  hasToastShown: false,
}

export const progressSlice = createSlice({
  name: 'student/progress',
  initialState,
  reducers: {
    showToast(state) {
      state.hasToastShown = true
    },
  },
  extraReducers: {
    [getProgressData.pending.type]: state => {
      state.isLoading = true
    },

    [getProgressData.fulfilled.type]: (
      state,
      action: PayloadAction<IProgressData>
    ) => {
      state.isLoading = false
      state.hasLoaded = true
      state.data = action.payload
    },

    [getProgressData.rejected.type]: state => {
      state.isLoading = false
    },
  },
})

export default progressSlice.reducer
