import { baseApi } from '../../baseApi'
import {
  AddUserAccountArg,
  GetDataForCreateUserResponse,
  GetTeachersResponse,
  GetUsersResponse,
  UpdateUserAccountArg,
} from './users.types'
import { DataTableArgs } from '../../../components/DataTable'

const api = baseApi.injectEndpoints({
  endpoints: builder => ({
    getUsers: builder.query<GetUsersResponse, DataTableArgs>({
      providesTags: ['User'],
      query: arg => ({
        url: 'users/getAllUser',
        data: arg,
        method: 'post',
        errorMessage: 'Не удалось получить информацию о пользователях!',
      }),
    }),
    addUserAccount: builder.mutation<void, AddUserAccountArg>({
      invalidatesTags: ['User'],
      query: arg => ({
        url: 'users/registrationUser',
        method: 'post',
        data: arg,
        errorMessage: 'Не удалось добавить пользователя!',
      }),
    }),
    updateUserAccount: builder.mutation<void, UpdateUserAccountArg>({
      invalidatesTags: ['User'],
      query: arg => ({
        url: 'users/updateUser',
        method: 'post',
        data: arg,
        errorMessage: 'Не удалось обновить студента!',
      }),
    }),
    getTeachers: builder.query<GetTeachersResponse, null>({
      providesTags: ['User'],
      query: _ => ({
        url: 'users/getAllTeachers',
        method: 'get',
        errorMessage: 'Не удалось получить информацию о преподавателях!',
      }),
    }),
    getDataForCreateUser: builder.query<
      GetDataForCreateUserResponse,
      void | null
    >({
      query: _ => ({
        url: 'users/getDataForCreateUser',
        method: 'get',
        errorMessage:
          'Не удалось получить информацию для создания пользователя!',
      }),
    }),
  }),
})

export const {
  useGetTeachersQuery,
  useGetUsersQuery,
  useAddUserAccountMutation,
  useUpdateUserAccountMutation,
  useGetDataForCreateUserQuery,
} = api
