import React, {
  FC,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import logo from '../../static/logo.svg'
import main from '../../static/main.svg'
import points from '../../static/points.svg'
import materials from '../../static/materials.svg'
import timetable from '../../static/timetable.svg'
import cifra from '../../static/cifra.svg'
import chat from '../../static/chat.svg'
import homework from '../../static/homework.svg'
import SidebarItem from './SidebarItem'
import { Sidebar as PrimeSidebar } from 'primereact/sidebar'
import './StudentSidebar.scss'
import { IProps } from './StudentSidebar.types'
import { Link, useLocation } from 'react-router-dom'
import { Button } from 'primereact/button'
import { actions, logout } from '../../store/user/user.slice'
import useAppDispatch from '../../hooks/useAppDispatch'
import { map } from 'lodash'
import useAppSelector from '../../hooks/useAppSelector'
import {
  AdminPaths,
  DirectorCompanyPaths,
  DirectorFilialPaths,
  EduPaths,
  StudentPaths,
} from '../Routing/Routing.types'
import { sidebarWidthSlice } from '../../store/sidebarWidth/sidebarWidth.slice'
import { IUser, Role } from '../../store/user/user.types'
import { useLazyGetCountUnreadRequestMessageQuery } from '../../api/endpoints/chat'
import { useLazyGetCountUnShowedPracticeQuery } from '../../api/endpoints/practice'

interface Menu<RolePath> {
  pathname: RolePath
  background: string
  children: ReactNode
  indicator?: keyof IUser
}

type Menus<RolePath> = Array<Menu<RolePath>>

const studentMenu: Menus<StudentPaths> = [
  {
    pathname: StudentPaths.index,
    background: '#3B82F610',
    children: (
      <SidebarItem title="Главная" img={main} link={StudentPaths.index} />
    ),
  },
  {
    pathname: StudentPaths.progress,
    background: '#44AFAF10',
    children: (
      <SidebarItem
        title="Успеваемость"
        img={points}
        link={StudentPaths.progress}
      />
    ),
  },
  {
    pathname: StudentPaths.homework,
    background: '#D9770610',
    children: (
      <SidebarItem
        title="Домашнее задание"
        img={homework}
        link={StudentPaths.homework}
      />
    ),
  },
  {
    pathname: StudentPaths.materials,
    background: '#8D44AF10',
    children: (
      <SidebarItem
        title="Учебные материалы"
        img={materials}
        link={StudentPaths.materials}
      />
    ),
  },
  {
    pathname: StudentPaths.practices,
    background: '#8D44AF10',
    indicator: 'practices',
    children: (
      <SidebarItem
        title="Практические"
        img={materials}
        link={StudentPaths.practices}
      />
    ),
  },
  {
    pathname: StudentPaths.timetable,
    background: '#3B82F610',
    children: (
      <SidebarItem
        title="Расписание"
        img={timetable}
        link={StudentPaths.timetable}
      />
    ),
  },
  {
    pathname: StudentPaths.mail,
    background: '#c56aa010',
    children: (
      <SidebarItem title="Обращения" img={chat} link={StudentPaths.mail} />
    ),
  },
  // {
  //   pathname: StudentPaths.payment,
  //   background: '#16A34A10',
  //   children: (
  //     <SidebarItem title="Оплата" img={payment} link={StudentPaths.payment} />
  //   ),
  // },
  // {
  //   pathname: StudentPaths.faq,
  //   background: '#49505710',
  //   children: <SidebarItem title="FAQ" img={faq} link={StudentPaths.faq} />,
  // },
]

const adminMenu: Menus<AdminPaths> = [
  // {
  //   pathname: AdminPaths.index,
  //   background: '#3B82F610',
  //   children: (
  //     <SidebarItem title="Главная" img={main} link={AdminPaths.index} />
  //   ),
  // },
  {
    pathname: AdminPaths.timetable,
    background: '#3B82F610',
    children: (
      <SidebarItem
        title="Расписание"
        img={timetable}
        link={AdminPaths.timetable}
      />
    ),
  },
  {
    pathname: AdminPaths.attendant,
    background: '#44AFAF10',
    children: (
      <SidebarItem
        title="Присутствующие"
        img={points}
        link={AdminPaths.attendant}
      />
    ),
  },
  {
    pathname: AdminPaths.materials,
    background: '#8D44AF10',
    children: (
      <SidebarItem
        title="Методические материалы"
        img={materials}
        link={AdminPaths.materials}
      />
    ),
  },
  {
    pathname: AdminPaths.homework,
    background: 'rgba(217,119,6,0.06)',
    children: (
      <SidebarItem
        title="Домашнее задание"
        img={homework}
        link={AdminPaths.homework}
      />
    ),
  },
]

const eduMenu: Menus<EduPaths> = [
  {
    pathname: EduPaths.attendant,
    background: '#44AFAF10',
    children: (
      <SidebarItem
        title="Присутствующие"
        img={points}
        link={EduPaths.attendant}
      />
    ),
  },
  {
    pathname: EduPaths.timetable,
    background: '#3B82F610',
    children: (
      <SidebarItem
        title="Расписание"
        img={timetable}
        link={EduPaths.timetable}
      />
    ),
  },
  {
    pathname: EduPaths.materials,
    background: '#8D44AF10',
    children: (
      <SidebarItem
        title="Учебные материалы"
        img={materials}
        link={EduPaths.materials}
      />
    ),
  },
  {
    pathname: EduPaths.classrooms,
    background: '#8D44AF10',
    children: (
      <SidebarItem
        title="Аудитории"
        img={materials}
        link={EduPaths.classrooms}
      />
    ),
  },
  {
    pathname: EduPaths.subjects,
    background: '#8D44AF10',
    children: (
      <SidebarItem title="Предметы" img={materials} link={EduPaths.subjects} />
    ),
  },
  {
    pathname: EduPaths.groups,
    background: '#8D44AF10',
    children: (
      <SidebarItem title="Группы" img={materials} link={EduPaths.groups} />
    ),
  },
  {
    pathname: EduPaths.students,
    background: '#8D44AF10',
    children: (
      <SidebarItem title="Студенты" img={materials} link={EduPaths.students} />
    ),
  },
  {
    pathname: EduPaths.mail,
    background: '#c56aa010',
    indicator: 'messages',
    children: <SidebarItem title="Обращения" img={chat} link={EduPaths.mail} />,
  },
]

const directorFilialMenu: Menus<DirectorFilialPaths> = [
  {
    pathname: DirectorFilialPaths.attendant,
    background: '#44AFAF10',
    children: (
      <SidebarItem
        title="Присутствующие"
        img={points}
        link={DirectorFilialPaths.attendant}
      />
    ),
  },
  {
    pathname: DirectorFilialPaths.timetable,
    background: '#3B82F610',
    children: (
      <SidebarItem
        title="Расписание"
        img={timetable}
        link={DirectorFilialPaths.timetable}
      />
    ),
  },
  {
    pathname: DirectorFilialPaths.classrooms,
    background: '#8D44AF10',
    children: (
      <SidebarItem
        title="Аудитории"
        img={materials}
        link={DirectorFilialPaths.classrooms}
      />
    ),
  },
  {
    pathname: DirectorFilialPaths.materials,
    background: '#8D44AF10',
    children: (
      <SidebarItem
        title="Учебные материалы"
        img={materials}
        link={DirectorFilialPaths.materials}
      />
    ),
  },
  {
    pathname: DirectorFilialPaths.subjects,
    background: '#8D44AF10',
    children: (
      <SidebarItem
        title="Предметы"
        img={materials}
        link={DirectorFilialPaths.subjects}
      />
    ),
  },
  {
    pathname: DirectorFilialPaths.groups,
    background: '#8D44AF10',
    children: (
      <SidebarItem
        title="Группы"
        img={materials}
        link={DirectorFilialPaths.groups}
      />
    ),
  },
  {
    pathname: DirectorFilialPaths.students,
    background: '#8D44AF10',
    children: (
      <SidebarItem
        title="Студенты"
        img={materials}
        link={DirectorFilialPaths.students}
      />
    ),
  },
  {
    pathname: DirectorFilialPaths.users,
    background: '#8D44AF10',
    children: (
      <SidebarItem
        title="Пользователи"
        img={materials}
        link={DirectorFilialPaths.users}
      />
    ),
  },
  {
    pathname: DirectorFilialPaths.mail,
    background: '#c56aa010',
    indicator: 'messages',
    children: (
      <SidebarItem
        title="Обращения"
        img={chat}
        link={DirectorFilialPaths.mail}
      />
    ),
  },
]

const directorCompanyMenu: Menus<DirectorCompanyPaths> = [
  {
    pathname: DirectorCompanyPaths.users,
    background: '#8D44AF10',
    children: (
      <SidebarItem
        title="Пользователи"
        img={materials}
        link={DirectorFilialPaths.users}
      />
    ),
  },
  {
    pathname: DirectorCompanyPaths.mail,
    background: '#c56aa010',
    mail: true,
    children: (
      <SidebarItem
        title="Обращения"
        img={chat}
        link={DirectorCompanyPaths.mail}
      />
    ),
  },
]

const menus: Partial<Record<Role, any>> = {
  student: studentMenu,
  admin: adminMenu,
  edu: eduMenu,
  director_filial: directorFilialMenu,
  director_company: directorCompanyMenu,
}

export const Sidebar: FC<IProps> = ({ isVisible, onHide, mobileData }) => {
  const sidebarWidth = useAppSelector(state => state.sidebarWidthReducer.width)
  const { setSidebarWidth } = sidebarWidthSlice.actions
  const [isMobile, setIsMobile] = useState(false)
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.userReducer.user)
  const { pathname } = useLocation()
  const sidebarRef = useRef<HTMLDivElement>(null)
  const [triggerMessages, messagesResult] =
    useLazyGetCountUnreadRequestMessageQuery()
  const [triggerPractices, practiceResult] =
    useLazyGetCountUnShowedPracticeQuery()
  useEffect(() => {
    if (
      user.role !== 'student' &&
      user.role !== 'admin' &&
      messagesResult.isUninitialized
    )
      triggerMessages()
        .unwrap()
        .then(e => {
          dispatch(actions.setMessages(e || 0))
        })
    if (user.role === 'student' && practiceResult.isUninitialized)
      triggerPractices()
        .unwrap()
        .then(e => {
          dispatch(actions.setPractices(e || 0))
        })
  }, [])

  useLayoutEffect(() => {
    function updateSize() {
      setTimeout(() => {
        const clientWidth = sidebarRef.current?.clientWidth

        if (clientWidth !== sidebarWidth) {
          dispatch(setSidebarWidth(clientWidth || sidebarWidth))
        }
      }, 1)
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const sidebar = (
    <div className="sidebar h-full" ref={sidebarRef}>
      <div
        className="sidebar-content flex flex-column align-items-center"
        style={{
          width: sidebarWidth,
        }}
      >
        <div className="logo-container w-full flex flex-column align-items-center">
          <Link to="/" className="logo">
            <img src={logo} alt="logo" />
          </Link>
          <ul className="nav w-full">
            {map(
              user.role !== 'anonymous' ? menus[user.role] : [],
              ({ pathname: itemPathname, children, background, indicator }) => (
                <li
                  key={itemPathname}
                  style={{
                    position: 'relative',
                    background:
                      pathname === itemPathname ? background : undefined,
                  }}
                >
                  {children}
                  {indicator && user[indicator] ? (
                    <span className={'sidebar__indicator'}>
                      {user[indicator]}
                    </span>
                  ) : null}
                </li>
              )
            )}
            <li>
              <div
                className="nav-link flex align-items-center last"
                onClick={() => dispatch(logout())}
              >
                <div className="icon">
                  <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger p-button-outlined"
                  />
                </div>
                <div className="text">Выйти</div>
              </div>
            </li>
          </ul>
          {isMobile && user.role === 'student' ? (
            <div className="mobile-data mt-auto w-full flex align-items-center justify-content-between mt-12">
              <div className="ml-24">{mobileData.curGroup}</div>
              <div className="coins flex align-items-center mt-0">
                <div className="coin flex align-items-center">
                  <img src={cifra} alt="cifra" />
                  <div className="text">{mobileData.coins}</div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth < 1350) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  if (isMobile) {
    return (
      <PrimeSidebar
        blockScroll={true}
        visible={isVisible}
        onHide={onHide}
        position="right"
      >
        {sidebar}
      </PrimeSidebar>
    )
  }

  return sidebar
}
