import { getTimetable } from './timetable.actions'
import { ITimetableData, ITimetableState } from './timetable.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: ITimetableState = {
  data: {
    nearestLessons: [],
    lessons: [],
  },
  hasLoaded: false,
  isLoading: false,
}

export const timetableSlice = createSlice({
  name: 'student/timetable',
  initialState,
  reducers: {},
  extraReducers: {
    [getTimetable.pending.type]: state => {
      state.isLoading = true
    },

    [getTimetable.fulfilled.type]: (
      state,
      action: PayloadAction<ITimetableData>
    ) => {
      state.isLoading = false
      state.hasLoaded = true
      state.data = action.payload
    },

    [getTimetable.rejected.type]: state => {
      state.isLoading = false
    },
  },
})

export default timetableSlice.reducer
