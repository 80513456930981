import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import { FC, useEffect, useState } from 'react'
import styles from './FloatingButton.module.scss'

export const FloatingButton: FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [prevScroll, setPrevScroll] = useState(0)
  const [className, setClassName] = useState('')

  const clickHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {}, [
    document.addEventListener('scroll', () => {
      if (window.scrollY > prevScroll) {
        setIsVisible(true)
        setClassName(styles.in)
      } else {
        setIsVisible(false)
        setClassName(styles.out)
      }

      setPrevScroll(window.scrollY)
    }),
  ])

  return (
    <Button
      icon="pi pi-chevron-up"
      className={classNames(
        styles.floating,
        {
          [styles.visible]: isVisible,
        },
        className
      )}
      onClick={clickHandler}
    />
  )
}
