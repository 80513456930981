import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createMaterial, getMaterials } from './materials.actions'
import { IMaterialsSlice, IMaterialType, Themes } from './materials.types'
import { requestState } from '../../../constants/constants'
import { getProgrammeNames } from '../subject/subject.actions'

const initialState: IMaterialsSlice = {
  data: {
    materials: [],
    subjects: [],
  },
  themes: [],
  getMaterials: requestState,
  createMaterial: requestState,
}

const materialsSlice = createSlice({
  name: 'admin/materials',
  initialState,
  reducers: {},
  extraReducers: {
    [getMaterials.pending.type]: state => {
      state.getMaterials.pending = true
    },

    [getMaterials.fulfilled.type]: (
      state,
      action: PayloadAction<IMaterialType>
    ) => {
      state.getMaterials.pending = false
      state.getMaterials.fulfilled = true
      state.data = action.payload
    },

    [getMaterials.rejected.type]: state => {
      state.getMaterials.pending = false
    },

    [getProgrammeNames.fulfilled.type]: (
      state,
      action: PayloadAction<Themes>
    ) => {
      state.themes = action.payload
    },

    [createMaterial.pending.type]: state => {
      state.createMaterial.pending = true
    },

    [createMaterial.fulfilled.type]: state => {
      state.createMaterial.pending = false
      state.createMaterial.fulfilled = true
    },

    [createMaterial.rejected.type]: state => {
      state.createMaterial.pending = false
    },
  },
})

export default materialsSlice.reducer
