import { StudentLayout } from '../../components/Layout/student/StudentLayout'
import {
  DataTable,
  usePagination,
  UserTemplate,
} from '../../components/DataTable'
import { Toolbar } from '../../components/Toolbar'
import {
  AddUserAccountArg,
  AdminUser,
  UpdateUserAccountArg,
  useAddUserAccountMutation,
  useGetUsersQuery,
  useUpdateUserAccountMutation,
} from '../../api/endpoints/users'
import { Helmet } from 'react-helmet'
import { Toast } from 'primereact/toast'
import React, { useRef, useState } from 'react'
import Loader from '../../components/Loader'
import { Card } from '../../components/Card/Card'
import { Column } from 'primereact/column'
import { NotificationTypes, notify } from '../../utils/notify'
import { Modal } from '../../components/Modal/Modal'
import { ModalContent } from './Modal'
import classNames from 'classnames'

export interface UsersProps {
  directorCompany?: boolean
}
export const Users = ({ directorCompany }: UsersProps) => {
  const [modal, setModal] = useState(false)
  const [initialState, setInitialState] = useState<UpdateUserAccountArg | null>(
    null
  )
  const toast = useRef<Toast>(null)
  const [add, { isLoading: isAddLoading }] = useAddUserAccountMutation()
  const [update, { isLoading: isUpdateLoading }] =
    useUpdateUserAccountMutation()
  const [config, setConfig] = usePagination()
  const { data, isLoading, isFetching } = useGetUsersQuery(config)
  function onCreate(form: AddUserAccountArg) {
    return add(form)
      .unwrap()
      .then(() => {
        notify(
          toast,
          {
            type: NotificationTypes.success,
            content: 'Вы успешно добавили пользователя!',
          },
          true
        )
      })
  }

  function onUpdate(form: UpdateUserAccountArg) {
    return update(form)
      .unwrap()
      .then(() => {
        setModal(false)
        setInitialState(null)
        notify(
          toast,
          {
            type: NotificationTypes.success,
            content: 'Вы успешно обновили пользователя!',
          },
          true
        )
      })
  }
  const rowClassName = (data: AdminUser) =>
    classNames({ 'p-disabled': !data.canBeEdited })

  return (
    <StudentLayout>
      <Helmet title={'Пользователи'} />
      <Toast ref={toast} />
      <Modal
        header={`${initialState ? 'Обновление' : 'Добавление'} пользователя`}
        visible={modal}
        onHide={() => {
          setInitialState(null)
          setModal(false)
        }}
      >
        <ModalContent
          initialState={initialState}
          directorCompany={directorCompany}
          onSubmit={initialState ? onUpdate : onCreate}
          loading={initialState ? isUpdateLoading : isAddLoading}
        />
      </Modal>
      <Card headerClassName={'mt-24'} header={'Пользователи'}>
        {isLoading ? (
          <Loader />
        ) : (
          <DataTable
            selectionMode="single"
            loading={isFetching}
            dataKey={'id'}
            lazy={true}
            value={data?.data.content}
            onPagination={setConfig}
            config={{
              ...config,
              totalRecords: data?.metaData.totalRecordsElement || 0,
            }}
            rowClassName={rowClassName}
            isDataSelectable={event => {
              const rowData = event?.data as AdminUser
              return rowData?.canBeEdited
            }}
            onRowSelect={row => {
              const rowData = row.data as AdminUser
              setModal(true)
              setInitialState({
                id: rowData.id,
                role: rowData.role,
                surname: rowData.surname,
                ...(directorCompany && { filial: rowData.filialId }),
                name: rowData.name,
                password: '',
              })
            }}
            header={
              <Toolbar
                add={{ loading: isAddLoading, onClick: () => setModal(true) }}
              />
            }
          >
            <Column
              field={'name'}
              header="Имя"
              body={rowData => <UserTemplate rowData={rowData} link={'/'} />}
            />
            <Column field={'surname'} header="Фамилия" />
            <Column field={'login'} header="Логин" />
            <Column field={'roleName'} header="Роль" />
            {directorCompany ? (
              <Column field={'filial'} header="Филиал" />
            ) : null}
          </DataTable>
        )}
      </Card>
    </StudentLayout>
  )
}
