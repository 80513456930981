import { Button } from 'primereact/button'
import React from 'react'
import { confirmModal } from '../../../utils/confirm'
import { ConfirmDialog } from 'primereact/confirmdialog'

export const ConfirmButton = ({
  onConfirm,
  loading,
  disabled,
}: {
  onConfirm: () => void
  disabled?: boolean
  loading?: boolean
}) => {
  function onClick() {
    confirmModal({
      message:
        'Вы уверены что хотите закончить пару? Внести изменения возможно только через учебный отдел.',
      accept: onConfirm,
    })
  }
  return (
    <>
      <ConfirmDialog draggable={false} />
      <Button
        loading={loading}
        disabled={disabled}
        onClick={onClick}
        className={'p-button-danger mt-5'}
      >
        {disabled ? 'Пара завершена' : 'Закончить пару'}
      </Button>
    </>
  )
}
