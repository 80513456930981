import { getHomeworks, loadHomework } from './homework.actions'
import { IHomeworkData, IHomeworkState } from './homework.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: IHomeworkState = {
  data: {
    allTasks: 0,
    curTasks: 0,
    passedTasks: 0,
    completedTasks: 0,
    loadedTasks: 0,
    tasks: [],
    subjects: [],
  },
  isLoading: false,
  hasLoaded: false,
}

export const homeworkSlice = createSlice({
  name: 'student/homeworkSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getHomeworks.pending.type]: state => {
      state.isLoading = true
    },

    [getHomeworks.fulfilled.type]: (
      state,
      action: PayloadAction<IHomeworkData>
    ) => {
      state.isLoading = false
      state.hasLoaded = true
      state.data = action.payload
    },

    [getHomeworks.rejected.type]: state => {
      state.isLoading = false
    },

    [loadHomework.fulfilled.type]: (state, action: PayloadAction<number>) => {
      state.data.tasks = state.data.tasks.filter(task => {
        if (task.id === action.payload) {
          task.status = 'В процессе'
        }

        return task
      })

      state.data.loadedTasks++
      state.data.curTasks--
    },
  },
})

export default homeworkSlice.reducer
