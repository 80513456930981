import { Card } from '../../components/Card/Card'
import style from './TimetablePage.module.scss'
import { Button } from 'primereact/button'
import { forEach, map } from 'lodash'
import React, { MouseEventHandler, useMemo, useRef, useState } from 'react'
import { StudentLayout } from '../../components/Layout/student/StudentLayout'
import { dayWorker } from '../../utils'
import { dateFormat, dateFormatWithTime } from '../../constants/constants'
import { Dayjs } from 'dayjs'
import { Toast } from 'primereact/toast'
import { Helmet } from 'react-helmet'
import { Form, ModalContent } from './components/Modal'
import { timeSlots } from './config/config'
import { NotificationTypes, notify } from '../../utils/notify'
import { confirmTooltip } from '../../utils/confirm'
import { Modal } from '../../components/Modal/Modal'
import {
  Lesson,
  RemoveTimetableRangeArg,
  useAddTimetableMutation,
  useDeleteTimetableMutation,
  useDeleteTimetableRangeMutation,
  useGetDataForCreateTimetableQuery,
  useGetTimetableScheduleQuery,
  useUpdateTimetableMutation,
} from '../../api/endpoints/timetable'
import { Dropdown } from '../../components/Dropdown'
import { Table } from './components/Table'
import { Calendar } from '../../components/Calendar'
import classNames from 'classnames'
import { RemoveScheduleModal } from './components/RemoveScheduleModal'

function formatDate(time: Date) {
  return dayWorker(time).format('YYYY-MM-DD HH:mm')
}

function formatTime(date: Dayjs, time: string) {
  const timeObj = dayWorker(time, 'HH:mm')
  return date
    .clone()
    .set('hour', timeObj.get('hour'))
    .set('minute', timeObj.get('minute'))
}

function formatRange(date: Date, fromDate: Date) {
  return formatDate(
    dayWorker(date)
      .set('minutes', dayWorker(fromDate).get('minutes'))
      .set('hour', dayWorker(fromDate).get('hour'))
      .toDate()
  )
}

export type Type =
  | {
      cur_group: undefined
      start_date: string
      end_date: string
      subject: undefined
      userId: number
      number_lesson: number
      rangeDateEnd: string
      rangeDateStart: string
      audience: number
    }
  | {
      id: number
      cur_group: string
      end_date: Date
      start_date: Date
      subject: number
    }
  | null

export interface ScheduleRow {
  time: string
  index: number
  [key: number]: Array<Lesson>
}
interface TimetablePageProps {
  allowEdit?: boolean
}
export const TimetablePage = ({ allowEdit }: TimetablePageProps) => {
  const [modal, setModal] = useState(false)
  const [removeScheduleModal, setRemoveScheduleModal] = useState(false)
  const [activeId, setActiveId] = useState<number | null>(null)
  const [activeLesson, setActiveLesson] = useState<number | null>(null)
  const [initialValues, setInitialValues] = useState<Type>(null)
  const [group, setGroup] = useState<null | number>(null)
  const [audience, setAudience] = useState<null | number>(null)
  const [teacher, setTeacher] = useState<null | number>(null)
  const toast = useRef<Toast>(null)
  const [range, setRange] = useState({
    start: dayWorker().startOf('week'),
    end: dayWorker().endOf('week'),
  })
  const { data, isFetching } = useGetTimetableScheduleQuery({
    start_date: range.start.format(dateFormat),
    end_date: range.end.format(dateFormat),
    audience,
    cur_group: group,
    teacherId: teacher,
  })
  const [delRange, { isLoading: isDeleteRangeLoading }] =
    useDeleteTimetableRangeMutation()
  const [add, { isLoading: isAddLoading }] = useAddTimetableMutation()
  const [update, { isLoading: isUpdateLoading }] = useUpdateTimetableMutation()
  const [del, { isLoading: isDeleteLoading }] = useDeleteTimetableMutation()
  const { data: selectData, isLoading } = useGetDataForCreateTimetableQuery(
    null,
    {
      skip: !allowEdit,
    }
  )

  function nextWeek() {
    const obj = dayWorker(range.start).add(1, 'week')
    setRange({
      start: obj.startOf('week'),
      end: obj.endOf('week'),
    })
  }
  function prevWeek() {
    const obj = dayWorker(range.start).subtract(1, 'week')
    setRange({
      start: obj.startOf('week'),
      end: obj.endOf('week'),
    })
  }

  const schedule = useMemo(() => {
    const rows: Array<ScheduleRow> = map(timeSlots, ([start, end], index) => {
      return {
        time: `${start}-${end}`,
        index: index + 1,
        ...Array.from({ length: 7 }, () => []),
      }
    })
    forEach(data, lesson => {
      const { numberLesson, startDate } = lesson
      const numberRow = rows[numberLesson]
      const dayOfWeek = dayWorker(startDate, dateFormatWithTime).weekday()
      numberRow[dayOfWeek] = [...numberRow[dayOfWeek], lesson]
    })
    return rows
  }, [data])

  function onCreate(form: Form) {
    if (activeLesson !== null && activeLesson !== undefined) {
      add({
        audience: form.audience,
        cur_group: form.cur_group,
        number_lesson: activeLesson,
        subject: form.subject,
        start_date: formatDate(form.start_date),
        end_date: formatDate(form.end_date),
        rangeDateEnd:
          form.range[1] && formatRange(form.range[1], form.end_date),
        rangeDateStart:
          form.range[0] && formatRange(form.range[0], form.start_date),
        teacherId: form.teacher,
      })
        .unwrap()
        .then(() => {
          notify(
            toast,
            {
              type: NotificationTypes.success,
              content: 'Вы успешно создали урок!',
            },
            true
          )
          onHide()
        })
    }
  }

  function onUpdate(form: Form) {
    if (activeId && activeLesson !== null && activeLesson !== undefined) {
      update({
        audience: form.audience,
        cur_group: form.cur_group,
        id: activeId,
        subject: form.subject,
        start_date: formatDate(form.start_date),
        end_date: formatDate(form.end_date),
        teacherId: form.teacher,
      })
        .unwrap()
        .then(() => {
          notify(
            toast,
            {
              type: NotificationTypes.success,
              content: 'Вы успешно обновили урок!',
            },
            true
          )
          onHide()
        })
    }
  }

  function onDelete(event: MouseEventHandler<HTMLButtonElement> | undefined) {
    function request() {
      if (!activeId) return
      del({ lessonId: activeId })
        .unwrap()
        .then(() => {
          notify(
            toast,
            {
              type: NotificationTypes.success,
              content: 'Вы успешно удалили урок!',
            },
            true
          )
          onHide()
        })
    }
    if (event)
      return confirmTooltip(event.currentTarget, {
        message: 'Вы уверены что хотите удалить?',
        accept: request,
      })
    request()
  }

  function onHide() {
    setActiveId(null)
    setInitialValues(null)
    setModal(false)
    setActiveLesson(null)
  }

  function onAdd(column: ScheduleRow, date: dayWorker.Dayjs) {
    if (!allowEdit) return
    setModal(true)
    setActiveLesson(column.index - 1)
    setInitialValues({
      start_date: formatTime(date, timeSlots[column.index - 1][0]).toDate(),
      end_date: formatTime(date, timeSlots[column.index - 1][1]).toDate(),
      subject: undefined,
      cur_group: undefined,
      teacher: undefined,
      range: [],
    })
  }

  function onEdit(column: ScheduleRow, lesson: Lesson) {
    setModal(true)
    setActiveId(lesson.id)
    setInitialValues({
      start_date: dayWorker(lesson.startDate, dateFormatWithTime).toDate(),
      end_date: dayWorker(lesson.endDate, dateFormatWithTime).toDate(),
      subject: lesson.subjectId,
      cur_group: lesson.curGroupId,
      audience: lesson.audience,
      teacher: lesson.teacherId,
    })
    setActiveLesson(column.index - 1)
  }

  function onRemoveSchedule() {
    setRemoveScheduleModal(true)
  }

  function onScheduleModalHide() {
    setRemoveScheduleModal(false)
  }

  function onRemoveScheduleSubmit(args: RemoveTimetableRangeArg) {
    delRange(args)
      .unwrap()
      .then(() => {
        notify(
          toast,
          {
            type: NotificationTypes.success,
            content: 'Вы успешно сняли расписание!',
          },
          true
        )
        onScheduleModalHide()
      })
  }

  return (
    <StudentLayout>
      <Toast ref={toast} />
      <Helmet title={'Расписание'} />
      {allowEdit ? (
        <>
          <Modal
            header={`${activeId ? 'Редактирование' : 'Создание'} расписания`}
            visible={modal}
            onHide={onHide}
          >
            <ModalContent
              onSubmit={activeId ? onUpdate : onCreate}
              initialValues={initialValues}
              onDelete={onDelete}
              update={Boolean(activeId)}
              deleteLoading={isDeleteLoading}
              loading={activeId ? isUpdateLoading : isAddLoading}
            />
          </Modal>
          <Modal
            header={`Удаление расписания`}
            visible={removeScheduleModal}
            onHide={onScheduleModalHide}
          >
            <RemoveScheduleModal
              onSubmit={onRemoveScheduleSubmit}
              loading={isDeleteRangeLoading}
            />
          </Modal>
        </>
      ) : null}
      <Card header={'Расписание занятий'} className={style.card}>
        <div className={style.timetable}>
          <span className={style.header}>Календарь занятий</span>
          <div
            className={classNames(style.wrapper, 'flex flex-wrap gap-4 mt-24')}
          >
            <div className={style.dates}>
              <Button
                onClick={prevWeek}
                icon="pi pi-chevron-left white"
                className="p-button-rounded p-button-outlined"
              />
              <Calendar
                onChange={event => {
                  if (event.value instanceof Date)
                    setRange({
                      start: dayWorker(event.value).startOf('week'),
                      end: dayWorker(event.value).endOf('week'),
                    })
                }}
                dateTemplate={event => {
                  const date = dayWorker(
                    `${event.year}-${event.month + 1}-${event.day}`
                  )
                  const isBetween = date.isBetween(
                    range.start,
                    range.end,
                    'days',
                    '[]'
                  )
                  return (
                    <div
                      className={classNames({
                        [style.active]: isBetween,
                      })}
                    >
                      {event.day}
                    </div>
                  )
                }}
                readOnlyInput
                value={range.start.toDate()}
                dateFormat={`${range.start.format(
                  'DD MMMM YYYY'
                )} - ${range.end.format('DD MMMM YYYY')}`}
              />
              <Button
                onClick={nextWeek}
                icon="pi pi-chevron-right white"
                className="p-button-rounded p-button-outlined"
              />
            </div>
            {allowEdit ? (
              <>
                <Dropdown
                  showClear
                  value={group}
                  options={map(
                    selectData?.dataNameGroups,
                    ({ curGroup, id }) => ({
                      label: curGroup,
                      value: id,
                    })
                  )}
                  onChange={e => setGroup(e.value)}
                  loading={isLoading}
                  placeholder={'Группа'}
                />
                <Dropdown
                  showClear
                  value={audience}
                  options={map(
                    selectData?.audiences,
                    ({ audienceName, id }) => ({
                      label: audienceName,
                      value: id,
                    })
                  )}
                  onChange={e => setAudience(e.value)}
                  loading={isLoading}
                  placeholder={'Аудитория'}
                />
                <Dropdown
                  showClear
                  value={teacher}
                  options={map(selectData?.teachers, ({ fio, id }) => {
                    return {
                      label: fio,
                      value: id,
                    }
                  })}
                  onChange={e => setTeacher(e.value)}
                  loading={isLoading}
                  placeholder={'Преподаватель'}
                />
                <Button onClick={onRemoveSchedule}>Снять с занятий</Button>
              </>
            ) : null}
          </div>
          <Table
            allowEdit={allowEdit}
            disabledTooltip={modal}
            startDate={range.start}
            loading={isFetching}
            data={schedule}
            onEdit={onEdit}
            onAdd={onAdd}
          />
        </div>
      </Card>
    </StudentLayout>
  )
}
