import { ITimetableData } from './timetable.types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../../http'

export const getTimetable = createAsyncThunk<
  ITimetableData,
  { year: number; month: number }
>('student/timetable/getTimetable', async ({ month, year }, thunkAPI) => {
  try {
    // todo sort by date

    const { data } = await http.get('/timetable/getTimetableData', {
      params: {
        month,
        year,
      },
    })

    return data
  } catch (e) {
    return thunkAPI.rejectWithValue(
      'Страница с расписанием не смогла загрузиться, что же делать, что же делать..'
    )
  }
})
