import React, { forwardRef, ReactNode } from 'react'
import { classNames } from 'primereact/utils'
import styles from './Lesson.module.scss'

interface ClassProps {
  children: ReactNode
  disabled?: boolean
  active?: boolean
  absent?: boolean
  late?: boolean
  onClick?: () => void
}
export const Lesson = forwardRef<HTMLButtonElement, ClassProps>(
  ({ children, onClick, disabled, active, absent, late }, ref) => {
    return (
      <button
        ref={ref}
        disabled={disabled}
        onClick={onClick}
        className={classNames(styles.class, {
          [styles.disabled]: disabled,
          [styles.active]: active,
          [styles.absent]: absent,
          [styles.late]: late,
        })}
      >
        {children}
      </button>
    )
  }
)
