import { parseIOSDate } from '../../utils/date/parseIOSDate'

export const useDateFormatter = () => {
  return (date_: string): string => {
    const date = parseIOSDate(date_)

    let m = date.getMonth() + 1
    let year = date.getFullYear()
    const d = date.getDate()

    if (m === 0) {
      m = 12
      year -= 1
    }

    return `${d >= 10 ? d : '0' + d}.${m >= 10 ? m : '0' + m}.${year}`
  }
}
