import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  width: 0,
}

export const sidebarWidthSlice = createSlice({
  name: 'sidebarWidthSlice',
  initialState,
  reducers: {
    setSidebarWidth(state, action: PayloadAction<number>) {
      state.width = action.payload
    },
  },
})

export default sidebarWidthSlice.reducer
