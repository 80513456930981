import http from '../http'
import { BaseQueryFn } from '@reduxjs/toolkit/query'
import type { AxiosRequestConfig } from 'axios'
import { AxiosError } from 'axios'

export const axiosBaseQuery: BaseQueryFn<
  {
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
    errorMessage?: string
  },
  unknown,
  unknown
> = async ({ url, method, data, params, errorMessage }) => {
  try {
    const result = await http({ url, method, data, params, errorMessage })
    return { data: result?.data }
  } catch (axiosError) {
    const err = axiosError as AxiosError
    return {
      error: {
        status: err?.status,
        data: errorMessage,
      },
    }
  }
}
