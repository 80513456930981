import {
  fileLoaderHeader,
  modalItemTemplate,
} from '../../pages/student/HomeworkPage/HomeworkPage'
import {
  FileUpload as PrimeFileUpload,
  FileUploadProps,
} from 'primereact/fileupload'
import './FileUpload.scss'
import { forwardRef } from 'react'

export type FileUploadType = PrimeFileUpload
export const FileUpload = forwardRef<PrimeFileUpload>(
  (props: FileUploadProps, ref) => {
    return (
      <PrimeFileUpload
        ref={ref}
        {...props}
        itemTemplate={modalItemTemplate}
        headerTemplate={fileLoaderHeader}
        multiple={true}
        chooseLabel="Выбрать файлы"
        cancelLabel="Отменить выбор"
        invalidFileSizeMessageSummary="Размер файла(ов) привышает 100 МБ!"
        invalidFileSizeMessageDetail=""
        id="files"
        mode="advanced"
        name="demo[]"
        maxFileSize={104857600}
      />
    )
  }
)
