import { FC } from 'react'
import { Link } from 'react-router-dom'
import { IProps } from './SidebarItem.types'

export const SidebarItem: FC<IProps> = ({
  link,
  img,
  title,
  className = '',
}) => {
  return (
    <Link to={link}>
      <div className={'nav-link flex align-items-center ' + className}>
        <div className='icon'>
          <img src={img} alt={title} />
        </div>
        <div className='text'>{title}</div>
      </div>
    </Link>
  )
}
