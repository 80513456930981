import { IFAQ } from './faq.types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../../http'

export const getFAQ = createAsyncThunk<IFAQ>(
  'student/faq/getFAQ',
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get('/faq/getAllFAQ')

      return data.FAQData
    } catch (e) {
      return thunkAPI.rejectWithValue(
        'Страница с вопросами не смогла загрузиться, что же делать, что же делать..'
      )
    }
  }
)
