import { getStudentInfo, getStudentShortInfo } from './info.actions'
import { IShortInfoData, IInfoState, IInfoData } from './info.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: IInfoState = {
  shortInfo: {
    curGroup: '',
    coins: 0,
  },
  info: {
    // успеваимость
    points: {
      curMeanPoint: 0,
      prevMeanPoints: [],
      points: [],
    },

    // дз
    homework: {
      allTasks: 0,
      curTasks: 0,
      passedTasks: 0,
      completedTasks: 0,
    },

    // учебные материалы
    materials: {
      previewImage: '',
      createdDate: '',
      theme: '',
      name: '',
      source: 'Библиотека',
    },

    // расписание
    timetable: {
      datetime: '',
      theme: '',
      // programs: '',
    },

    // оплата
    payment: {
      necessaryToPay: 0,
      tariff: 0,
      paymentPeriod: '',
    },
  },
  isLoading: false,
  hasLoaded: false,
  isShortLoading: false,
  hasShortLoaded: false,
}

export const infoSlice = createSlice({
  name: 'student/info',
  initialState,
  reducers: {},
  extraReducers: {
    [getStudentShortInfo.pending.type]: state => {
      state.isShortLoading = true
    },

    [getStudentShortInfo.fulfilled.type]: (
      state,
      action: PayloadAction<IShortInfoData>
    ) => {
      state.isShortLoading = false
      state.hasShortLoaded = true
      state.shortInfo = action.payload
    },

    [getStudentShortInfo.rejected.type]: state => {
      state.isShortLoading = false
    },

    [getStudentInfo.pending.type]: state => {
      state.isLoading = true
    },

    [getStudentInfo.fulfilled.type]: (
      state,
      action: PayloadAction<IInfoData>
    ) => {
      state.isLoading = false
      state.hasLoaded = true
      state.info = action.payload
    },

    [getStudentInfo.rejected.type]: state => {
      state.isLoading = false
    },
  },
})

export default infoSlice.reducer
