import { Button, ButtonProps } from 'primereact/button'
import * as React from 'react'

interface ToolbarProps {
  add?: ButtonProps
}
export const Toolbar = ({ add }: ToolbarProps) => {
  return (
    <div style={{ display: 'flex', gap: '1rem' }}>
      {add ? <Button {...add}>Добавить</Button> : null}
    </div>
  )
}
