import { Helmet } from 'react-helmet'
import React, { useEffect, useRef, useState } from 'react'
import { StudentLayout } from '../../../components/Layout/student/StudentLayout'
import styles from '../AttendantPage.module.scss'
import { Card } from '../../../components/Card/Card'
import Loader from '../../../components/Loader'
import { find, map, size } from 'lodash'
import { AttendantTable } from '../components/Table'
import { useParams } from 'react-router-dom'
import {
  useGetPerformanceGroupQuery,
  useGetPresentsDataTeacherQuery,
  useGetPresentsFilterQuery,
  useGetSubjectNameByGroupQuery,
} from '../../../api/endpoints/attendant'
import { FormItem, RowData } from '../utils/utils'
import { ClassType } from '../../../components/ClassType/ClassType'
import { AttendantStudent } from '../../../store/admin/attendant/attendant.types'
import {
  savePresentUserData,
  savePresentUserDataList,
  uploadMaterialAndHomework,
} from '../../../store/admin/attendant/attendant.actions'
import { NotificationTypes, notify } from '../../../utils/notify'
import { Form, ModalContent } from '../components/Modal'
import { dayWorker } from '../../../utils/dateWorker'
import { dateFormatWithTime } from '../../../constants/constants'
import useAppDispatch from '../../../hooks/useAppDispatch'
import { Modal } from '../../../components/Modal/Modal'
import { Dropdown } from '../../../components/Dropdown'
import { Lesson } from '../../../components/Lesson/Lesson'
import { Button } from 'primereact/button'
import { ColorIndicator } from '../../../components/ColorIndicator'

export const AttendantPageGroupView = () => {
  const [modal, setModal] = useState(false)
  const [data, setData] = useState<Array<RowData>>([])
  const { groupId } = useParams()
  const dispatch = useAppDispatch()
  const [classType, setClassType] = useState<number | null>(null)
  const [subject, setSubject] = useState<number | null>(null)
  const [lessonNumber, setLessonNumber] = useState<number | null>(null)
  const timer = useRef<NodeJS.Timeout>()
  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [])
  const { data: lessons, isLoading: areLessonsLoading } =
    useGetPerformanceGroupQuery(
      {
        subjectId: subject,
        groupId: Number(groupId),
      },
      { skip: !groupId || !subject, refetchOnMountOrArgChange: true }
    )
  const { data: subjects, isLoading: areSubjectsLoading } =
    useGetSubjectNameByGroupQuery(
      {
        groupId: Number(groupId),
      },
      { skip: !groupId, refetchOnMountOrArgChange: true }
    )

  const { data: presentsData, refetch } = useGetPresentsDataTeacherQuery(
    {
      lessonId: lessonNumber,
    },
    { skip: !lessonNumber, refetchOnMountOrArgChange: true }
  )

  const { data: filters, isLoading: filtersLoading } =
    useGetPresentsFilterQuery(null, {
      refetchOnMountOrArgChange: true,
    })

  useEffect(() => {
    if (presentsData) {
      setData(presentsData.academicStudentModels)
    }
  }, [presentsData?.academicStudentModels])

  function onRow(
    id: number,
    value: Partial<
      Record<
        keyof Pick<AttendantStudent, 'presentStudent'>,
        keyof Pick<AttendantStudent, 'presentStudent'>
      >
    >
  ) {
    setData(prevState =>
      map(prevState, row =>
        row.presentStudent.id === id
          ? { ...row, presentStudent: { ...row.presentStudent, ...value } }
          : row
      )
    )
  }

  function onSingleUpdate(
    id: number,
    value: Partial<
      Record<
        keyof Pick<AttendantStudent, 'presentStudent'>,
        keyof Pick<AttendantStudent, 'presentStudent'>
      >
    >
  ) {
    const localData = JSON.parse(JSON.stringify(data))
    const student = find(data, findStudent => {
      return findStudent.presentStudent.id === id
    })
    if (!student || (!classType && classType !== 0)) return
    onRow(id, value)
    dispatch(
      savePresentUserData({
        ...student.presentStudent,
        ...value,
        typeMark: classType,
      })
    )
      .unwrap()
      .then(_ => {
        notify(
          window.Toast,
          {
            type: NotificationTypes.success,
            content: 'Информация о студентах успешно сохранена!',
          },
          true
        )
      })
      .catch(e => {
        setData(localData)
      })
  }
  function onMultipleUpdate(
    id: number,
    value: Partial<
      Record<
        keyof Pick<AttendantStudent, 'presentStudent'>,
        keyof Pick<AttendantStudent, 'presentStudent'>
      >
    >
  ) {
    onRow(id, value)
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      setData(prevState => {
        if (classType || classType === 0) {
          dispatch(
            savePresentUserDataList(
              map(prevState, user => ({
                ...user.presentStudent,
                typeMark: classType,
              }))
            )
          )
            .unwrap()
            .then(_ => {
              notify(
                window.Toast,
                {
                  type: NotificationTypes.success,
                  content: 'Информация о студентах успешно сохранена!',
                },
                true
              )
            })
        }
        return prevState
      })
    }, 30000)
  }

  function onSubmit(form: Form) {
    if (!lessonNumber || !groupId) return
    dispatch(
      uploadMaterialAndHomework({
        material_id: form.material,
        lesson_id: Number(lessonNumber),
        cur_group: groupId,
        ...(form.deadLine && {
          deadline: dayWorker(form.deadLine).format(dateFormatWithTime),
        }),
      })
    )
      .unwrap()
      .then(_ => {
        setModal(false)
        refetch()
        notify(
          window.Toast,
          {
            type: NotificationTypes.success,
            content: 'Методический материал успешно прикреплен!',
          },
          true
        )
      })
  }
  const packageModel = presentsData?.methodPackageModel
  return (
    <StudentLayout>
      <Helmet title={'Присутствующие'} />
      <Modal
        header={'Методический материал'}
        visible={modal}
        onHide={() => setModal(false)}
      >
        <ModalContent
          onSubmit={onSubmit}
          subjectId={subject}
          initialValues={
            packageModel
              ? {
                  deadLine: packageModel.deadline
                    ? dayWorker(
                        packageModel.deadline,
                        dateFormatWithTime
                      ).toDate()
                    : new Date(),
                  material: packageModel.material,
                }
              : null
          }
        />
      </Modal>
      <Card
        header={'Присутствующие'}
        className={'mt-24'}
        headerClassName={styles.header}
        contentClassName={'p-24'}
      >
        {areLessonsLoading ? (
          <Loader />
        ) : (
          <>
            <FormItem label={'Выбор предмета'}>
              <Dropdown
                filter
                value={subject}
                options={map(subjects, ({ id, name }) => {
                  return {
                    value: id,
                    label: name,
                  }
                })}
                loading={areSubjectsLoading}
                onChange={e => setSubject(e.value)}
              />
            </FormItem>
            {subject ? (
              <>
                <FormItem label={'Урок №'}>
                  <div className={styles.classWrapper}>
                    {size(lessons)
                      ? map(lessons, ({ numberLesson, lessonId, finished }) => (
                          <Lesson
                            key={lessonId}
                            absent={!finished}
                            active={lessonId === lessonNumber}
                            onClick={() => setLessonNumber(lessonId)}
                          >
                            {numberLesson}
                          </Lesson>
                        ))
                      : 'Уроки отсутсвуют'}
                  </div>
                  <div className={'mt-3'}>
                    <ColorIndicator
                      text={'Пара отмечена со стороны преподавателя'}
                    />
                    <ColorIndicator
                      danger
                      text={'Пара не отмечена со стороны преподавателя'}
                    />
                  </div>
                </FormItem>
                {lessonNumber ? (
                  <>
                    <FormItem label={'Методический пакет'}>
                      <Button onClick={() => setModal(true)}>
                        {presentsData?.methodPackageModel
                          ? 'Обновить'
                          : 'Загрузить'}
                      </Button>
                    </FormItem>
                    <FormItem label={'Тип урока'}>
                      <div className={styles.classWrapper}>
                        {map(filters?.typeMarks, ({ typeId, type }) => (
                          <ClassType
                            key={typeId}
                            active={typeId === classType}
                            onClick={() => setClassType(typeId)}
                          >
                            {type}
                          </ClassType>
                        ))}
                      </div>
                    </FormItem>
                    {classType || classType === 0 ? (
                      <AttendantTable
                        statusLoading={filtersLoading}
                        statusOptions={map(
                          filters?.typeStatuses,
                          ({ status, statusId }) => ({
                            value: statusId,
                            label: status,
                          })
                        )}
                        data={data}
                        onStatusChange={(event, rowData) =>
                          onSingleUpdate(rowData.presentStudent.id, {
                            status: event.value,
                          })
                        }
                        onCommentChange={(event, rowData) =>
                          onMultipleUpdate(rowData.presentStudent.id, {
                            comment: event.target.value,
                          })
                        }
                        onRewardChange={(reward, rowData) =>
                          onMultipleUpdate(rowData.presentStudent.id, {
                            reward,
                          })
                        }
                        onMarkChange={(event, rowData) =>
                          onMultipleUpdate(rowData.presentStudent.id, {
                            mark: event.value,
                          })
                        }
                      />
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}
          </>
        )}
      </Card>
    </StudentLayout>
  )
}
