import useAppSelector from '../../hooks/useAppSelector'
import { StudentLayout } from '../../components/Layout/student/StudentLayout'
import { Helmet } from 'react-helmet'
import React, { useEffect, useRef } from 'react'
import { Toast } from 'primereact/toast'
import { map, size } from 'lodash'
import { Card } from '../../components/Card/Card'
import useAppDispatch from '../../hooks/useAppDispatch'
import {
  getAllNotification,
  saveStatusNotification,
  saveStatusNotificationsList,
} from '../../store/admin/notification/notification.actions'
import Loader from '../../components/Loader'
import { Button } from 'primereact/button'
import { NotificationTypes, notify } from '../../utils/notify'
import { INotification } from '../../store/user/user.types'
import stylePage from './Notification.module.scss'
import { dayWorker } from '../../utils/dateWorker'
import { dateFormatWithTime } from '../../constants/constants'
import { RenderNotifications } from '../../components/Header/StudentHeader'

function sortNotifications(
  notifications: Array<INotification>
): Array<INotification> {
  const copy = JSON.parse(JSON.stringify(notifications)) as Array<INotification>
  copy.sort((a, b) => {
    return dayWorker(a.creationDate, dateFormatWithTime).isAfter(
      dayWorker(b.creationDate, dateFormatWithTime)
    )
      ? 1
      : -1
  })
  return copy
}

export const NotificationsPage = () => {
  const notifications = useAppSelector(
    state => state.userReducer.user.allNotifications
  )
  const { pending } = useAppSelector(
    state => state.userReducer.getAllNotification
  )
  const dispatch = useAppDispatch()
  const toast = useRef<Toast>(null)

  useEffect(() => {
    getData()
  }, [])

  function getData() {
    dispatch(getAllNotification())
      .unwrap()
      .catch(e => {
        notify(toast, { type: NotificationTypes.error, content: e })
      })
  }

  function onCheck(notification: INotification) {
    dispatch(saveStatusNotification({ ...notification, show: true }))
      .unwrap()
      .catch(e => {
        notify(toast, { type: NotificationTypes.error, content: e })
      })
  }

  function onAllCheck() {
    dispatch(
      saveStatusNotificationsList(
        map(notifications.unread, notification => ({
          ...notification,
          show: true,
        }))
      )
    )
      .unwrap()
      .then(_ => getData())
      .catch(e => {
        notify(toast, { type: NotificationTypes.error, content: e })
      })
  }
  const anyNotifications =
    size(notifications.unread) || size(notifications.read)

  return (
    <StudentLayout>
      <Helmet title={'Все уведомления'} />
      <Toast ref={toast} />
      <Card
        headerClassName={'mt-24'}
        header={'Уведомления'}
        contentClassName={stylePage.page}
      >
        {pending ? (
          <Loader />
        ) : (
          <div className={'flex flex-column'} style={{ gap: '1rem' }}>
            {anyNotifications ? (
              <>
                {size(notifications.unread) ? (
                  <div className="flex justify-content-center">
                    <Button className="p-button-sm" onClick={onAllCheck}>
                      Прочитать все
                    </Button>
                  </div>
                ) : null}
                <RenderNotifications
                  notifications={notifications.unread}
                  onCheck={onCheck}
                />
                <RenderNotifications notifications={notifications.read} />
              </>
            ) : (
              'Нет уведомлений!'
            )}
          </div>
        )}
      </Card>
    </StudentLayout>
  )
}
