import { baseApi } from '../../baseApi'
import {
  GetMaterialByIdArg,
  GetMaterialByIdResult,
  updateMaterialArg,
  UpdateMaterialArgs,
} from './materials.types'
import { Themes } from '../../../store/admin/materials/materials.types'

const api = baseApi.injectEndpoints({
  endpoints: builder => ({
    getMaterialById: builder.query<GetMaterialByIdResult, GetMaterialByIdArg>({
      providesTags: ['Material'],
      query: arg => {
        return {
          url: 'materials/getMaterialById',
          method: 'get',
          params: arg,
          errorMessage: 'Не удалось получить информацию о материале!',
        }
      },
    }),
    getProgrammeNames: builder.query<Themes, null | void>({
      query: _ => {
        return {
          url: '/subjects/getSubjects',
          method: 'get',
          errorMessage: 'Не удалось получить информацию о предметах!',
        }
      },
    }),
    updateMaterial: builder.mutation<void | null, UpdateMaterialArgs>({
      invalidatesTags: ['Material'],
      query: arg => ({
        url: 'materials/updateMaterial',
        method: 'post',
        data: arg,
        errorMessage: 'Не удалось обновить материал!',
      }),
    }),
    changeDownloadStatus: builder.mutation<null, updateMaterialArg>({
      invalidatesTags: ['Material'],
      query: arg => ({
        url: 'practice/changeDownloadStatus',
        method: 'post',
        data: arg,
        errorMessage: 'Не удалось поменять статус!',
      }),
    }),
  }),
})

export const {
  useGetMaterialByIdQuery,
  useGetProgrammeNamesQuery,
  useUpdateMaterialMutation,
  useChangeDownloadStatusMutation,
} = api
