import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IErrorState } from './error.types'

const initialState: IErrorState = {
  message: null,
}

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    openErrorPage(state, action: PayloadAction<string>) {
      state.message = action.payload
    },

    clearError(state) {
      state.message = null
    },
  },
})

export default errorSlice.reducer
