import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISubjectSlice, RouteFilter } from './subject.types'
import {
  createSubject,
  getFilterForProgrammeName,
  getProgrammeNames,
} from './subject.actions'
import { requestState } from '../../../constants/constants'

const initialState: ISubjectSlice = {
  hasLoaded: false,
  isLoading: false,
  getSubjects: requestState,
  createSubject: requestState,
  getFilterForProgrammeName: requestState,
  filters: [],
}

const subjectSlice = createSlice({
  name: 'admin/subject',
  initialState,
  reducers: {},
  extraReducers: {
    [getProgrammeNames.pending.type]: state => {
      state.getSubjects.pending = true
    },
    [getProgrammeNames.fulfilled.type]: state => {
      state.getSubjects.fulfilled = true
      state.getSubjects.pending = false
    },
    [createSubject.pending.type]: state => {
      state.createSubject.pending = true
    },
    [createSubject.fulfilled.type]: state => {
      state.createSubject.fulfilled = true
      state.createSubject.pending = false
    },
    [getFilterForProgrammeName.pending.type]: state => {
      state.getFilterForProgrammeName.pending = true
    },
    [getFilterForProgrammeName.fulfilled.type]: (
      state,
      action: PayloadAction<Array<RouteFilter>>
    ) => {
      state.getFilterForProgrammeName.fulfilled = true
      state.getFilterForProgrammeName.pending = false
      state.filters = action.payload
    },
  },
})

export default subjectSlice.reducer
