export const timeSlots = [
  ['9:00', '10:20'],
  ['10:30', '11:50'],
  ['12:00', '13:20'],
  ['13:30', '14:50'],
  ['15:00', '16:20'],
  ['16:30', '17:50'],
  ['18:30', '19:50'],
  ['20:00', '21:20'],
]
