import { map } from 'lodash'
import { ScheduleRow } from '../TimetablePage'
import style from '../TimetablePage.module.scss'
import { useRef } from 'react'
import { Tooltip } from 'primereact/tooltip'
import { Group, Teacher, Time } from '../../../static'
import { Lesson } from '../../../api/endpoints/timetable'
import classNames from 'classnames'
import Loader from '../../../components/Loader'
import { dayWorker } from '../../../utils/dateWorker'
import {
  dateFormatWithTime,
  tableFormat,
  timeFormat,
} from '../../../constants/constants'

interface TableProps {
  disabledTooltip: boolean
  loading: boolean
  data: Array<ScheduleRow>
  onAdd: (column: ScheduleRow, date: dayWorker.Dayjs) => void
  onEdit: (column: ScheduleRow, lesson: Lesson) => void
  startDate: dayWorker.Dayjs
  allowEdit?: boolean
}
const weekdays = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС']

export function Table({
  data,
  onAdd,
  loading,
  startDate,
  onEdit,
  disabledTooltip,
  allowEdit,
}: TableProps) {
  return (
    <div
      className={classNames(style.tableWrapper, { [style.loading]: loading })}
    >
      {loading ? (
        <div className={style.overlay}>
          <Loader />
        </div>
      ) : null}
      <table className={style.table}>
        <thead>
          <tr>
            <th>Время</th>
            {map(weekdays, (weekday, index) => {
              const date = startDate.add(index, 'day')
              return (
                <th key={weekday}>
                  {weekday} {date.format(tableFormat)}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {map(data, row => {
            return (
              <Row
                allowEdit={allowEdit}
                disabledTooltip={disabledTooltip}
                onEdit={onEdit}
                startDate={startDate}
                onAdd={onAdd}
                key={row.index}
                row={row}
              />
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const Row = ({
  startDate,
  row,
  onAdd,
  onEdit,
  disabledTooltip,
  allowEdit,
}: {
  allowEdit?: boolean
  disabledTooltip: boolean
  startDate: dayWorker.Dayjs
  row: ScheduleRow
  onAdd: (column: ScheduleRow, date: dayWorker.Dayjs) => void
  onEdit: (column: ScheduleRow, lesson: Lesson) => void
}) => {
  return (
    <tr>
      <th>{row.time}</th>
      {map(weekdays, (_, index) => {
        const date = startDate.add(index, 'day')
        const cell = row[index]
        return (
          <Cell
            allowEdit={allowEdit}
            disabledTooltip={disabledTooltip}
            onEdit={onEdit}
            scheduleRow={row}
            onAdd={() => onAdd(row, date)}
            key={`${row.index}-${index}`}
            cell={cell}
          />
        )
      })}
    </tr>
  )
}

const Cell = ({
  cell,
  onAdd,
  onEdit,
  scheduleRow,
  disabledTooltip,
  allowEdit,
}: {
  allowEdit?: boolean
  disabledTooltip: boolean
  scheduleRow: ScheduleRow
  cell: ScheduleRow[number]
  onAdd?: () => void
  onEdit: (column: ScheduleRow, lesson: Lesson) => void
}) => {
  return (
    <td>
      <div
        onClick={cell.length === 0 ? onAdd : undefined}
        className={classNames(style.cell, {
          [style.empty]: cell.length === 0,
          'cursor-pointer': allowEdit,
        })}
      >
        {map(cell, (lesson, index) => {
          return (
            <LessonComponent
              className={classNames({
                [style.full]:
                  !allowEdit &&
                  (cell.length === 1 ||
                    (index === cell.length - 1 && cell.length % 2 !== 0)),
              })}
              allowEdit={allowEdit}
              disabledTooltip={disabledTooltip}
              onClick={() => onEdit(scheduleRow, lesson)}
              fullDisplay={cell.length <= 2}
              key={lesson.id}
              lesson={lesson}
            />
          )
        })}
        {allowEdit ? (
          cell.length === 0 ? (
            'Добавить'
          ) : (
            <div
              onClick={onAdd}
              className={classNames(style.lessonAdd, {
                [style.full]: cell.length % 2 === 0,
              })}
            >
              Добавить
            </div>
          )
        ) : null}
      </div>
    </td>
  )
}

const LessonComponent = ({
  lesson,
  fullDisplay,
  onClick,
  disabledTooltip,
  allowEdit,
  className,
}: {
  className: any
  allowEdit?: boolean
  disabledTooltip: boolean
  onClick?: () => void
  lesson: Lesson
  fullDisplay: boolean
}) => {
  const ref = useRef<HTMLDivElement>(null)
  return (
    <>
      <div
        onClick={onClick}
        ref={ref}
        className={classNames(style.lesson, className)}
      >
        <div>{lesson.curGroup}</div>
        {allowEdit ? <div>{lesson.teacher}</div> : null}
        {fullDisplay ? (
          <div>{formatTime(lesson.startDate, lesson.endDate)}</div>
        ) : null}
      </div>
      <Tooltip
        disabled={disabledTooltip}
        className={style.tooltip}
        target={ref}
        autoHide={false}
      >
        <div className={style.row}>
          <Group />
          {lesson.curGroup}
        </div>
        <div className={style.row}>
          <Group />
          {lesson.audienceName}
        </div>
        {allowEdit ? (
          <div className={style.row}>
            <Teacher />
            {lesson.teacher}
          </div>
        ) : null}
        <div className={style.row}>
          <Time />
          {formatTime(lesson.startDate, lesson.endDate)}
        </div>
        <div>
          <div className={style.label}>Предмет:</div>
          <div style={{ marginTop: 6 }}>{lesson.subject}</div>
        </div>
      </Tooltip>
    </>
  )
}

function formatTime(startDate: string, endDate: string) {
  return `${dayWorker(startDate, dateFormatWithTime).format(
    timeFormat
  )} - ${dayWorker(endDate, dateFormatWithTime).format(timeFormat)}`
}
