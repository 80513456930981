import { Avatar } from '../../Avatar'
import React from 'react'
import { Link } from 'react-router-dom'

interface RowData {
  avatarURL: string
  name: string
}

interface UserTemplateProps {
  rowData: RowData
  link?: string
}

export const UserTemplate = ({ rowData, link }: UserTemplateProps) => {
  return (
    <div className={'flex align-items-center'} style={{ gap: 10 }}>
      <Avatar src={rowData?.avatarURL} width={56} />
      {link ? (
        <Link to={link}>{rowData?.name}</Link>
      ) : (
        <span className="image-text">{rowData?.name}</span>
      )}
    </div>
  )
}
