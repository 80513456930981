import { IProfileData, IUpdateProfileActionPayload } from './profile.types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../../http'

export const getProfile = createAsyncThunk<IProfileData, undefined>(
  'student/profile/getProfile',
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get('/students/getProfileData')

      return data
    } catch (e) {
      return thunkAPI.rejectWithValue(
        'Страница с профилем студента не смогла загрузиться, что же делать, что же делать..'
      )
    }
  }
)

export const setProfile = createAsyncThunk<
  IProfileData,
  { id: number; data: IUpdateProfileActionPayload; profileCompleted: boolean }
>(
  'student/profile/setProfile',
  async ({ id, data, profileCompleted }, thunkAPI) => {
    const [surname, name, patronymic] = data.fio.split(' ')

    try {
      let url

      if (profileCompleted) {
        url = '/students/updateProfileStudent'
      } else {
        url = '/students/registrationStudent'
      }

      await http.post(url, {
        ...data,
        userId: id,
        name,
        surname,
        patronymic,
        phoneNumber: data.phone,
      })

      return data
    } catch (e) {
      return thunkAPI.rejectWithValue(
        'Не удалось обновить данные о профиле пользователя!'
      )
    }
  }
)
