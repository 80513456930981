import {
  AddUserAccountArg,
  UpdateUserAccountArg,
  useGetDataForCreateUserQuery,
} from '../../api/endpoints/users'
import { useFormik } from 'formik'
import { requireField } from '../../constants/constants'
import { generatePass } from '../../utils/password/password'
import { FormItem } from '../../components/Form'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from '../../components/Dropdown'
import { map } from 'lodash'
import { Button } from 'primereact/button'
import React from 'react'

interface ModalContentProps {
  initialState: UpdateUserAccountArg | null
  onSubmit: (form: UpdateUserAccountArg | AddUserAccountArg) => Promise<void>
  loading: boolean
  directorCompany: boolean
}

export const ModalContent = ({
  loading,
  onSubmit,
  initialState,
  directorCompany,
}: ModalContentProps) => {
  const formik = useFormik({
    initialValues: initialState || {
      login: '',
      password: '',
      role: 0,
      name: '',
      surname: '',
      ...(directorCompany && { filial: 0 }),
    },
    validate: ({ login, password, role, filial, name, surname }) => {
      let errors: Partial<Record<keyof AddUserAccountArg, any>> = {}
      if (!initialState && !login) errors.login = requireField
      if (!initialState && !password) errors.password = requireField
      if (directorCompany && !filial) errors.filial = requireField
      if (!role) errors.role = requireField
      if (!name) errors.name = requireField
      if (!surname) errors.surname = requireField
      return errors
    },
    onSubmit: values => {
      onSubmit(values).then(() => {
        formik.resetForm()
      })
    },
  })
  function generate() {
    formik.setFieldValue('password', generatePass())
  }
  const { login, password, filial, role, surname, name } = formik.values
  const { data, isLoading, isFetching } = useGetDataForCreateUserQuery()
  return (
    <form onSubmit={formik.handleSubmit} className="p-fluid">
      {initialState ? null : (
        <FormItem value={login} name={'login'} label={'Логин'} formik={formik}>
          <InputText />
        </FormItem>
      )}
      <FormItem value={name} name={'name'} label={'Имя'} formik={formik}>
        <InputText />
      </FormItem>
      <FormItem
        value={surname}
        name={'surname'}
        label={'Фамилия'}
        formik={formik}
      >
        <InputText />
      </FormItem>
      {directorCompany ? (
        <FormItem
          value={filial}
          name={'filial'}
          label={'Филиал'}
          formik={formik}
        >
          <Dropdown
            loading={isLoading || isFetching}
            options={map(data?.filials, ({ id, name }) => ({
              value: id,
              label: name,
            }))}
          />
        </FormItem>
      ) : null}
      <FormItem value={role} name={'role'} label={'Роль'} formik={formik}>
        <Dropdown
          loading={isLoading || isFetching}
          options={map(data?.typeRoleUsers, ({ roleId, role }) => ({
            value: roleId,
            label: role,
          }))}
        />
      </FormItem>
      <FormItem
        value={password}
        name={'password'}
        label={'Пароль'}
        formik={formik}
      >
        <InputText />
      </FormItem>
      <Button
        type={'button'}
        className={'mb-24'}
        style={{ width: 'auto', display: 'block' }}
        onClick={generate}
      >
        Сгенерировать пароль
      </Button>
      <Button
        loading={loading}
        type="submit"
        label={initialState ? 'Обновить' : 'Сохранить'}
        className={'w-auto'}
      />
    </form>
  )
}
