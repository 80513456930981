import React, { ReactNode } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import IndexStudentPage from '../../pages/student/IndexPage'
import MaterialPage from '../../pages/student/MaterialPage'
import StudentTimetablePage from '../../pages/student/TimetablePage'
import ProfilePage from '../../pages/student/ProfilePage'
import ErrorPage from '../../pages/ErrorPage'
import AuthPage from '../../pages/AuthPage'
import { Role } from '../../store/user/user.types'
import { map } from 'lodash'
import {
  AdminPaths,
  DirectorCompanyPaths,
  DirectorFilialPaths,
  EduPaths,
  StudentPaths,
} from './Routing.types'
import { MaterialsPage } from '../../pages/Materials'
import { EduSubjects } from '../../pages'
import { AdminHomeworkPage } from '../../pages/admin/Homework'
import ProgressPage from '../../pages/student/ProgressPage'
import HomeworkPage from '../../pages/student/HomeworkPage'
import { AttendantPage } from '../../pages/Attendant'
import { NotificationsPage } from '../../pages/Notifications'
import { EduGroups } from '../../pages/edu/Groups'
import { Students } from '../../pages/edu/Students'
import { TimetablePage } from '../../pages/Timetable'
import { Users } from '../../pages/Users'
import { MailPage } from '../../pages/Mail'
import { Audience } from '../../pages/edu/Audience'
import { Statistic } from '../../pages/edu/Statistic'
import { AttendanceStatistic } from '../../pages/edu/AttendanceStatistic'
import { AttendantPageLessonView } from '../../pages/Attendant/types/AttendantPageLessonView'
import { AttendantPageGroupView } from '../../pages/Attendant/types/AttendantPageGroupView'

interface Props {
  role: Role
  profileCompleted: boolean
}

interface Route<RolePath> {
  path: RolePath
  element: ReactNode
}

type Routes<RolePath> = Array<Route<RolePath>>

const studentRoutes: Routes<StudentPaths> = [
  { path: StudentPaths.index, element: <IndexStudentPage /> },
  { path: StudentPaths.progress, element: <ProgressPage /> },
  { path: StudentPaths.materials, element: <MaterialPage key={'materials'} /> },
  { path: StudentPaths.homework, element: <HomeworkPage /> },
  { path: StudentPaths.timetable, element: <StudentTimetablePage /> },
  // { path: StudentPaths.payment, element: <PaymentPage /> },
  // { path: StudentPaths.faq, element: <FAQPage /> },
  { path: StudentPaths.profile, element: <ProfilePage /> },
  { path: StudentPaths.mail, element: <MailPage student /> },
  // { path: StudentPaths.chat, element: <ChatPage /> },
  { path: StudentPaths.notifications, element: <NotificationsPage /> },
  {
    path: StudentPaths.practices,
    element: <MaterialPage key={'practices'} practices />,
  },
]

const adminRoutes: Routes<AdminPaths> = [
  // { path: AdminPaths.index, element: <AdminIndexPage /> },
  { path: AdminPaths.attendant, element: <AttendantPage allowEdit /> },
  { path: AdminPaths.materials, element: <MaterialsPage /> },
  {
    path: AdminPaths.timetable,
    element: <TimetablePage allowEdit={false} />,
  },
  { path: AdminPaths.profile, element: <ProfilePage /> },
  { path: AdminPaths.homework, element: <AdminHomeworkPage /> },
  { path: AdminPaths.notifications, element: <NotificationsPage /> },
]

const eduRoutes: Routes<EduPaths> = [
  {
    path: EduPaths.timetable,
    element: <TimetablePage allowEdit />,
  },
  {
    path: EduPaths.attendant,
    element: <AttendantPage />,
  },
  {
    path: `${EduPaths.attendant}/:lessonId/:groupId/:subjectId`,
    element: <AttendantPageLessonView />,
  },
  {
    path: `${EduPaths.attendant}/:groupId`,
    element: <AttendantPageGroupView />,
  },
  { path: EduPaths.materials, element: <MaterialsPage allowEdit /> },
  { path: EduPaths.subjects, element: <EduSubjects /> },
  { path: EduPaths.notifications, element: <NotificationsPage /> },
  { path: EduPaths.groups, element: <EduGroups /> },
  { path: EduPaths.students, element: <Students /> },
  { path: EduPaths.mail, element: <MailPage /> },
  { path: EduPaths.classrooms, element: <Audience /> },
  { path: `${EduPaths.statistics}/:id`, element: <Statistic /> },
  {
    path: `${EduPaths.attendanceStatistic}/:id`,
    element: <AttendanceStatistic />,
  },
]

const directorFilialPaths: Routes<DirectorFilialPaths> = [
  {
    path: DirectorFilialPaths.students,
    element: <Students />,
  },
  {
    path: DirectorFilialPaths.users,
    element: <Users />,
  },
  {
    path: DirectorFilialPaths.timetable,
    element: <TimetablePage allowEdit />,
  },
  {
    path: DirectorFilialPaths.attendant,
    element: <AttendantPage />,
  },
  { path: DirectorFilialPaths.materials, element: <MaterialsPage allowEdit /> },
  { path: DirectorFilialPaths.subjects, element: <EduSubjects /> },
  { path: DirectorFilialPaths.notifications, element: <NotificationsPage /> },
  { path: DirectorFilialPaths.groups, element: <EduGroups /> },
  { path: DirectorFilialPaths.mail, element: <MailPage /> },
  { path: DirectorFilialPaths.classrooms, element: <Audience /> },
]

const directorCompany: Routes<DirectorCompanyPaths> = [
  {
    path: DirectorCompanyPaths.users,
    element: <Users directorCompany />,
  },
  { path: DirectorCompanyPaths.mail, element: <MailPage /> },
]

const routes: Partial<Record<Role, any>> = {
  student: studentRoutes,
  admin: adminRoutes,
  edu: eduRoutes,
  director_company: directorCompany,
  director_filial: directorFilialPaths,
}
export const Routing: React.FC<Props> = ({ role, profileCompleted }) => {
  if (role in routes) {
    if (role === 'student' && !profileCompleted)
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/profile/" element={<ProfilePage />} />
            <Route path={'/error'} element={<ErrorPage />} />
            <Route path="*" element={<Navigate to={'/profile/'} />} />
          </Routes>
        </BrowserRouter>
      )
    return (
      <BrowserRouter>
        <Routes>
          {map(role !== 'anonymous' ? routes[role] : [], route => {
            return <Route key={route.path} {...route} />
          })}
          <Route path={'/error'} element={<ErrorPage />} />
          <Route
            path="*"
            element={
              <Navigate
                to={role !== 'anonymous' ? routes[role][0].path : '/'}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login/" element={<AuthPage />} />
        <Route path="*" element={<Navigate to={'/login/'} />} />
      </Routes>
    </BrowserRouter>
  )
}
