import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import Loader from '../../../components/Loader'
import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import useDateFormatter from '../../../hooks/useDateFormatter'
import {
  getHomeworks,
  loadHomework,
} from '../../../store/student/homework/homework.actions'
import {
  HomeworkStatusColor,
  ITask,
} from '../../../store/student/homework/homework.types'
import { InputText } from 'primereact/inputtext'
import { Tag } from 'primereact/tag'
import { FileUpload } from '../../../components/FileUpload/FileUpload'
import { Toast } from 'primereact/toast'
import './HomeworkPage.scss'
import { StudentLayout } from '../../../components/Layout/student/StudentLayout'
import { downloadFile } from '../../../downloadFile'
import { classNames } from 'primereact/utils'
import { validateUrl } from '../../../utils/validateUrl'
import { checkDeadline } from '../../../utils/date/checkDeadline'
import { NotificationTypes, notify } from '../../../utils/notify'
import { IMaterialLoadingItem } from '../MaterialPage/MaterialPage.types'
import { Modal } from '../../../components/Modal/Modal'
import { FileUploadHeaderTemplateOptions } from 'primereact/fileupload'
import useErrorHandler from '../../../hooks/useErrorHandler'
import { IFilter } from './HomeworkPage.types'
import { Tooltip } from 'primereact/tooltip'

export const modalItemTemplate = (file: any, props: any) => {
  return (
    <div className="flex align-items-center justify-content-between">
      <div className="block">
        <span>{file.name}</span>
      </div>
      <div className="flex">
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2 max-w-fit"
        />
        <div className="ml-2 delete-button-container">
          {props.removeElement}
        </div>
      </div>
    </div>
  )
}

export const fileLoaderHeader = ({
  className,
  chooseButton,
  cancelButton,
}: FileUploadHeaderTemplateOptions) => {
  return (
    <div className={className + ' bg-transparent flex align-content-center'}>
      {chooseButton}
      {cancelButton}
    </div>
  )
}

export const HomeworkPage: FC = () => {
  // state
  const [buttonLoading, setButtonLoading] = useState(false)
  const [homeworkLoading, setHomeworkLoading] = useState<
    IMaterialLoadingItem[]
  >([])
  const [statisticContainerWidth, setStatisticContainerWidth] = useState(1)
  const [tasks, setTasks] = useState<ITask[]>([])
  const [filter, setFilter] = useState<IFilter>({
    status: ['all'],
  })
  const [curTask, setCurTask] = useState<ITask>()
  const [link, setLink] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [files, setFiles] = useState<File[]>()

  // ref
  const toastRef = useRef<Toast>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  // redux
  const id = useAppSelector(state => state.userReducer.user.id)
  const { isLoading, hasLoaded, data } = useAppSelector(
    state => state.homeworkReducer
  )
  const dispatch = useAppDispatch()

  // hooks
  const _ = useDateFormatter()
  const errorHandler = useErrorHandler()

  useEffect(() => {
    document.title = 'Домашнее задание'

    if (!hasLoaded) {
      dispatch(getHomeworks())
        .unwrap()
        .catch(error => errorHandler(error))
    }
  }, [])

  useEffect(() => {
    let qs = data.tasks

    if (!filter) {
      return
    }

    if (filter.status[0] !== 'all') {
      qs = qs.filter(t => filter.status.includes(t.status))
    }

    if (filter.subject && filter.subject.code !== 'all') {
      qs = qs.filter(t => t.name === filter.subject?.code)
    }

    if (filter.theme && filter.theme.code !== 'all') {
      qs = qs.filter(t => t.theme === filter.theme?.code)
    }

    setTasks(qs)
  }, [filter])

  useEffect(() => {
    setTasks(data.tasks)
    setHomeworkLoading(
      data.tasks.map(t => ({
        id: t.id,
        isLoading: false,
      }))
    )
  }, [data.tasks])

  useLayoutEffect(() => {
    function updateSize() {
      setStatisticContainerWidth(
        contentRef.current!.getBoundingClientRect().width - 48
      )
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useEffect(() => {
    if (window.innerWidth <= 1100 && contentRef.current) {
      setStatisticContainerWidth(
        contentRef.current!.getBoundingClientRect().width - 48
      )
    }
  }, [contentRef.current])

  const sendHomeworkHandler = () => {
    setButtonLoading(true)

    dispatch(
      loadHomework({
        id,
        homeworkID: curTask!.id,
        link,
        files,
      })
    )
      .unwrap()
      .then(() => {
        setButtonLoading(false)

        notify(
          toastRef,
          {
            type: NotificationTypes.success,
            content: 'Вы успешно загрузили домашнее задание!',
          },
          true
        )
      })
      .catch(error => {
        setButtonLoading(false)

        notify(toastRef, {
          type: NotificationTypes.error,
          content: error,
        })
      })

    setCurTask(undefined)
    setLink('')
    setFiles(undefined)
    setIsModalVisible(false)
  }

  const modalFooter = (
    <div>
      <Button
        label="Отменить"
        className="p-button-link"
        icon="pi pi-times"
        onClick={() => setIsModalVisible(false)}
      />
      <Button
        label="Отправить"
        icon="pi pi-check"
        disabled={!((link.trim().length && validateUrl(link)) || files?.length)}
        onClick={sendHomeworkHandler}
        loading={buttonLoading}
      />
    </div>
  )

  return (
    <StudentLayout pageName="homework-page">
      <>
        <div className="card mt-24">
          <div className="header flex align-items-center justify-content-between homework-bg">
            <div className="title big">Домашнее задание</div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="content" ref={contentRef}>
              <div className="text fz-20 bold">Общая статистика</div>
              <div
                className="flex justify-content-between mt-24 overflow-x-scroll"
                style={{
                  width: statisticContainerWidth,
                }}
              >
                <div
                  className={classNames('area bg statistic mx-2 ml-0', {
                    selected: filter.status?.includes('all'),
                  })}
                  onClick={() =>
                    setFilter(prev => ({
                      ...prev,
                      status: ['all'],
                    }))
                  }
                >
                  <div className="title">Всего заданий</div>
                  <div className="h1">{data.allTasks}</div>
                </div>
                <div
                  className={classNames('area bg statistic mx-2', {
                    selected: filter.status?.includes('Не сделано'),
                  })}
                  onClick={() =>
                    setFilter(prev => ({
                      ...prev,
                      status: ['Не сделано', 'На пересдаче'],
                    }))
                  }
                >
                  <div className="title">Текущие</div>
                  <div className="h1">{data.curTasks}</div>
                </div>
                <div
                  className={classNames('area bg statistic mx-2', {
                    selected: filter.status?.includes('В процессе'),
                  })}
                  onClick={() =>
                    setFilter(prev => ({
                      ...prev,
                      status: ['В процессе'],
                    }))
                  }
                >
                  <div className="title">Загружено</div>
                  <div className="h1">{data.loadedTasks}</div>
                </div>
                <div
                  className={classNames('area bg statistic mx-2', {
                    selected: filter.status?.includes('Выполнено'),
                  })}
                  onClick={() =>
                    setFilter(prev => ({
                      ...prev,
                      status: ['Выполнено'],
                    }))
                  }
                >
                  <div className="title">Проверено</div>
                  <div className="h1">{data.completedTasks}</div>
                </div>
                <div
                  className={classNames('area bg statistic mx-2', {
                    selected: filter.status?.includes('Просрочено'),
                  })}
                  onClick={() =>
                    setFilter(prev => ({
                      ...prev,
                      status: ['Просрочено'],
                    }))
                  }
                >
                  <div className="title">Просрочено</div>
                  <div className="h1">{data.passedTasks}</div>
                </div>
              </div>
              <div className="text fz-20 bold mt-48">Мои домашние задания</div>
              <div className="flex filter-container mt-24">
                <Dropdown
                  value={filter.subject}
                  className="w-300"
                  options={[
                    {
                      name: '------',
                      code: 'all',
                    },
                    ...data.subjects.map(s => ({
                      name: s.name,
                      code: s.name,
                    })),
                  ]}
                  onChange={event =>
                    setFilter(prev => ({
                      ...prev,
                      subject: event.value,
                      theme: data.subjects
                        .find(s => s.name === event.value.name)
                        ?.themes.includes(prev.theme ? prev.theme!.name : '')
                        ? prev.theme
                        : undefined,
                    }))
                  }
                  optionLabel="name"
                  placeholder="Выберите предмет"
                />
                <Dropdown
                  value={filter.theme}
                  className="ml-24 w-300"
                  options={[
                    {
                      name: '------',
                      code: 'all',
                    },
                    ...(data.subjects
                      .find(s => s.name === filter.subject?.name)
                      ?.themes.map(t => ({
                        name: t,
                        code: t,
                      })) || []),
                  ]}
                  onChange={event =>
                    setFilter(prev => ({
                      ...prev,
                      theme: event.value,
                    }))
                  }
                  optionLabel="name"
                  placeholder="Выберите тему"
                />
              </div>
              <div className="grid">
                {tasks.length ? (
                  tasks.map(task => (
                    <div
                      className="lg:col-4 md:col-6 col-12 task"
                      key={task.name + task.theme}
                    >
                      <div
                        className={classNames(
                          'area material bg flex flex-column justify-content-between h-full',
                          {
                            passed: !(
                              task.status === 'В процессе' ||
                              task.status === 'Выполнено' ||
                              task.status === 'Просрочено' ||
                              task.status === 'На пересдаче'
                            ),
                          }
                        )}
                      >
                        <div className="task-container">
                          <div className="body">
                            <div className="flex justify-content-between">
                              <small className="date block mt-0">
                                {_(task.createdDate)}
                              </small>
                              <div
                                style={{
                                  color: HomeworkStatusColor[task.status],
                                }}
                              >
                                {task.status}
                              </div>
                            </div>
                            <div className="name mt-4" id={`task-${task.id}`}>
                              {task.name}
                              <br />
                              {task.theme}
                            </div>
                            <Tooltip target={`#task-${task.id}`} position="top">
                              {task.name}
                              <br />
                              {task.theme}
                            </Tooltip>
                            <small className="date mt-24">Тема:</small>
                            <div className="mt-4">{task.description}</div>
                          </div>
                          <div className="footer mt-24">
                            <Button
                              label="Скачать ДЗ"
                              loading={
                                homeworkLoading.find(h => h.id === task.id)
                                  ?.isLoading
                              }
                              onClick={() => {
                                setHomeworkLoading(prev =>
                                  prev.map(h => {
                                    if (h.id === task.id) {
                                      h.isLoading = true
                                    }

                                    return h
                                  })
                                )

                                downloadFile(task.lessonLink).finally(() =>
                                  setHomeworkLoading(prev =>
                                    prev.map(h => {
                                      if (h.id === task.id) {
                                        h.isLoading = false
                                      }

                                      return h
                                    })
                                  )
                                )
                              }}
                            />
                            <br />
                            <Button
                              label="Отправить"
                              className="mt-24"
                              onClick={() => {
                                setCurTask(task)
                                setIsModalVisible(true)
                              }}
                              disabled={
                                task.status === 'В процессе' ||
                                task.status === 'Выполнено' ||
                                task.status === 'Просрочено'
                              }
                            />
                            <div
                              className={classNames('mt-24', {
                                'danger-text':
                                  checkDeadline(
                                    new Date(task.deadline),
                                    new Date()
                                  ) &&
                                  (task.status === 'На пересдаче' ||
                                    task.status === 'Не сделано'),
                              })}
                            >
                              Выполнить до: {_(task.deadline)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="fz-20 px-2">Здесь пока ничего нет</div>
                )}
              </div>
            </div>
          )}
        </div>
        <Modal
          withStyle={false}
          header="Отправить домашнее задание"
          visible={isModalVisible}
          style={{ width: '50vw' }}
          footer={modalFooter}
          onHide={() => setIsModalVisible(false)}
        >
          <small className="date">Предмет:</small>
          <div className="fz-20 bold">{curTask?.name}</div>
          <small className="date">Тема:</small>
          <div className="fz-20 bold">{curTask?.theme}</div>
          <form className="mt-48">
            <small className="date">Вставьте ссылку</small>
            <div className="field mt-8">
              <InputText
                disabled={!!files?.length}
                className="w-full"
                id="link"
                placeholder="https://example.com/"
                value={link}
                onChange={event => setLink(event.target.value)}
              />
            </div>
            <small className="date">Или прикрепите файлы</small>
            <div className="field mt-8">
              <FileUpload
                disabled={!!link.trim().length}
                onSelect={(event: any) => setFiles(() => [...event.files])}
                onClear={() => setFiles(undefined)}
                onRemove={event => {
                  setFiles(prev =>
                    prev?.filter(file => file.name !== event.file.name)
                  )
                }}
              />
            </div>
          </form>
        </Modal>
        <Toast ref={toastRef} />
      </>
    </StudentLayout>
  )
}
