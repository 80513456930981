import { StudentLayout } from '../../components/Layout/student/StudentLayout'
import { Helmet } from 'react-helmet'
import { Toast } from 'primereact/toast'
import { Card } from '../../components/Card/Card'
import Loader from '../../components/Loader'
import { DataTable, usePagination } from '../../components/DataTable'
import { Column } from 'primereact/column'
import React, { useRef, useState } from 'react'
import {
  createMessageArg,
  Message,
  useCreateMessageMutation,
  useGetRequestMessagesQuery,
} from '../../api/endpoints/chat'
import { DataTableExpandedRows } from 'primereact/datatable'
import { Divider } from 'primereact/divider'
import { Modal } from '../../components/Modal/Modal'
import { dateFormatWithTime } from '../../constants/constants'
import { Tag } from 'primereact/tag'
import { dayWorker } from '../../utils/dateWorker'
import { NotificationTypes, notify } from '../../utils/notify'
import { MessageView } from './components/MessageView'
import {
  AnswerMailModal,
  AnswerMailModalForm,
} from './components/AnswerMailModal'
import {
  CreateMailModal,
  CreateMailModalForm,
} from './components/CreateMailModal'
import { Toolbar } from '../../components/Toolbar'

interface MailPageProps {
  student?: boolean
}

export const MailPage = ({ student }: MailPageProps) => {
  const toast = useRef<Toast>(null)
  const [config, setConfig] = usePagination()
  const [createModal, setCreateModal] = useState(false)
  const [answerModal, setAnswerModal] = useState(false)
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>(null)
  const [initialValues, setInitialValues] = useState<Omit<
    createMessageArg,
    'text'
  > | null>(null)
  const { data, isLoading, isFetching } = useGetRequestMessagesQuery(config)
  const [add, { isLoading: isCreateLoading }] = useCreateMessageMutation()

  function onAnswerSubmit(values: AnswerMailModalForm) {
    if (!initialValues) return
    add({
      text: values.response,
      ...initialValues,
    })
      .unwrap()
      .then(_ => {
        setAnswerModal(false)
        setInitialValues(null)
        notify(
          toast,
          {
            type: NotificationTypes.success,
            content: 'Вы успешно ответили на обращение!',
          },
          true
        )
      })
  }
  function onCreateSubmit({ theme, message, receiver }: CreateMailModalForm) {
    return add({
      title: theme,
      text: message,
      sendTo: receiver,
    })
      .unwrap()
      .then(_ => {
        setCreateModal(false)
        notify(
          toast,
          {
            type: NotificationTypes.success,
            content: 'Обращение успешно создано!',
          },
          true
        )
      })
  }
  function rowExpansionTemplate({
    id,
    text,
    title,
    sendFromFIO,
    creationDate,
    filial,
    messageAnswer,
  }: Message) {
    return (
      <div>
        <MessageView
          text={text}
          title={title}
          time={dayWorker(creationDate).format(dateFormatWithTime)}
          id={id}
          from={sendFromFIO}
          filial={filial}
        />
        {messageAnswer ? (
          <>
            <Divider />
            <MessageView
              text={messageAnswer.answerMessage}
              title={'Ответ'}
              time={dayWorker(messageAnswer.answerCreationDate).format(
                dateFormatWithTime
              )}
              id={messageAnswer.id}
              from={messageAnswer.fromToFIO}
              filial={filial}
            />
          </>
        ) : null}
      </div>
    )
  }
  function statusBodyTemplate({ answer }: Message) {
    return (
      <Tag
        value={answer ? 'Отвечено' : 'Не отвечено'}
        severity={answer ? 'success' : 'danger'}
      />
    )
  }

  return (
    <StudentLayout>
      <Helmet title={'Обращения'} />
      <Toast ref={toast} />
      {student ? (
        <Modal
          header={'Создать обращение'}
          visible={createModal}
          onHide={() => setCreateModal(false)}
        >
          <CreateMailModal
            onSubmit={onCreateSubmit}
            loading={isCreateLoading}
          />
        </Modal>
      ) : (
        <Modal
          header={'Ответ на обращение'}
          visible={answerModal}
          onHide={() => setAnswerModal(false)}
        >
          <AnswerMailModal
            onSubmit={onAnswerSubmit}
            loading={isCreateLoading}
          />
        </Modal>
      )}
      <Card headerClassName={'mt-24'} header={'Обращения'}>
        {isLoading ? (
          <Loader />
        ) : (
          <DataTable
            selectionMode={student ? undefined : 'single'}
            header={
              student ? (
                <Toolbar
                  add={{
                    onClick: () => setCreateModal(true),
                    loading: isCreateLoading,
                  }}
                />
              ) : null
            }
            isDataSelectable={data => {
              const message = data.data as Message
              return !message.answer
            }}
            onRowSelect={row => {
              const rowData = row.data as Message
              setAnswerModal(true)
              setInitialValues({
                id: rowData.id,
                title: rowData.titleId,
                sendTo: rowData.sendFrom,
              })
            }}
            expandedRows={expandedRows}
            onRowToggle={e => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate}
            onPagination={setConfig}
            loading={isFetching}
            value={data?.data.content}
            config={{
              ...config,
              totalRecords: data?.metaData.totalRecordsElement || 0,
            }}
          >
            <Column expander style={{ width: '2rem' }} />
            <Column field={'title'} header="Тип" />
            <Column field={'id'} body={statusBodyTemplate} header="Статус" />
          </DataTable>
        )}
      </Card>
    </StudentLayout>
  )
}
