import styles from '../MailPage.module.scss'
import { classNames } from 'primereact/utils'
import { Tag } from 'primereact/tag'
import React, { ReactNode } from 'react'

interface MessageViewProps {
  time: ReactNode
  id: ReactNode
  title: ReactNode
  text: ReactNode
  from: ReactNode
  filial?: ReactNode
}

export const MessageView = ({
  id,
  time,
  title,
  text,
  from,
  filial,
}: MessageViewProps) => {
  return (
    <div className={styles.messageView}>
      <div className={'flex align-items-center justify-content-between gap-24'}>
        <span>#{id}</span>
        <span>{time}</span>
      </div>
      <div className={'mt-2'}>
        <div className={styles.title}>{title}</div>
        <div>{text}</div>
      </div>
      <div
        className={
          'flex align-items-center mt-3 justify-content-between gap-24'
        }
      >
        <div className={classNames('mt-3', styles.from)}>{from}</div>
        {filial ? <Tag severity="info" value={filial} /> : null}
      </div>
    </div>
  )
}
