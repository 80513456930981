import { createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../../http'
import { RouteFilter } from './subject.types'
import { Themes } from '../materials/materials.types'

export const createSubject = createAsyncThunk<unknown, { name: string }>(
  'teacher/subjects/deleteMaterial',
  async (params, thunkAPI) => {
    try {
      const { data } = await http.post('/subjects/createProgrammeName', params)
      return data
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось создать предмет!')
    }
  }
)

export const getFilterForProgrammeName = createAsyncThunk<Array<RouteFilter>>(
  'teacher/subjects/getFilterForProgrammeName',
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get('subjects/getFilterForCreateSubject')
      return data
    } catch (e) {
      return thunkAPI.rejectWithValue(
        'Не удалось получить информацию о фильтрах!'
      )
    }
  }
)

export const getProgrammeNames = createAsyncThunk<Themes, { id: number }>(
  'teacher/subjects/getSubjects',
  async (params, thunkAPI) => {
    try {
      const { data } = await http.get('/subjects/getSubjects', {
        params,
      })
      return data
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось получить предметы!')
    }
  }
)
