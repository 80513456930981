import { Helmet } from 'react-helmet'
import React, { useEffect, useRef, useState } from 'react'
import { StudentLayout } from '../../../components/Layout/student/StudentLayout'
import styles from '../AttendantPage.module.scss'
import { Card } from '../../../components/Card/Card'
import Loader from '../../../components/Loader'
import { find, map } from 'lodash'
import { AttendantTable } from '../components/Table'
import { useParams } from 'react-router-dom'
import {
  useGetPresentsDataTeacherQuery,
  useGetPresentsFilterQuery,
} from '../../../api/endpoints/attendant'
import { FormItem, RowData } from '../utils/utils'
import { Button } from 'primereact/button'
import { ClassType } from '../../../components/ClassType/ClassType'
import { AttendantStudent } from '../../../store/admin/attendant/attendant.types'
import {
  savePresentUserData,
  savePresentUserDataList,
  uploadMaterialAndHomework,
} from '../../../store/admin/attendant/attendant.actions'
import { NotificationTypes, notify } from '../../../utils/notify'
import { Form, ModalContent } from '../components/Modal'
import { dayWorker } from '../../../utils/dateWorker'
import { dateFormatWithTime } from '../../../constants/constants'
import useAppDispatch from '../../../hooks/useAppDispatch'
import { Modal } from '../../../components/Modal/Modal'

export const AttendantPageLessonView = () => {
  const [modal, setModal] = useState(false)
  const [data, setData] = useState<Array<RowData>>([])
  const { lessonId, groupId, subjectId } = useParams()
  const dispatch = useAppDispatch()
  const [classType, setClassType] = useState<number | null>(null)
  const timer = useRef<NodeJS.Timeout>()
  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [])
  const {
    data: presentsData,
    refetch,
    isLoading,
  } = useGetPresentsDataTeacherQuery(
    {
      lessonId: Number(lessonId),
    },
    { skip: !Number(lessonId), refetchOnMountOrArgChange: true }
  )
  const { data: filters, isLoading: filtersLoading } =
    useGetPresentsFilterQuery(null, {
      refetchOnMountOrArgChange: true,
    })
  useEffect(() => {
    if (presentsData) {
      setData(presentsData.academicStudentModels)
    }
  }, [presentsData?.academicStudentModels])
  function onRow(
    id: number,
    value: Partial<
      Record<
        keyof Pick<AttendantStudent, 'presentStudent'>,
        keyof Pick<AttendantStudent, 'presentStudent'>
      >
    >
  ) {
    setData(prevState =>
      map(prevState, row =>
        row.presentStudent.id === id
          ? { ...row, presentStudent: { ...row.presentStudent, ...value } }
          : row
      )
    )
  }

  function onSingleUpdate(
    id: number,
    value: Partial<
      Record<
        keyof Pick<AttendantStudent, 'presentStudent'>,
        keyof Pick<AttendantStudent, 'presentStudent'>
      >
    >
  ) {
    const localData = JSON.parse(JSON.stringify(data))
    const student = find(data, findStudent => {
      return findStudent.presentStudent.id === id
    })
    if (!student || (!classType && classType !== 0)) return
    onRow(id, value)
    dispatch(
      savePresentUserData({
        ...student.presentStudent,
        ...value,
        typeMark: classType,
      })
    )
      .unwrap()
      .then(_ => {
        notify(
          window.Toast,
          {
            type: NotificationTypes.success,
            content: 'Информация о студентах успешно сохранена!',
          },
          true
        )
      })
      .catch(e => {
        setData(localData)
      })
  }
  function onMultipleUpdate(
    id: number,
    value: Partial<
      Record<
        keyof Pick<AttendantStudent, 'presentStudent'>,
        keyof Pick<AttendantStudent, 'presentStudent'>
      >
    >
  ) {
    onRow(id, value)
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      setData(prevState => {
        if (classType || classType === 0) {
          dispatch(
            savePresentUserDataList(
              map(prevState, user => ({
                ...user.presentStudent,
                typeMark: classType,
              }))
            )
          )
            .unwrap()
            .then(_ => {
              notify(
                window.Toast,
                {
                  type: NotificationTypes.success,
                  content: 'Информация о студентах успешно сохранена!',
                },
                true
              )
            })
        }
        return prevState
      })
    }, 30000)
  }

  function onSubmit(form: Form) {
    if (!lessonId || !groupId) return
    dispatch(
      uploadMaterialAndHomework({
        material_id: form.material,
        lesson_id: Number(lessonId),
        cur_group: groupId,
        ...(form.deadLine && {
          deadline: dayWorker(form.deadLine).format(dateFormatWithTime),
        }),
      })
    )
      .unwrap()
      .then(_ => {
        setModal(false)
        refetch()
        notify(
          window.Toast,
          {
            type: NotificationTypes.success,
            content: 'Методический материал успешно прикреплен!',
          },
          true
        )
      })
  }
  const packageModel = presentsData?.methodPackageModel
  return (
    <StudentLayout>
      <Helmet title={'Присутствующие'} />
      <Modal
        header={'Методический материал'}
        visible={modal}
        onHide={() => setModal(false)}
      >
        <ModalContent
          onSubmit={onSubmit}
          subjectId={subjectId}
          initialValues={
            packageModel
              ? {
                  deadLine: packageModel.deadline
                    ? dayWorker(
                        packageModel.deadline,
                        dateFormatWithTime
                      ).toDate()
                    : new Date(),
                  material: packageModel.material,
                }
              : null
          }
        />
      </Modal>
      <Card
        header={'Присутствующие'}
        className={'mt-24'}
        headerClassName={styles.header}
        contentClassName={'p-24'}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <FormItem label={'Методический пакет'}>
              <Button onClick={() => setModal(true)}>
                {presentsData?.methodPackageModel ? 'Обновить' : 'Загрузить'}
              </Button>
            </FormItem>
            <FormItem label={'Тип урока'}>
              <div className={styles.classWrapper}>
                {map(filters?.typeMarks, ({ typeId, type }) => (
                  <ClassType
                    key={typeId}
                    active={typeId === classType}
                    onClick={() => setClassType(typeId)}
                  >
                    {type}
                  </ClassType>
                ))}
              </div>
            </FormItem>
            {classType || classType === 0 ? (
              <AttendantTable
                statusLoading={filtersLoading}
                statusOptions={map(
                  filters?.typeStatuses,
                  ({ status, statusId }) => ({
                    value: statusId,
                    label: status,
                  })
                )}
                data={data}
                onStatusChange={(event, rowData) =>
                  onSingleUpdate(rowData.presentStudent.id, {
                    status: event.value,
                  })
                }
                onCommentChange={(event, rowData) =>
                  onMultipleUpdate(rowData.presentStudent.id, {
                    comment: event.target.value,
                  })
                }
                onRewardChange={(reward, rowData) =>
                  onMultipleUpdate(rowData.presentStudent.id, {
                    reward,
                  })
                }
                onMarkChange={(event, rowData) =>
                  onMultipleUpdate(rowData.presentStudent.id, {
                    mark: event.value,
                  })
                }
              />
            ) : null}
          </>
        )}
      </Card>
    </StudentLayout>
  )
}
