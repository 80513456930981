import React, { useState } from 'react'
import styles from '../AttendantPage.module.scss'
import { map } from 'lodash'
import { CifraNoBg, CifraNoBgDisabled } from '../../../static'

interface RatingProps {
  length?: number
  value: number
  onChange: (value: number) => void
  disabled?: boolean
}

export const Rating: React.FC<RatingProps> = ({
  length = 3,
  value,
  onChange,
  disabled,
}) => {
  const [hover, setHover] = useState<number | null>(null)
  return (
    <div className={styles.rating}>
      {map(
        Array.from({ length }, (_, i) => i),
        index => {
          const props = {
            onMouseEnter: () => {
              if (disabled) return
              setHover(index + 1)
            },
            onMouseLeave: () => {
              if (disabled) return
              setHover(null)
            },
            onClick: () => {
              if (disabled) return
              if (value - 1 === index) return onChange(0)
              onChange(index + 1)
            },
          }
          return index < (hover === null ? value : hover) && !disabled ? (
            <CifraNoBg className={styles.ratingItem} key={index} {...props} />
          ) : (
            <CifraNoBgDisabled
              className={styles.ratingItem}
              key={index}
              {...props}
            />
          )
        }
      )}
    </div>
  )
}
