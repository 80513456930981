import { IPaymentData } from './payment.types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getPayment = createAsyncThunk<IPaymentData, undefined>(
  'student/payment/getPayment',
  async (_, thunkAPI) => {
    try {
      return {
        totalPayment: 12600,
        paymentHistory: [
          {
            date: '2023-01-01T16:00:50.303Z',
            payment: 6525,
            status: 'Неоплачено',
          },
          {
            date: '2022-12-01T16:00:50.303Z',
            payment: 6525,
            status: 'Неоплачено',
          },
          {
            date: '2022-11-01T16:00:50.303Z',
            payment: 6525,
            status: 'Оплачено',
          },
        ],
        purposeOfPayment:
          'Цветочкова Ирина Павловна, №2022017985, 25.12.2023 - 25.12.2025, 3D дизайн и анимация   ',
        paymentAccount: '40702810120000008349',
        qr: 'https://privatbankrf.ru/images/qr_code_1.png',
        requisites: `Получатель:
ООО «Цифра»
Юридический адрес:
236006, Калининград, Театральная Д. 35,ЭТАЖ 8, ПОМЕЩ. /ОФИС XLIX/807
ИНН 3906413813
КПП 390601001
ОГРН 1223900006175
р/с 40702810120000008349
ПАО Сбербанк
к/с 30101810100000000634
БИК 042748634
Фактический адрес: г. Калининград улица Театральная 35, 8 этаж, 807 офис.
Телефон: 8 (4012) 795-595
Телефон для отправки оплаченных квитанций: +7 905 245 50 05 (viber, whatsapp, telegram)`,
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(
        'Страница с оплатой не смогла загрузиться, что же делать, что же делать..'
      )
    }
  }
)
