import {
  Calendar as PrimeCalendar,
  CalendarProps as PrimeCalendarProps,
} from 'primereact/calendar'

interface CalendarProps extends PrimeCalendarProps {}

export const Calendar = ({ ...rest }: CalendarProps) => {
  return <PrimeCalendar dateFormat="dd-mm-yy" {...rest} />
}
