import { createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../../http'
import {
  AttendantLessonStatus,
  AttendantMark,
  AttendantStudent,
  INearestLesson,
} from './attendant.types'

export interface getNearestDataLessonResponse {
  nearestLessons: Array<INearestLesson>
  typeMarks: Array<AttendantMark>
  typeStatuses: Array<AttendantLessonStatus>
}

export const getNearestDataLesson =
  createAsyncThunk<getNearestDataLessonResponse | null>(
    'teacher/attendant/getNearestDataLesson',
    async (_, thunkAPI) => {
      try {
        const { data } = await http.get('performance/getNearestDataLesson')
        return data
      } catch (e) {
        return thunkAPI.rejectWithValue(
          'Не удалось получить информацию о ближайшем уроке!'
        )
      }
    }
  )

export type presentsAttendatsType = Array<AttendantStudent>

export const savePresentUserData = createAsyncThunk<
  presentsAttendatsType,
  AttendantStudent['presentStudent']
>('teacher/attendant/savePresentUserData', async (params, thunkAPI) => {
  try {
    const { data } = await http.post('performance/savePresentUserData', params)
    // @ts-ignore
    if (data?.message) return thunkAPI.rejectWithValue(data.message)
    return data
  } catch (e) {
    return thunkAPI.rejectWithValue(
      'Не удалось сохранить информацию о студенте!'
    )
  }
})

export const savePresentUserDataList = createAsyncThunk<
  presentsAttendatsType,
  Array<AttendantStudent['presentStudent']>
>('teacher/attendant/savePresentUserDataList', async (params, thunkAPI) => {
  try {
    const { data } = await http.post(
      'performance/savePresentUserDataList',
      params
    )
    // @ts-ignore
    if (data?.message) return thunkAPI.rejectWithValue(data.message)
    return data
  } catch (e) {
    return thunkAPI.rejectWithValue(
      'Не удалось сохранить информацию о студентах!'
    )
  }
})

export const uploadMaterialAndHomework = createAsyncThunk<
  unknown,
  {
    material_id: number
    deadline?: string
    cur_group: string
    lesson_id: number
  }
>('teacher/attendant/uploadMaterialAndHomework', async (params, thunkAPI) => {
  try {
    const { data } = await http.post(
      'performance/uploadMaterialAndHomework',
      params
    )
    return data
  } catch (e) {
    return thunkAPI.rejectWithValue(
      'Не удалось прикрепить методический материал!'
    )
  }
})
