import { ITimetableState } from './timetable.types'
import { createSlice } from '@reduxjs/toolkit'

const initialState: ITimetableState = {
  data: [],
}

export const timetableSlice = createSlice({
  name: 'admin/timetable',
  initialState,
  reducers: {},
})

export default timetableSlice.reducer
