import { baseApi } from '../../baseApi'
import { GetPracticeResult } from './practice.types'

const api = baseApi.injectEndpoints({
  endpoints: builder => ({
    getPractice: builder.query<GetPracticeResult, void | null>({
      providesTags: ['Practice'],
      query: arg => {
        return {
          url: 'practice/getPractice',
          method: 'get',
          params: arg,
          errorMessage:
            'Не удалось получить информацию о практических работах!',
        }
      },
    }),
    getCountUnShowedPractice: builder.query<number, void | null>({
      providesTags: ['Practice'],
      query: _ => ({
        url: 'practice/getCountUnShowedPractice',
        method: 'get',
        errorMessage:
          'Не удалось получить информацию о количестве практических работ!',
      }),
    }),
  }),
})

export const { useGetPracticeQuery, useLazyGetCountUnShowedPracticeQuery } = api
