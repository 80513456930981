import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { FC, useEffect, useState } from 'react'
import { StudentLayout } from '../../../components/Layout/student/StudentLayout'
import Loader from '../../../components/Loader'
import { downloadFile } from '../../../downloadFile'
import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import useDateFormatter from '../../../hooks/useDateFormatter'
import useErrorHandler from '../../../hooks/useErrorHandler'
import { getMaterials } from '../../../store/student/material/material.actions'
import {
  IMaterial,
  SourceColor,
} from '../../../store/student/material/material.types'
import './MaterialPage.scss'
import { IFilter, IMaterialLoadingItem } from './MaterialPage.types'
import { useGetPracticeQuery } from '../../../api/endpoints/practice'
import { map } from 'lodash'
import { useChangeDownloadStatusMutation } from '../../../api/endpoints/materials'
import { classNames } from 'primereact/utils'

interface MaterialPageProps {
  practices?: boolean
}

export const MaterialPage: FC<MaterialPageProps> = ({ practices }) => {
  // state
  const title = practices ? 'Практические' : 'Учебные материалы'
  const [materialsLoading, setMaterialsLoading] = useState<
    IMaterialLoadingItem[]
  >([])
  const { data: practiceData } = useGetPracticeQuery()
  const [materials, setMaterials] = useState<IMaterial[]>([])
  const [filter, setFilter] = useState<IFilter>({})
  const [change, { isLoading: isChangeLoading }] =
    useChangeDownloadStatusMutation()

  // redux
  const { hasLoaded, isLoading, data } = useAppSelector(
    state => state.materialReducer
  )
  const dispatch = useAppDispatch()
  // hooks
  const _ = useDateFormatter()
  const errorHandler = useErrorHandler()

  useEffect(() => {
    document.title = title

    if (!hasLoaded) {
      dispatch(getMaterials())
        .unwrap()
        .catch(error => errorHandler(error))
    }
  }, [])

  useEffect(() => {
    if (practices) {
      setMaterials(practiceData)
      setMaterialsLoading(
        map(practiceData, m => ({
          id: m.id,
          isLoading: false,
        }))
      )
    }
  }, [practiceData])

  useEffect(() => {
    if (!practices) {
      setMaterials(data.materials)
      setMaterialsLoading(
        data.materials.map(m => ({
          id: m.id,
          isLoading: false,
        }))
      )
    }
  }, [data.materials])

  useEffect(() => {
    let qs = practices ? practiceData || [] : data.materials
    if (!filter.search && !filter.source && !filter.subject && !filter.theme) {
      setMaterials(qs)
      return
    }

    if (filter.source) {
      qs = qs.filter(m => m.source === filter.source)
    }

    if (filter.subject && filter.subject.code !== 'all') {
      qs = qs.filter(m => m.theme === filter.subject?.code)
    }

    if (filter.theme && filter.theme.code !== 'all') {
      qs = qs.filter(m => m.name === filter.theme?.code)
    }

    if (filter.search && filter.search.trim().length) {
      qs = qs.filter(m =>
        m.name
          .toLocaleLowerCase()
          .includes(filter.search!.toLocaleLowerCase().trim())
      )
    }

    setMaterials(qs)
  }, [filter])

  const redirectOrDownload = (link: string, id: number) => {
    setMaterialsLoading(prev =>
      prev.map(m => {
        if (m.id === id) {
          m.isLoading = true
        }

        return m
      })
    )

    if (link.startsWith('http')) {
      window.open(link, '_blank')!.focus()

      setMaterialsLoading(prev =>
        prev.map(m => {
          if (m.id === id) {
            m.isLoading = false
          }

          return m
        })
      )
    } else {
      downloadFile(link).finally(() =>
        setMaterialsLoading(prev =>
          prev.map(m => {
            if (m.id === id) {
              m.isLoading = false
            }

            return m
          })
        )
      )
    }
  }

  const scrollIntoMaterial = (id: number) => {
    document.querySelector(`#m${id}`)?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <StudentLayout pageName="materials-page">
      <div className="card mt-24">
        <div className="header flex align-items-center justify-content-between materials-bg">
          <div className="title big">{title}</div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="content">
            {data.lastMaterials.length && !practices ? (
              <>
                <div className="text fz-20 bold">Последние добавления</div>
                <div className="flex mt-24 justify-content-between last-materials">
                  <div className="col-6 last py-0 pl-0">
                    <div
                      onClick={() =>
                        scrollIntoMaterial(data.lastMaterials[0].id)
                      }
                      className="area bg materials-bg flex flex-column justify-content-between h-full cursor-pointer"
                    >
                      <div>
                        <div className="flex justify-content-between">
                          <div
                            className="source-label"
                            style={{
                              color: SourceColor[data.lastMaterials[0].source],
                              backgroundColor: '#FFFFFF100',
                            }}
                          >
                            {data.lastMaterials[0].source[0]}
                          </div>
                          <small className="date block ml-72 created-date">
                            {_(data.lastMaterials[0].createdDate)}
                          </small>
                          {/*<div className="preview">
                            <img
                              src={data.lastMaterials[0].previewImage}
                              alt="preview"
                            />
                          </div>*/}
                        </div>
                        <div className="text mt-0 fz-20 mt-12">
                          {data.lastMaterials[0].theme}
                        </div>
                        <div className="text fz-20 bold mt-24">
                          {data.lastMaterials[0].name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-content-between w-full overflow-x-scroll">
                    {data.lastMaterials.length >= 2 ? (
                      <a
                        onClick={() =>
                          scrollIntoMaterial(data.lastMaterials[1].id)
                        }
                        className="px-2 first w-full md-mt-24 min-w-300 cursor-pointer"
                      >
                        <div className="area bg flex flex-column justify-content-between h-full bg-pink">
                          <div>
                            <div className="flex justify-content-between md-flex-column">
                              <div
                                className="source-label"
                                style={{
                                  color:
                                    SourceColor[data.lastMaterials[1].source],
                                  backgroundColor:
                                    SourceColor[data.lastMaterials[1].source] +
                                    '20',
                                }}
                              >
                                {data.lastMaterials[1].source[0]}
                              </div>
                              <small className="date block ml-72">
                                {_(data.lastMaterials[1].createdDate)}
                              </small>
                              {/*<div className="preview">
                                <img
                                  src={data.lastMaterials[1].previewImage}
                                  alt="preview"
                                />
                              </div>*/}
                            </div>
                            <div className="text mt-0 fz-20 mt-12">
                              {data.lastMaterials[1].theme}
                            </div>
                            <div className="text fz-20 bold mt-24">
                              {data.lastMaterials[1].name}
                            </div>
                          </div>
                        </div>
                      </a>
                    ) : null}
                    {data.lastMaterials.length >= 3 ? (
                      <a
                        onClick={() =>
                          scrollIntoMaterial(data.lastMaterials[2].id)
                        }
                        className="px-2 w-full md-mt-24 min-w-300 cursor-pointer"
                      >
                        <div className="area bg flex flex-column justify-content-between h-full bg-pink">
                          <div>
                            <div className="flex justify-content-between md-flex-column">
                              <div
                                className="source-label"
                                style={{
                                  color:
                                    SourceColor[data.lastMaterials[2].source],
                                  backgroundColor:
                                    SourceColor[data.lastMaterials[2].source] +
                                    '20',
                                }}
                              >
                                {data.lastMaterials[2].source[0]}
                              </div>
                              <small className="date block ml-72">
                                {_(data.lastMaterials[2].createdDate)}
                              </small>
                              {/*<div className="preview">
                                <img
                                  src={data.lastMaterials[2].previewImage}
                                  alt="preview"
                                />
                              </div>*/}
                            </div>
                            <div className="text mt-0 fz-20 mt-12">
                              {data.lastMaterials[2].theme}
                            </div>
                            <div className="text fz-20 bold mt-24">
                              {data.lastMaterials[2].name}
                            </div>
                          </div>
                        </div>
                      </a>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}
            <div
              className={classNames('text fz-20 bold', { 'mt-48': !practices })}
            >
              {title}
            </div>
            <div className="flex mt-24 p-12 pl-4 source-filter">
              <div className="pair">
                <Button
                  label="Уроки"
                  onClick={() =>
                    // @ts-ignore
                    setFilter(prev => {
                      if (prev.source === 'Уроки') {
                        return { ...prev, source: undefined }
                      }

                      return { ...prev, source: 'Уроки' }
                    })
                  }
                  className="p-button-rounded id-0 min-w-fit"
                  style={{
                    borderColor: SourceColor['Уроки'],
                    background:
                      filter.source === 'Уроки'
                        ? SourceColor['Уроки']
                        : SourceColor['Уроки'] + '20',
                    color:
                      filter.source === 'Уроки' ? '#fff' : SourceColor['Уроки'],
                  }}
                />
                <Button
                  label="Библиотека"
                  onClick={() =>
                    // @ts-ignore
                    setFilter(prev => {
                      if (prev.source === 'Библиотека') {
                        return { ...prev, source: undefined }
                      }

                      return { ...prev, source: 'Библиотека' }
                    })
                  }
                  className="p-button-rounded id-1 ml min-w-fit"
                  style={{
                    borderColor: SourceColor['Библиотека'],
                    background:
                      filter.source === 'Библиотека'
                        ? SourceColor['Библиотека']
                        : SourceColor['Библиотека'] + '20',
                    color:
                      filter.source === 'Библиотека'
                        ? '#fff'
                        : SourceColor['Библиотека'],
                  }}
                />
              </div>
              <div className="pair">
                <Button
                  label="Видео"
                  onClick={() =>
                    // @ts-ignore
                    setFilter(prev => {
                      if (prev.source === 'Видео') {
                        return { ...prev, source: undefined }
                      }

                      return { ...prev, source: 'Видео' }
                    })
                  }
                  className="p-button-rounded id-2 ml min-w-fit"
                  style={{
                    borderColor: SourceColor['Видео'],
                    background:
                      filter.source === 'Видео'
                        ? SourceColor['Видео']
                        : SourceColor['Видео'] + '20',
                    color:
                      filter.source === 'Видео' ? '#fff' : SourceColor['Видео'],
                  }}
                />
                <Button
                  label="Статьи"
                  onClick={() =>
                    // @ts-ignore
                    setFilter(prev => {
                      if (prev.source === 'Статьи') {
                        return { ...prev, source: undefined }
                      }

                      return { ...prev, source: 'Статьи' }
                    })
                  }
                  className="p-button-rounded id-3 ml min-w-fit"
                  style={{
                    borderColor: SourceColor['Статьи'],
                    background:
                      filter.source === 'Статьи'
                        ? SourceColor['Статьи']
                        : SourceColor['Статьи'] + '20',
                    color:
                      filter.source === 'Статьи'
                        ? '#fff'
                        : SourceColor['Статьи'],
                  }}
                />
              </div>
            </div>
            <div className="flex filter-container filter-container mt-12">
              <Dropdown
                value={filter.subject}
                className="w-300"
                options={[
                  {
                    name: '------',
                    code: 'all',
                  },
                  ...data.subjects.map(s => ({
                    name: s.name,
                    code: s.name,
                  })),
                ]}
                onChange={event =>
                  setFilter(prev => ({
                    ...prev,
                    subject: event.value,
                    theme: data.subjects
                      .find(s => s.name === event.value.name)
                      ?.themes.includes(prev.theme ? prev.theme!.name : '')
                      ? prev.theme
                      : undefined,
                  }))
                }
                optionLabel="name"
                placeholder="Выберите предмет"
              />
              <Dropdown
                value={filter.theme}
                className="mt ml-24 w-300"
                options={[
                  {
                    name: '------',
                    code: 'all',
                  },
                  ...(data.subjects
                    .find(s => s.name === filter.subject?.name)
                    ?.themes.map(t => ({
                      name: t,
                      code: t,
                    })) || []),
                ]}
                onChange={event =>
                  setFilter(prev => ({
                    ...prev,
                    theme: event.value,
                  }))
                }
                optionLabel="name"
                placeholder="Выберите тему"
              />
              <span className="p-input-icon-right ml-24 mt">
                <i className="pi pi-search" />
                <InputText
                  value={filter.search}
                  onChange={event =>
                    setFilter(prev => ({
                      ...prev,
                      search: event.target.value,
                    }))
                  }
                  placeholder="Поиск"
                />
              </span>
            </div>
            <div className="grid mt-48">
              {materials.length ? (
                materials.map(material => (
                  <div
                    className="lg:col-4 md:col-6 col-12"
                    key={material.name + material.source}
                    id={`m${material.id}`}
                  >
                    <div className="area material bg flex flex-column justify-content-between h-full">
                      <div className="body">
                        <div className="flex justify-content-between preview-container">
                          <div
                            className="source-label"
                            style={{
                              color: SourceColor[material.source],
                              backgroundColor:
                                SourceColor[material.source] + '20',
                            }}
                          >
                            {material.source[0]}
                          </div>
                          <small className="date block ml-72">
                            {_(material.createdDate)}
                          </small>
                          {/*<div className="preview">
                            <img src={material.previewImage} alt="preview" />
                          </div>*/}
                        </div>
                        <div className="text mt-24" style={{ fontSize: 18 }}>
                          {material.theme}
                        </div>
                        <div className="text bold mt-24">{material.name}</div>
                      </div>
                      <div className="footer mt-24">
                        {practices ? (
                          <Button
                            onClick={() => {
                              redirectOrDownload(
                                material.practiceFile,
                                material.id
                              )
                              change({ practiceId: material.id })
                            }}
                            loading={
                              isChangeLoading ||
                              materialsLoading.find(m => m.id === material.id)
                                ?.isLoading
                            }
                          >
                            Скачать практическую
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              redirectOrDownload(
                                material.pathFileMaterial,
                                material.id
                              )
                            }}
                            loading={
                              materialsLoading.find(m => m.id === material.id)
                                ?.isLoading
                            }
                          >
                            Скачать урок
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="fz-20 px-2">Здесь пока ничего нет</div>
              )}
            </div>
          </div>
        )}
      </div>
    </StudentLayout>
  )
}
