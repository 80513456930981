import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HomeworkHomework, IHomeworkSlice } from './homework.types'
import {
  checkHomework,
  getHomeTaskDataTeacher,
  getHomeTaskFilterTeacher,
  IGetHomeTaskFilterTeacher,
} from './homework.actions'
import { requestState } from '../../../constants/constants'

const initialState: IHomeworkSlice = {
  getHomeTaskFilterTeacher: requestState,
  getHomeTaskDataTeacher: requestState,
  checkHomework: requestState,
  filters: [],
  students: [],
}

const homeworkSlice = createSlice({
  name: 'teacher/homework',
  initialState,
  reducers: {},
  extraReducers: {
    [getHomeTaskFilterTeacher.pending.type]: state => {
      state.getHomeTaskFilterTeacher.pending = true
    },
    [getHomeTaskFilterTeacher.fulfilled.type]: (
      state,
      action: PayloadAction<IGetHomeTaskFilterTeacher>
    ) => {
      state.getHomeTaskFilterTeacher.fulfilled = true
      state.getHomeTaskFilterTeacher.pending = false
      state.filters = action.payload.filtersTeacherGroupSubjects
    },
    [getHomeTaskFilterTeacher.rejected.type]: state => {
      state.getHomeTaskFilterTeacher.pending = false
    },
    [getHomeTaskDataTeacher.pending.type]: state => {
      state.getHomeTaskDataTeacher.pending = true
    },
    [getHomeTaskDataTeacher.fulfilled.type]: (
      state,
      action: PayloadAction<Array<HomeworkHomework>>
    ) => {
      state.getHomeTaskDataTeacher.fulfilled = true
      state.getHomeTaskDataTeacher.pending = false
      state.students = action.payload
    },
    [getHomeTaskDataTeacher.rejected.type]: state => {
      state.getHomeTaskDataTeacher.pending = false
    },
    [checkHomework.pending.type]: state => {
      state.checkHomework.pending = true
    },

    [checkHomework.fulfilled.type]: state => {
      state.checkHomework.pending = false
      state.checkHomework.fulfilled = true
    },

    [checkHomework.rejected.type]: state => {
      state.checkHomework.pending = false
    },
  },
})

export default homeworkSlice.reducer
