import { Dayjs } from 'dayjs'
import { dayWorker } from '../dateWorker'

export function dateIsNotNegative(date: Dayjs): boolean {
  const yearDiff = dayWorker().diff(date, 'year')
  const monthDiff = dayWorker().diff(date, 'month')
  const dayDiff = dayWorker().diff(date, 'day')

  return yearDiff >= 0 && monthDiff >= 0 && dayDiff > 0
}
