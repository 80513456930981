import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAttendantSlice } from './attendant.types'
import { requestState } from '../../../constants/constants'
import {
  getNearestDataLesson,
  getNearestDataLessonResponse,
  uploadMaterialAndHomework,
} from './attendant.actions'
import { getMaterials } from '../materials/materials.actions'
import { IMaterialType } from '../materials/materials.types'

const initialState: IAttendantSlice = {
  getMaterials: requestState,
  uploadMaterialAndHomework: requestState,
  getNearestDataLesson: requestState,
  typeMarks: [],
  typeStatuses: [],
  materials: [],
  nearestLessons: [],
}

const attendantSlice = createSlice({
  name: 'admin/attendant',
  initialState,
  reducers: {},
  extraReducers: {
    [getNearestDataLesson.pending.type]: state => {
      state.getNearestDataLesson.pending = true
    },
    [getNearestDataLesson.fulfilled.type]: (
      state,
      action: PayloadAction<getNearestDataLessonResponse | null>
    ) => {
      state.getNearestDataLesson.fulfilled = true
      state.getNearestDataLesson.pending = false
      if (!action.payload) return
      state.typeStatuses = action.payload.typeStatuses
      state.nearestLessons = action.payload.nearestLessons
      state.typeMarks = action.payload.typeMarks
    },
    [getNearestDataLesson.rejected.type]: state => {
      state.getNearestDataLesson.pending = false
    },
    [getMaterials.pending.type]: state => {
      state.getMaterials.pending = true
    },
    [getMaterials.fulfilled.type]: (
      state,
      action: PayloadAction<IMaterialType>
    ) => {
      state.getMaterials.fulfilled = true
      state.getMaterials.pending = false
      state.materials = action.payload?.materials
    },
    [getMaterials.rejected.type]: state => {
      state.getMaterials.pending = false
    },
    [uploadMaterialAndHomework.pending.type]: state => {
      state.uploadMaterialAndHomework.pending = true
    },
    [uploadMaterialAndHomework.fulfilled.type]: state => {
      state.uploadMaterialAndHomework.fulfilled = true
      state.uploadMaterialAndHomework.pending = false
    },
    [uploadMaterialAndHomework.rejected.type]: state => {
      state.uploadMaterialAndHomework.pending = false
    },
  },
})

export default attendantSlice.reducer
