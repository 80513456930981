import { StudentLayout } from '../../../components/Layout/student/StudentLayout'
import { Card } from '../../../components/Card/Card'
import { Column } from 'primereact/column'
import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import { requireField } from '../../../constants/constants'
import { classNames } from 'primereact/utils'
import { Toast } from 'primereact/toast'
import { NotificationTypes, notify } from '../../../utils/notify'
import { Modal } from '../../../components/Modal/Modal'
import Loader from '../../../components/Loader'
import { InputText } from 'primereact/inputtext'
import { useAddGroupMutation, useGetGroupsQuery } from '../../../api'
import { DataTable } from '../../../components/DataTable'
import { Toolbar } from '../../../components/Toolbar'
import { EduPaths } from '../../../components/Routing/Routing.types'
import { Link } from 'react-router-dom'

export const Groups = () => {
  const [modal, setModal] = useState(false)
  const toast = useRef<Toast>(null)
  const { data, isLoading, isFetching } = useGetGroupsQuery()
  const [add, { isLoading: isAddLoading }] = useAddGroupMutation()

  function onSubmit({ groupName }: Form) {
    const data = new FormData()
    data.append('groupName', groupName)
    return add(data)
      .unwrap()
      .then(() => {
        notify(
          toast,
          {
            type: NotificationTypes.success,
            content: 'Вы успешно создали группу!',
          },
          true
        )
      })
      .catch(e => {
        notify(toast, { type: NotificationTypes.error, content: e })
      })
  }

  return (
    <StudentLayout>
      <Helmet title={'Группы'} />
      <Toast ref={toast} />
      <Modal
        header={'Создание Группы'}
        visible={modal}
        onHide={() => setModal(false)}
      >
        <ModalContent onSubmit={onSubmit} loading={isAddLoading} />
      </Modal>
      <Card headerClassName={'mt-24'} header={'Группы'}>
        {isLoading ? (
          <Loader />
        ) : (
          <DataTable
            header={
              <Toolbar
                add={{ onClick: () => setModal(true), loading: isAddLoading }}
              />
            }
            loading={isFetching}
            value={data}
          >
            <Column
              sortable
              field={'curGroup'}
              body={rowData => (
                <Link to={`${EduPaths.attendant}/${rowData.id}`}>
                  {rowData.curGroup}
                </Link>
              )}
              header="Название"
            />
          </DataTable>
        )}
      </Card>
    </StudentLayout>
  )
}

interface Form {
  groupName: string
}

const ModalContent = ({
  onSubmit,
  loading,
}: {
  onSubmit: (form: Form) => Promise<void>
  loading: boolean
}) => {
  const formik = useFormik({
    initialValues: {
      groupName: '',
    },
    validate: ({ groupName }) => {
      let errors: Partial<Record<keyof Form, any>> = {}
      if (!groupName) errors.groupName = requireField
      return errors
    },
    onSubmit: values => {
      onSubmit(values).then(() => formik.resetForm())
    },
  })

  const isFormFieldValid = (name: keyof Form) =>
    !!(formik.touched[name] && formik.errors[name])
  const getFormErrorMessage = (name: keyof Form) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    )
  }
  const { groupName } = formik.values
  return (
    <form onSubmit={formik.handleSubmit} className="p-fluid">
      <div className="field">
        <span className="p-float-label">
          <InputText
            id="groupName"
            name="groupName"
            value={groupName}
            onChange={formik.handleChange}
            className={classNames({
              'p-invalid': isFormFieldValid('groupName'),
            })}
          />
          <label
            htmlFor="groupName"
            className={classNames({
              'p-error': isFormFieldValid('groupName'),
            })}
          >
            Название группы
          </label>
        </span>
        {getFormErrorMessage('groupName')}
      </div>
      <Button
        loading={loading}
        type="submit"
        label="Сохранить"
        className={'w-auto'}
      />
    </form>
  )
}
