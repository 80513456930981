import axios from 'axios'
import { NotificationTypes, notify } from './utils/notify'

const prodUrl = process.env.REACT_APP_BASE_URL || 'http://185.20.227.119:8080'
const devUrl =
  process.env.REACT_APP_BASE_DEV_URL || 'http://185.20.227.119:8081'

export const baseURL = process.env.NODE_ENV === 'development' ? devUrl : prodUrl

export const isValidCode = (code: number) => code === 200 || code === 201

const http = axios.create({
  withCredentials: true,
  baseURL,
})

http.interceptors.request.use(config => {
  config.headers!.Authorization = localStorage.getItem('access-token')
  return config
})

http.interceptors.response.use(
  async config => {
    if (isValidCode(config.data.statusCode)) {
      config.data = config.data?.data
      return config
    }
    notify(
      window.Toast,
      {
        type: NotificationTypes.error,
        content: config.data?.message,
      },
      true
    )
    return Promise.reject(config)
  },
  async error => {
    const originalRequest = error.config
    if (
      error.response?.status === 400 &&
      error.response.data?.message === 'Ошибка ключа доступа' &&
      originalRequest &&
      originalRequest._isRetry !== true
    ) {
      originalRequest._isRetry = true
      const { data } = await axios.post(baseURL + '/users/refreshToken', {
        refreshToken: localStorage.getItem('refresh-token'),
      })

      localStorage.setItem('access-token', data?.data?.accessToken)
      localStorage.setItem('refresh-token', data?.data?.refreshToken)

      return http.request(originalRequest!)
    }
    notify(
      window.Toast,
      {
        type: NotificationTypes.error,
        content: error?.errorMessage,
      },
      true
    )
    return Promise.reject(error)
  }
)

export default http
