import ru from 'dayjs/locale/ru'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import dayjs from 'dayjs'

dayjs.locale(ru)
dayjs.extend(customParseFormat)
dayjs.extend(isBetween)
dayjs.extend(localeData)
dayjs.extend(weekday)

export { dayjs as dayWorker }
