import { Dialog, DialogProps } from 'primereact/dialog'
import { ReactNode } from 'react'
import styles from '../../pages/Attendant/AttendantPage.module.scss'
import { classNames } from 'primereact/utils'

interface ModalProps extends DialogProps {
  children: ReactNode
  withStyle?: boolean
}

export const Modal = ({
  children,
  resizable = false,
  draggable = false,
  closeOnEscape = true,
  footer = null,
  blockScroll = true,
  className,
  withStyle = true,
  ...rest
}: ModalProps) => {
  return (
    <Dialog
      closeOnEscape={closeOnEscape}
      resizable={resizable}
      draggable={draggable}
      footer={footer}
      blockScroll={blockScroll}
      className={classNames(className, {
        [styles.modal]: withStyle,
      })}
      {...rest}
    >
      {children}
    </Dialog>
  )
}
