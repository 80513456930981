import { baseApi } from '../../baseApi'
import { Group } from './group.types'

const api = baseApi.injectEndpoints({
  endpoints: builder => ({
    getGroups: builder.query<Array<Group>, void | null>({
      providesTags: ['Group'],
      query: _ => ({
        url: 'groups/getAllGroups',
        method: 'get',
        errorMessage: 'Не удалось получить информацию о группах!',
      }),
    }),
    addGroup: builder.mutation<void, FormData>({
      invalidatesTags: ['Group'],
      query: arg => ({
        url: 'groups/createGroup',
        method: 'post',
        data: arg,
        errorMessage: 'Не удалось создать группу!',
      }),
    }),
  }),
})

export const { useGetGroupsQuery, useAddGroupMutation } = api
