import { ILoginAction, ISetAvatarURLAction, IUser } from './user.types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import http, { baseURL } from '../../http'

export const login = createAsyncThunk<IUser, ILoginAction>(
  'user/login',
  async ({ login, password }, thunkAPI) => {
    try {
      const { data: loginData } = await http.post('/users', {
        login,
        password,
      })
      localStorage.setItem('access-token', loginData.accessToken)
      localStorage.setItem('refresh-token', loginData.refreshToken)

      const { data } = await http.get('/users/getUserById', {})

      return {
        ...data,
        avatarURL: baseURL + '/' + data.avatarURL,
      }
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось авторизоваться!')
    }
  }
)

export const getUserInfo = createAsyncThunk<IUser, undefined>(
  'user/getUserInfo',
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get('/users/getUserById')

      return {
        ...data,
        avatarURL: baseURL + '/' + data.avatarURL,
        notifications: [],
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(
        'Не удалось получить данные пользователя!'
      )
    }
  }
)

export const setAvatarURL = createAsyncThunk<string, ISetAvatarURLAction>(
  'user/setAvatarURL',
  async ({ id, avatar }, thunkAPI) => {
    try {
      const fd = new FormData()

      fd.append('user_id', id.toString())
      fd.append('file', avatar)

      const { data } = await http.post('/students/uploadAvatar', fd)

      return baseURL + '/' + data
    } catch (e) {
      return thunkAPI.rejectWithValue(
        'Не удалось загрузить фотографию пользователя!'
      )
    }
  }
)
