import { useFormik } from 'formik'
import { InputText } from 'primereact/inputtext'
import { InputMask } from 'primereact/inputmask'
import { InputNumber } from 'primereact/inputnumber'
import { Calendar } from 'primereact/calendar'
import { classNames } from 'primereact/utils'
import { FC, useEffect, useRef, useState } from 'react'
import { StudentLayout } from '../../../components/Layout/student/StudentLayout'
import useAppSelector from '../../../hooks/useAppSelector'
import './ProfilePage.scss'
import { Button } from 'primereact/button'
import useAppDispatch from '../../../hooks/useAppDispatch'
import { setAvatarURL } from '../../../store/user/user.actions'
import { actions } from '../../../store/user/user.slice'
import { Toast } from 'primereact/toast'
import Loader from '../../../components/Loader'
import avatarImg from '../../../static/avatar.svg'
import {
  getProfile,
  setProfile,
} from '../../../store/student/profile/profile.actions'
import { IUpdateProfileActionPayload } from '../../../store/student/profile/profile.types'
import { validateEmail } from '../../../utils/validateEmail'
import { NotificationTypes, notify } from '../../../utils/notify'
import { dayWorker } from '../../../utils/dateWorker'
import { num_word } from '../../Attendant/utils/utils'
import useErrorHandler from '../../../hooks/useErrorHandler'
import { dateIsNotNegative } from '../../../utils/date/dateIsNotNegative'

export const ProfilePage: FC = () => {
  const getInitialValues = () => ({
    fio: firstName
      ? `${lastName} ${firstName}` +
        (patronymicName ? ` ${patronymicName}` : '')
      : '',
    contractNumber: String(data.contractNumber).replaceAll(' ', ''),
    password: '',
    birthday: data.birthday || new Date().toString(),
  })

  // state and ref
  const [avatarLoading, setAvatarLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [putInitialData, setPutInitialData] = useState(false)
  const [visible, setVisible] = useState(false)
  const toastRef = useRef<Toast>(null)

  // redux
  const { profileCompleted } = useAppSelector(state => state.userReducer)
  const { id, firstName, lastName, patronymicName, avatarURL } = useAppSelector(
    state => state.userReducer.user
  )
  const { setFIO, setProfileCompleted } = actions
  const { isLoading, hasLoaded, data } = useAppSelector(
    state => state.profileReducer
  )
  const dispatch = useAppDispatch()

  // hooks
  const errorHandler = useErrorHandler()

  const formik = useFormik<IUpdateProfileActionPayload>({
    initialValues: {
      ...data,
      ...getInitialValues(),
    },
    validate(data) {
      let errors: Partial<Record<keyof IUpdateProfileActionPayload, string>> =
        {}
      const fioLength = data.fio ? data.fio.trim().split(' ').length : 0

      if (!data.fio?.trim().length) errors.fio = 'ФИО обязателено!'
      if (!data.birthday) errors.birthday = 'Дата рождения обязателено!'
      if (!dateIsNotNegative(dayWorker(data.birthday)))
        errors.birthday = 'Возраст должен быть положительным!'
      if (!data.email?.trim().length) errors.email = 'Email обязателен!'
      if (!data.phone?.toString().trim().length)
        errors.phone = 'Телефон обязателен!'
      if (!data.country?.trim().length) errors.country = 'Страна обязателена!'
      if (!data.city?.trim().length) errors.city = 'Город обязателен!'
      if (!data.address?.trim().length) errors.address = 'Адрес обязателен!'
      if (!data.password?.trim().length && !profileCompleted)
        errors.password = 'Пароль обязателен!'
      if (!data.contractNumber?.trim().length)
        errors.contractNumber = '№ договора обязателен!'
      if (!(fioLength === 2 || fioLength === 3))
        errors.fio = 'Введите фамилию и имя'

      if (!validateEmail(data.email)) errors.email = 'Введите корректный email!'

      return errors
    },
    onSubmit(data) {
      setButtonLoading(true)

      const name = data.fio.split(' ')

      if (avatarURL.endsWith('undefined')) {
        setButtonLoading(false)
        return toastRef.current!.show({
          severity: 'info',
          summary: 'Обратите внимание',
          detail: 'Для завершения регистрации необходимо загрузить фотографию',
          life: 5000,
        })
      }

      dispatch(
        setProfile({
          id,
          data,
          profileCompleted,
        })
      )
        .unwrap()
        .then(() => {
          setButtonLoading(false)

          notify(
            toastRef,
            {
              type: NotificationTypes.success,
              content: 'Ваши данные были успешно обновлены',
            },
            true
          )

          dispatch(setProfileCompleted(true))
        })
        .catch(error => {
          setButtonLoading(false)

          notify(toastRef, {
            type: NotificationTypes.error,
            content: error,
          })
        })

      dispatch(
        setFIO({
          patronymicName: name[2],
          firstName: name[1],
          lastName: name[0],
        })
      )
    },
  })

  useEffect(() => {
    if (!hasLoaded) {
      dispatch(getProfile())
        .unwrap()
        .catch(error => errorHandler(error))
    }
  }, [])

  useEffect(() => {
    if (!profileCompleted) {
      setTimeout(() => {
        toastRef.current!.show({
          severity: 'info',
          summary: 'Обратите внимание',
          detail:
            'Завершите регистрацию и загрузите фотографию, чтобы приступить к обучению',
          life: 5000,
        })
      }, 1000)
    }
  }, [profileCompleted])

  useEffect(() => {
    if (data.contractNumber && !putInitialData) {
      formik.setValues(prev => ({
        ...prev,
        ...data,
        ...getInitialValues(),
      }))

      setPutInitialData(true)
    }
  }, [data])

  const isFormFieldValid = (name: string) =>
    // @ts-ignore
    !!(formik.touched[name] && formik.errors[name])

  const getFormErrorMessage = (name: string) => {
    return (
      isFormFieldValid(name) && (
        // @ts-ignore
        <small className="p-error">{formik.errors[name]}</small>
      )
    )
  }

  const toggleVisible = () => setVisible(prev => !prev)
  const diff = dayWorker().diff(dayWorker(data.birthday), 'year')

  return (
    <StudentLayout pageName="profile-page">
      <div className="card mt-24">
        <div className="header flex align-items-center justify-content-between profile-bg">
          <div className="title big">Личный кабинет</div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="content">
            <div className="profile flex flex-column align-items-center mt-24">
              <div className="img">
                <img
                  src={avatarURL.endsWith('undefined') ? avatarImg : avatarURL}
                  alt="avatar"
                />
                <label
                  htmlFor="avatar"
                  className={classNames({
                    loading: avatarLoading,
                  })}
                >
                  {avatarLoading ? (
                    <i className="pi pi-spin pi-spinner text-white"></i>
                  ) : (
                    <i className="pi pi-arrow-up text-white"></i>
                  )}
                </label>
                <input
                  disabled={avatarLoading}
                  type="file"
                  id="avatar"
                  accept="image/*"
                  onChange={event => {
                    const avatar = event.target.files?.item(0)

                    if (avatar) {
                      setAvatarLoading(true)

                      dispatch(
                        setAvatarURL({
                          id,
                          avatar,
                        })
                      )
                        .unwrap()
                        .catch(error =>
                          notify(toastRef, {
                            type: NotificationTypes.error,
                            content: error,
                          })
                        )
                        .finally(() => setAvatarLoading(false))
                    }
                  }}
                />
              </div>
              {profileCompleted ? (
                <>
                  <div className="mt-48 fz-40 text-black">
                    {lastName} {firstName}
                  </div>
                  <small className="date mt-24">
                    {data.country}, {data.city}, {diff}{' '}
                    {num_word(diff, ['год', 'года', 'лет'])}
                  </small>
                </>
              ) : null}
            </div>
            <form onSubmit={formik.handleSubmit} className="grid form mt-64">
              <div className="field lg:col-3 md:col-6 col-12">
                <span className="p-float-label">
                  <InputText
                    id="fio"
                    value={formik.values.fio}
                    onChange={formik.handleChange}
                    className={
                      classNames({
                        'p-invalid': isFormFieldValid('fio'),
                      }) + ' w-full'
                    }
                  />
                  <label
                    htmlFor="fio"
                    className={classNames({
                      'p-error': isFormFieldValid('fio'),
                    })}
                  >
                    ФИО
                  </label>
                </span>
                {getFormErrorMessage('fio')}
              </div>
              <div className="field lg:col-3 md:col-6 col-12">
                <span className="p-float-label">
                  <Calendar
                    id="birthday"
                    value={new Date(formik.values.birthday)}
                    onChange={formik.handleChange}
                    dateFormat="dd-mm-yy"
                    className={
                      classNames({
                        'p-invalid': isFormFieldValid('birthday'),
                      }) + ' w-full'
                    }
                  />
                  <label
                    htmlFor="birthday"
                    className={classNames({
                      'p-error': isFormFieldValid('birthday'),
                    })}
                  >
                    Дата рождения
                  </label>
                </span>
                {getFormErrorMessage('birthday')}
              </div>
              <div className="field lg:col-3 md:col-6 col-12">
                <span className="p-float-label">
                  <InputText
                    id="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className={
                      classNames({
                        'p-invalid': isFormFieldValid('email'),
                      }) + ' w-full'
                    }
                  />
                  <label
                    htmlFor="email"
                    className={classNames({
                      'p-error': isFormFieldValid('email'),
                    })}
                  >
                    Email
                  </label>
                </span>
                {getFormErrorMessage('email')}
              </div>
              <div className="field lg:col-3 md:col-6 col-12">
                <span className="p-float-label">
                  <InputMask
                    id="phone"
                    mask="+7 (999) 999 99 99"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    className={
                      classNames({
                        'p-invalid': isFormFieldValid('phone'),
                      }) + ' w-full'
                    }
                  />
                  <label
                    htmlFor="phone"
                    className={classNames({
                      'p-error': isFormFieldValid('phone'),
                    })}
                  >
                    Телефон
                  </label>
                </span>
                {getFormErrorMessage('phone')}
              </div>
              <div className="field lg:col-3 md:col-6 col-12">
                <span className="p-float-label">
                  <InputText
                    id="country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    className={
                      classNames({
                        'p-invalid': isFormFieldValid('country'),
                      }) + ' w-full'
                    }
                  />
                  <label
                    htmlFor="country"
                    className={classNames({
                      'p-error': isFormFieldValid('country'),
                    })}
                  >
                    Страна
                  </label>
                </span>
                {getFormErrorMessage('country')}
              </div>
              <div className="field lg:col-3 md:col-6 col-12">
                <span className="p-float-label">
                  <InputText
                    id="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    className={
                      classNames({
                        'p-invalid': isFormFieldValid('city'),
                      }) + ' w-full'
                    }
                  />
                  <label
                    htmlFor="city"
                    className={classNames({
                      'p-error': isFormFieldValid('city'),
                    })}
                  >
                    Город
                  </label>
                </span>
                {getFormErrorMessage('city')}
              </div>
              <div className="field lg:col-3 md:col-6 col-12">
                <span className="p-float-label">
                  <InputText
                    id="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    className={
                      classNames({
                        'p-invalid': isFormFieldValid('address'),
                      }) + ' w-full'
                    }
                  />
                  <label
                    htmlFor="address"
                    className={classNames({
                      'p-error': isFormFieldValid('address'),
                    })}
                  >
                    Улица, дом
                  </label>
                </span>
                {getFormErrorMessage('address')}
              </div>
              {profileCompleted ? (
                <div className="field lg:col-3 md:col-6 col-12">
                  <span className="p-float-label">
                    <InputNumber
                      readOnly
                      id="contractNumber"
                      value={Number(formik.values.contractNumber)}
                      onChange={formik.handleChange}
                      className={
                        classNames({
                          'p-invalid': isFormFieldValid('contractNumber'),
                        }) + ' w-full'
                      }
                    />
                    <label
                      htmlFor="contractNumber"
                      className={classNames({
                        'p-error': isFormFieldValid('contractNumber'),
                      })}
                    >
                      № договора
                    </label>
                  </span>
                  {getFormErrorMessage('contractNumber')}
                </div>
              ) : (
                <div className="field flex flex-column lg:col-3 md:col-6 col-12">
                  <span className="p-float-label p-input-icon-right">
                    {visible ? (
                      <i className="pi pi-eye" onClick={toggleVisible} />
                    ) : (
                      <i className="pi pi-eye-slash" onClick={toggleVisible} />
                    )}
                    <InputText
                      id="password"
                      type={visible ? 'text' : 'password'}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      className={classNames(
                        {
                          'p-invalid': isFormFieldValid('password'),
                        },
                        'w-full'
                      )}
                    />
                    <label
                      htmlFor="password"
                      className={classNames({
                        'p-error': isFormFieldValid('password'),
                      })}
                    >
                      Пароль
                    </label>
                  </span>
                  {getFormErrorMessage('password')}
                </div>
              )}
              <div className="col-12 flex justify-content-center">
                <Button type="submit" loading={buttonLoading}>
                  Сохранить
                </Button>
              </div>
            </form>
          </div>
        )}
        <Toast ref={toastRef} />
      </div>
    </StudentLayout>
  )
}
