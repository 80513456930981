import { useGetShortStatisticsQuery, useGetStatisticsQuery } from '../../../api'
import { Link, useParams } from 'react-router-dom'
import { StudentLayout } from '../../../components/Layout/student/StudentLayout'
import { Helmet } from 'react-helmet'
import Loader from '../../../components/Loader'
import React from 'react'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import { getTime } from '../../../utils/date/getTime'
import { map } from 'lodash'
import { dayWorker } from '../../../utils/dateWorker'
import {
  dateFormat,
  dateFormatWithTime,
  displayFormat,
} from '../../../constants/constants'
import { EduPaths } from '../../../components/Routing/Routing.types'
import { Avatar } from '../../../components/Avatar'
import cifra from '../../../static/cifra.svg'

export const Statistic = () => {
  const { id } = useParams()
  const { isLoading, data } = useGetShortStatisticsQuery({
    studentId: Number(id),
  })
  useGetStatisticsQuery({
    studentId: Number(id),
  })
  const infoData = data?.infoData
  const shortInfoData = data?.shortInfoData

  return (
    <StudentLayout>
      <Helmet title={'Просмотр студента'} />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="grid">
              <div className="flex flex-column lg:col-4 col-12 card py-0">
                <div className="header flex align-items-center justify-content-between points-bg">
                  <div className="title">Студент</div>
                </div>
                <div className="content flex-1">
                  <div className="area bg">
                    <div
                      className={'flex flex-column align-items-center gap-2'}
                    >
                      <Avatar
                        width={124}
                        height={124}
                        src={shortInfoData?.student.avatarURL}
                      />
                      <div
                        className={
                          'flex flex-column gap-2 text-center align-items-center'
                        }
                      >
                        <div className="title points-text">{`${shortInfoData?.student.firstName} ${shortInfoData?.student.lastName} ${shortInfoData?.student.patronymicName}`}</div>
                        <div>{shortInfoData?.curGroup}</div>
                        <div className="coin flex align-items-center">
                          <img src={cifra} alt="cifra" />
                          <div className="text">{shortInfoData?.coins}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:col-4 col-12 card py-0 md-mt-24">
                <div className="header flex align-items-center justify-content-between points-bg">
                  <div className="title">Успеваемость</div>
                </div>
                <div className="content">
                  <div className="area bg" style={{ height: '137.117px' }}>
                    <div className="title points-text">Средний балл</div>
                    <div className="flex align-items-end text">
                      <div className="points-text h1 border">
                        {infoData?.points.curMeanPoint}
                      </div>
                      {map(infoData?.points.prevMeanPoints, (point, idx) => (
                        <div
                          className={`h${idx + 2 > 3 ? 3 : idx + 2} border`}
                          key={point}
                        >
                          {point}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="grid">
                    {infoData?.points.points.length ? (
                      infoData?.points.points.map((point, idx) => (
                        <div
                          className="col-4 py-0 xs-col-6"
                          id={classNames({
                            first: idx <= 1,
                          })}
                          key={point.date + point.point}
                        >
                          <div className="area bg" style={{ height: 152.233 }}>
                            <div className="title">Получено</div>
                            <div className="h1 dark">{point.point}</div>
                            <small className="date">
                              {dayWorker(point.date, dateFormatWithTime).format(
                                dateFormat
                              )}
                            </small>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-4 py-0 xs-col-6" id="first">
                        <div className="area bg" style={{ height: 152.233 }}>
                          <div className="title">Пока нет оценок</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="lg:col-4 col-12 card py-0 md-mt-24">
                <div className="header flex align-items-center justify-content-between homework-bg">
                  <div className="title">Домашнее задание</div>
                </div>
                <div className="content">
                  <div className="grid mt-0 h-full">
                    <div
                      className={
                        (infoData?.homework.curTask?.name
                          ? 'col-7'
                          : 'col-12') + ' xs-col-12 py-0 relative'
                      }
                    >
                      <div className="grid mt-0">
                        <div className="col-6 py-0">
                          <div className="area bg" style={{ height: 137.117 }}>
                            <div className="title homework-text">
                              Всего заданий
                              <br />
                            </div>
                            <div className="h1 dark">
                              {infoData?.homework.allTasks}
                            </div>
                          </div>
                        </div>
                        <div className="col-6 py-0">
                          <div className="area bg" style={{ height: 137.117 }}>
                            <div className="title">Текущие задания</div>
                            <div className="h1 dark">
                              {infoData?.homework.curTasks}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid">
                        <div className="col-6 py-0">
                          <div className="area bg" style={{ height: 152.233 }}>
                            <div className="title">Просрочено заданий</div>
                            <div className="h1 dark">
                              {infoData?.homework.passedTasks}
                            </div>
                          </div>
                        </div>
                        <div className="col-6 py-0">
                          <div className="area bg" style={{ height: 152.233 }}>
                            <div className="title">Проверено заданий</div>
                            <div className="h1 dark">
                              {infoData?.homework.completedTasks}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {infoData?.homework.curTask?.name ? (
                      <div className="col-5 xs-col-12 py-0">
                        <div className="area overflow-y-scroll flex flex-column justify-content-between h-full">
                          <div>
                            <div className="title light sm-mt-24">
                              Ближайшее
                            </div>
                            <div className="text bold fz-20">
                              {infoData?.homework.curTask.name}
                            </div>
                            <small className="text">
                              {infoData?.homework.curTask.description}
                            </small>
                            <small className="text bold">
                              {infoData?.homework.curTask.tags}
                            </small>
                          </div>
                          <small className="text date">
                            До{' '}
                            {dayWorker(
                              infoData?.homework.curTask.deadline,
                              dateFormatWithTime
                            ).format(dateFormatWithTime)}
                          </small>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="lg:col-4 col-12 card py-0">
                <div className="header flex align-items-center justify-content-between materials-bg">
                  <div className="title">Учебные материалы</div>
                </div>
                <div className="content" style={{ height: 305.35 }}>
                  {infoData?.materials?.name ? (
                    <div className="area flex flex-column justify-content-between h-full overflow-y-scroll">
                      <div>
                        <div className="flex justify-content-between">
                          <div className="title materials-text">
                            Последнее поступление:
                          </div>
                          {/*<div className="preview">
                            <img
                              src={info.materials.previewImage}
                              alt="preview"
                            />
                          </div>*/}
                        </div>
                        <small className="date">
                          {dayWorker(
                            infoData?.materials.createdDate,
                            dateFormat
                          ).format(displayFormat)}
                        </small>
                        <div className="text fz-20 mt-6">
                          {infoData?.materials.theme}
                        </div>
                        <div className="text fz-20 bold mt-24">
                          {infoData?.materials.name}
                        </div>
                      </div>
                      <small className="date mt-24">
                        {infoData?.materials.source}
                      </small>
                    </div>
                  ) : (
                    <div className="text fz-20">Здесь пока ничего нет</div>
                  )}
                </div>
              </div>
              <div className="lg:col-4 col-12 card py-0 md-mt-24">
                <div className="header flex align-items-center justify-content-between timetable-bg">
                  <div className="title">Расписание</div>
                </div>
                <div className="content" style={{ height: 305.35 }}>
                  {infoData?.timetable?.theme ? (
                    <div className="area overflow-y-scroll max-h-full">
                      <div className="title timetable-text">
                        Следующее занятие:
                      </div>
                      <div className="text fz-20 bold mt-6">
                        {dayWorker(
                          infoData?.timetable.datetime,
                          dateFormatWithTime
                        ).format(dateFormatWithTime)}
                        <br />
                        {getTime(infoData?.timetable.datetime)}
                      </div>
                      <small className="date mt-24">Тема занятия:</small>
                      <div className="text fz-20 bold mt-6">
                        {infoData?.timetable.theme}
                      </div>
                    </div>
                  ) : (
                    <div className="text fz-20">Здесь пока ничего нет</div>
                  )}
                </div>
              </div>
              <div className="lg:col-4 col-12 card py-0 md-mt-24 payment-card">
                <div className="header flex align-items-center justify-content-between payment-bg">
                  <div className="title">Посещаемость</div>
                  <Link to={`${EduPaths.attendanceStatistic}/${id}`}>
                    <Button
                      icon="pi pi-chevron-right white"
                      className="p-button-rounded p-button-outlined"
                    />
                  </Link>
                </div>
                <div
                  className="content overflow-y-scroll max-h-full"
                  style={{ height: 305.35 }}
                >
                  {data?.visitsModel ? (
                    <>
                      <div className="area bg">
                        <div className="title">Пар было</div>
                        <div className="h1 dark">
                          {data?.visitsModel.countLesson}
                        </div>
                        <div className="title mt-2">Присутствовал на</div>
                        <div className="h1 dark">
                          {data?.visitsModel.present}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="text fz-20">Здесь пока ничего нет</div>
                  )}
                </div>
              </div>
            </div>
          </>
        </>
      )}
    </StudentLayout>
  )
}
