import styles from '../AttendantPage.module.scss'
import { Column } from 'primereact/column'
import {
  getMarkClassname,
  isDisabled,
  marks,
  RowData,
  Statuses,
} from '../utils/utils'
import { DropdownChangeParams } from 'primereact/dropdown'
import { classNames } from 'primereact/utils'
import { Rating } from './Rating'
import { InputTextarea } from 'primereact/inputtextarea'
import { DataTable } from 'primereact/datatable'
import React from 'react'
import { AttendantStudent } from '../../../api/endpoints/attendant/attendant.types'
import { SelectItemOptionsType } from 'primereact/selectitem'
import { map } from 'lodash'
import { Dropdown } from '../../../components/Dropdown'

interface AttendantTableProps {
  disabled?: boolean
  data: Array<AttendantStudent>
  statusLoading: boolean
  statusOptions: SelectItemOptionsType
  onStatusChange: (e: DropdownChangeParams, rowData: RowData) => void
  onMarkChange: (e: DropdownChangeParams, rowData: RowData) => void
  onRewardChange: (reward: number, rowData: RowData) => void
  onCommentChange: (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    rowData: RowData
  ) => void
}

export const AttendantTable = ({
  disabled,
  data,
  statusOptions,
  statusLoading,
  onStatusChange,
  onMarkChange,
  onRewardChange,
  onCommentChange,
}: AttendantTableProps) => {
  return (
    <DataTable
      className={styles.tableWrapper}
      tableClassName={styles.table}
      value={data}
      responsiveLayout={'scroll'}
    >
      <Column field={'fio'} header={'Студент'} align={'left'} />
      <Column
        field={'status'}
        header={'Статус'}
        align={'left'}
        style={{ minWidth: 204 }}
        body={(rowData: RowData) => {
          return (
            <Dropdown
              disabled={disabled}
              loading={statusLoading}
              value={rowData.presentStudent.status}
              className={classNames(
                'w-full',
                rowData.presentStudent.status !== null &&
                  Statuses[rowData.presentStudent?.status]?.className
              )}
              onChange={event => onStatusChange(event, rowData)}
              options={statusOptions}
            />
          )
        }}
      />
      <Column
        field={'mark'}
        header={'Работа в классе'}
        style={{ minWidth: 230 }}
        align={'left'}
        body={(rowData: RowData) => {
          return (
            <Dropdown
              value={rowData.presentStudent.mark}
              className={classNames(
                'w-full',
                getMarkClassname(rowData.presentStudent.mark)
              )}
              disabled={disabled || isDisabled(rowData)}
              onChange={event => onMarkChange(event, rowData)}
              options={map(marks, (mark, index) => ({
                value: index,
                label: mark,
                className: getMarkClassname(index),
              }))}
            />
          )
        }}
      />
      <Column
        field={'status'}
        header={'Награда'}
        style={{ width: 142 }}
        align={'left'}
        body={(rowData: RowData) => {
          return (
            <Rating
              disabled={disabled || isDisabled(rowData)}
              value={rowData.presentStudent.reward}
              onChange={reward => onRewardChange(reward, rowData)}
            />
          )
        }}
      />
      <Column
        field={'comment'}
        header={'Комментарий'}
        align={'left'}
        body={(rowData: RowData) => {
          return (
            <InputTextarea
              className={'w-full'}
              value={rowData.presentStudent.comment || ''}
              disabled={disabled || isDisabled(rowData)}
              onChange={event => onCommentChange(event, rowData)}
            />
          )
        }}
      />
    </DataTable>
  )
}
