import { getPayment } from './payment.actions'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPaymentData, IPaymentState } from './payment.types'

const initialState: IPaymentState = {
  data: {
    totalPayment: 0,
    paymentHistory: [],
    purposeOfPayment: '',
    paymentAccount: '',
    qr: '',
    requisites: '',
  },
  isLoading: false,
  hasLoaded: false,
}

export const paymentSlice = createSlice({
  name: 'student/payment',
  initialState,
  reducers: {},
  extraReducers: {
    [getPayment.pending.type]: state => {
      state.isLoading = true
    },

    [getPayment.fulfilled.type]: (
      state,
      action: PayloadAction<IPaymentData>
    ) => {
      state.isLoading = false
      state.hasLoaded = true
      state.data = action.payload
    },

    [getPayment.rejected.type]: state => {
      state.isLoading = false
    },
  },
})

export default paymentSlice.reducer
