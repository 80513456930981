import { IChartData } from './chat.types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getChats = createAsyncThunk<IChartData, undefined>(
  'student/chat/getChats',
  async (_, thunkAPI) => {
    try {
      // todo make request

      return {
        telegramLink: '',
        whatsappLink: '',
        chatGroups: [
          {
            name: 'Преподаватели',
            chats: [
              {
                id: 1,
                avatar:
                  'https://avatars.mds.yandex.net/get-kino-vod-films-gallery/28788/47e2fd514411e18b76af786d7417062d/100x64_3',
                name: 'Английский Виктор Павлович',
                subject: 'C#',
                unreadMessages: 0,
                messages: [
                  {
                    message:
                      'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.',
                    sender: 'others',
                    sentDate: '2023-01-09T14:34:23.831Z',
                  },
                  {
                    message:
                      'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.',
                    sender: 'others',
                    sentDate: '2023-01-09T15:34:23.831Z',
                  },
                  {
                    message:
                      'Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.',
                    sender: 'me',
                    sentDate: '2023-01-09T16:34:23.831Z',
                  },
                ],
              },
              {
                id: 2,
                avatar:
                  'https://gh.cdn.sewest.net/assets/ident/news/d81c6286/Avenger_Blog_IronMan_Imagery5-q4grmvi70.jpg?quality=65',
                name: 'Болгарский Антон Сергеевич',
                subject: 'Python',
                unreadMessages: 2,
                messages: [],
              },
              {
                id: 3,
                avatar:
                  'https://static.wikia.nocookie.net/marvelcinematicuniverse/images/7/79/Hulk_in_the_She-Hulk_series_-_Infobox.jpg/revision/latest?cb=20220819171053',
                name: 'Венгерская Виктория Олеговна',
                subject: 'Java',
                unreadMessages: 14,
                messages: [],
              },
            ],
          },
        ],
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(
        'Страница с чатами не смогла загрузиться, что же делать, что же делать..'
      )
    }
  }
)

export const clearUnreadMessages = createAsyncThunk<
  { groupIDX: number; chatID: number },
  {
    id: number
    groupIDX: number
    chatID: number
  }
>('student/chat/clearUnreadMessages', async ({ id, groupIDX, chatID }) => {
  //  make request

  return {
    groupIDX,
    chatID,
  }
})

export const sendMessage = createAsyncThunk<
  { groupIDX: number; chatID: number; message: string },
  {
    id: number
    groupIDX: number
    chatID: number
    message: string
  }
>(
  'student/chat/sendMessage',
  async ({ id, groupIDX, chatID, message }, thunkAPI) => {
    try {
      // todo make request

      return {
        groupIDX,
        chatID,
        message,
      }
    } catch (e) {
      return thunkAPI.rejectWithValue('')
    }
  }
)
