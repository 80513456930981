import { baseApi } from '../../baseApi'
import {
  AddTimetableArg,
  DeleteTimetableArg,
  finishLessonArg,
  GetDataForCreateTimetableResult,
  GetTimetableScheduleArgs,
  GetTimetableScheduleResult,
  RemoveTimetableRangeArg,
  UpdateTimetableArg,
} from './timetable.types'

const api = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTimetableSchedule: builder.query<
      GetTimetableScheduleResult,
      GetTimetableScheduleArgs
    >({
      providesTags: ['Timetable'],
      query: arg => ({
        url: '/timetable/getTimetableDataSchedule',
        method: 'get',
        errorMessage: 'Не удалось получить информацию о расписании!',
        params: arg,
      }),
    }),
    getDataForCreateTimetable: builder.query<
      GetDataForCreateTimetableResult,
      void
    >({
      providesTags: ['Group', 'Audience', 'User'],
      query: arg => ({
        url: '/timetable/getDataForCreateTimetable',
        method: 'get',
        errorMessage: 'Не удалось получить информацию для создания расписания!',
        params: arg,
      }),
    }),
    finishLesson: builder.mutation<void | null, finishLessonArg>({
      invalidatesTags: ['LessonFinished'],
      query: arg => ({
        url: '/timetable/lessonFinish',
        method: 'post',
        data: arg,
        errorMessage: 'Не удалось закончить пару!',
      }),
    }),
    addTimetable: builder.mutation<void, AddTimetableArg>({
      invalidatesTags: ['Timetable'],
      query: arg => ({
        url: '/timetable/createTimeTable',
        method: 'post',
        data: arg,
        errorMessage: 'Не удалось добавить расписание!',
      }),
    }),
    updateTimetable: builder.mutation<void, UpdateTimetableArg>({
      invalidatesTags: ['Timetable'],
      query: arg => ({
        url: '/timetable/editLesson',
        method: 'post',
        data: arg,
        errorMessage: 'Не удалось обновить расписание!',
      }),
    }),
    deleteTimetable: builder.mutation<void, DeleteTimetableArg>({
      invalidatesTags: ['Timetable'],
      query: arg => ({
        url: '/timetable/deleteTimetable',
        method: 'delete',
        data: arg,
        errorMessage: 'Не удалось удалить расписание!',
      }),
    }),
    deleteTimetableRange: builder.mutation<void, RemoveTimetableRangeArg>({
      invalidatesTags: ['Timetable'],
      query: arg => ({
        url: '/timetable/deleteTimetableRange',
        method: 'delete',
        data: arg,
        errorMessage: 'Не удалось удалить расписание!',
      }),
    }),
  }),
})

export const {
  useDeleteTimetableRangeMutation,
  useAddTimetableMutation,
  useDeleteTimetableMutation,
  useUpdateTimetableMutation,
  useGetTimetableScheduleQuery,
  useGetDataForCreateTimetableQuery,
  useFinishLessonMutation,
} = api
