import { baseApi } from '../../baseApi'
import {
  getNearestDataLessonResult,
  getPerformanceGroupArg,
  getPerformanceGroupResult,
  getPresentsFilterResult,
  getSubjectNameByGroupArg,
  getSubjectNameByGroupResult,
  isFinishLessonArg,
} from './attendant.types'

const api = baseApi.injectEndpoints({
  endpoints: builder => ({
    getPresentsFilter: builder.query<getPresentsFilterResult, void>({
      query: _ => ({
        url: 'performance/getPresentsFilterTeacher',
        method: 'get',
        errorMessage: 'Не удалось получить информацию о фильтрах!',
      }),
    }),
    getPresentsDataTeacher: builder.query<
      getNearestDataLessonResult,
      { lessonId: number }
    >({
      query: params => ({
        url: 'performance/getPresentsDataTeacher',
        method: 'get',
        params,
        errorMessage: 'Не удалось получить информацию о присутствующих!',
      }),
    }),
    getSubjectNameByGroup: builder.query<
      getSubjectNameByGroupResult,
      getSubjectNameByGroupArg
    >({
      query: params => ({
        url: 'performance/getSubjectNameByGroup',
        method: 'get',
        params,
        errorMessage: 'Не удалось получить информацию о предметах!',
      }),
    }),
    getPerformanceGroup: builder.query<
      getPerformanceGroupResult,
      getPerformanceGroupArg
    >({
      query: params => ({
        url: 'performance/getPerformanceGroup',
        method: 'get',
        params,
        errorMessage: 'Не удалось получить информацию о присутствующих!',
      }),
    }),
    isFinishLesson: builder.query<boolean, isFinishLessonArg>({
      providesTags: ['LessonFinished'],
      query: params => ({
        url: 'performance/isFinishLesson',
        method: 'get',
        params,
        errorMessage: 'Не удалось получить информацию о присутствующих!',
      }),
    }),
  }),
})

export const {
  useGetPresentsFilterQuery,
  useGetPresentsDataTeacherQuery,
  useGetPerformanceGroupQuery,
  useGetSubjectNameByGroupQuery,
  useIsFinishLessonQuery,
} = api
