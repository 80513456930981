import React, { useEffect, useRef, useState } from 'react'
import { Card } from '../../components/Card/Card'
import { StudentLayout } from '../../components/Layout/student/StudentLayout'
import styles from './AttendantPage.module.scss'
import { Dropdown } from 'primereact/dropdown'
import { find, map, size } from 'lodash'
import useAppDispatch from '../../hooks/useAppDispatch'
import {
  getNearestDataLesson,
  savePresentUserData,
  savePresentUserDataList,
  uploadMaterialAndHomework,
} from '../../store/admin/attendant/attendant.actions'
import { NotificationTypes, notify } from '../../utils/notify'
import { Toast } from 'primereact/toast'
import { Helmet } from 'react-helmet'
import useAppSelector from '../../hooks/useAppSelector'
import Loader from '../../components/Loader'
import {
  AttendantStudent,
  INearestLesson,
} from '../../store/admin/attendant/attendant.types'
import { Modal } from '../../components/Modal/Modal'
import { Form, ModalContent } from './components/Modal'
import { dayWorker } from '../../utils/dateWorker'
import { dateFormatWithTime } from '../../constants/constants'
import { NearestLesson } from '../../components/NearestLesson/NearestLesson'
import { ClassType } from '../../components/ClassType/ClassType'
import { Button } from 'primereact/button'
import {
  useGetPresentsDataTeacherQuery,
  useGetPresentsFilterQuery,
  useIsFinishLessonQuery,
} from '../../api/endpoints/attendant'
import {
  AttendantGroup,
  AttendantSubject,
} from '../../api/endpoints/attendant/attendant.types'
import { Lesson } from '../../components/Lesson/Lesson'
import { FormItem, RowData } from './utils/utils'
import { AttendantTable } from './components/Table'
import { ConfirmButton } from './components/ConfirmButton'
import { useFinishLessonMutation } from '../../api/endpoints/timetable'

interface AttendantPageProps {
  allowEdit?: boolean
}
export const AttendantPage = ({ allowEdit }: AttendantPageProps) => {
  const [data, setData] = useState<Array<RowData>>([])
  const [classType, setClassType] = useState<number | null>(null)
  const dispatch = useAppDispatch()
  const { data: filters, isLoading: areFiltersLoading } =
    useGetPresentsFilterQuery(null, {
      skip: allowEdit,
      refetchOnMountOrArgChange: true,
    })
  const { pending } = useAppSelector(
    state => state.adminAttendantSlice.getNearestDataLesson
  )
  const nearestLessons = useAppSelector(
    state => state.adminAttendantSlice.nearestLessons
  )
  const [activeLesson, setActiveLesson] = useState<INearestLesson | null>(null)
  const [group, setGroup] = useState<AttendantGroup | null>(null)
  const [subject, setSubject] = useState<AttendantSubject | null>(null)
  const [lessonNumber, setLessonNumber] = useState<number | null>(null)
  const typeStatuses = useAppSelector(
    state => state.adminAttendantSlice.typeStatuses
  )
  const { data: presentsData, refetch } = useGetPresentsDataTeacherQuery(
    {
      lessonId: lessonNumber,
    },
    { skip: !lessonNumber, refetchOnMountOrArgChange: true }
  )

  const { data: isFinished, isLoading: isFinishedLoading } =
    useIsFinishLessonQuery(
      { lessonId: activeLesson?.lessons?.lessonId },
      {
        skip: !allowEdit || !activeLesson?.lessons?.lessonId,
        refetchOnMountOrArgChange: true,
      }
    )
  const [finish, { isLoading: isFinishLoading }] = useFinishLessonMutation()
  console.log(isFinished)
  const typeMarks = useAppSelector(state => state.adminAttendantSlice.typeMarks)
  const toast = useRef<Toast>(null)
  const [modal, setModal] = useState(false)
  function getData(setInitial = false) {
    if (allowEdit) {
      dispatch(getNearestDataLesson())
        .unwrap()
        .then(response => {
          if (response) {
            if (setInitial && size(response.nearestLessons)) {
              const lesson = find(response.nearestLessons, e => e.lessons.now)
              if (lesson) setActiveLesson(lesson)
            } else {
              if (activeLesson) {
                const lesson = find(
                  response.nearestLessons,
                  e => e.lessons.lessonId === activeLesson.lessons.lessonId
                )
                if (lesson) setActiveLesson(lesson)
              }
            }
          }
        })
    }
  }

  useEffect(() => {
    getData(true)
  }, [])

  useEffect(() => {
    if (activeLesson?.academicDataLessonTeacher?.academicStudentModels) {
      setData(activeLesson?.academicDataLessonTeacher?.academicStudentModels)
    }
  }, [activeLesson])

  useEffect(() => {
    setSubject(null)
    setLessonNumber(null)
  }, [group])

  useEffect(() => {
    setLessonNumber(null)
  }, [subject])

  useEffect(() => {
    if (presentsData) {
      setData(presentsData.academicStudentModels)
    }
  }, [presentsData?.academicStudentModels])

  const timer = useRef<NodeJS.Timeout>()
  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [])

  function onRow(
    id: number,
    value: Partial<
      Record<
        keyof Pick<AttendantStudent, 'presentStudent'>,
        keyof Pick<AttendantStudent, 'presentStudent'>
      >
    >
  ) {
    setData(prevState =>
      map(prevState, row =>
        row.presentStudent.id === id
          ? { ...row, presentStudent: { ...row.presentStudent, ...value } }
          : row
      )
    )
  }

  function onSingleUpdate(
    id: number,
    value: Partial<
      Record<
        keyof Pick<AttendantStudent, 'presentStudent'>,
        keyof Pick<AttendantStudent, 'presentStudent'>
      >
    >
  ) {
    const localData = JSON.parse(JSON.stringify(data))
    const student = find(data, findStudent => {
      return findStudent.presentStudent.id === id
    })
    if (!student || (!classType && classType !== 0)) return
    onRow(id, value)
    dispatch(
      savePresentUserData({
        ...student.presentStudent,
        ...value,
        typeMark: classType,
      })
    )
      .unwrap()
      .then(_ => {
        notify(
          toast,
          {
            type: NotificationTypes.success,
            content: 'Информация о студентах успешно сохранена!',
          },
          true
        )
      })
      .catch(e => {
        setData(localData)
      })
  }
  function onMultipleUpdate(
    id: number,
    value: Partial<
      Record<
        keyof Pick<AttendantStudent, 'presentStudent'>,
        keyof Pick<AttendantStudent, 'presentStudent'>
      >
    >
  ) {
    onRow(id, value)
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      setData(prevState => {
        if (classType || classType === 0) {
          dispatch(
            savePresentUserDataList(
              map(prevState, user => ({
                ...user.presentStudent,
                typeMark: classType,
              }))
            )
          )
            .unwrap()
            .then(_ => {
              notify(
                toast,
                {
                  type: NotificationTypes.success,
                  content: 'Информация о студентах успешно сохранена!',
                },
                true
              )
            })
        }
        return prevState
      })
    }, 30000)
  }

  function onSubmit(form: Form) {
    if (activeLesson === null && !lessonNumber) return
    dispatch(
      uploadMaterialAndHomework({
        material_id: form.material,
        lesson_id: activeLesson?.lessons?.lessonId || lessonNumber,
        cur_group: activeLesson?.lessons?.groupId || group?.group?.id,
        ...(form.deadLine && {
          deadline: dayWorker(form.deadLine).format(dateFormatWithTime),
        }),
      })
    )
      .unwrap()
      .then(_ => {
        setModal(false)
        allowEdit ? getData() : refetch()
        notify(
          toast,
          {
            type: NotificationTypes.success,
            content: 'Методический материал успешно прикреплен!',
          },
          true
        )
      })
  }

  function onFinish() {
    if (!activeLesson) return
    if (timer.current) clearTimeout(timer.current)
    dispatch(
      savePresentUserDataList(
        map(data, user => ({
          ...user.presentStudent,
          typeMark: classType,
        }))
      )
    )
      .unwrap()
      .then(_ => {
        notify(
          toast,
          {
            type: NotificationTypes.success,
            content: 'Информация о студентах успешно сохранена!',
          },
          true
        )
      })
    finish({ lessonId: activeLesson.lessons.lessonId })
      .unwrap()
      .then(_ => {
        notify(
          toast,
          {
            type: NotificationTypes.success,
            content: 'Пара завершена!',
          },
          true
        )
      })
  }

  const packageModel =
    activeLesson?.academicDataLessonTeacher?.methodPackageModel ||
    presentsData?.methodPackageModel

  return (
    <StudentLayout>
      <Toast ref={toast} />
      <Helmet title={'Присутствующие'} />
      <Card
        header={'Присутствующие'}
        className={'mt-24'}
        headerClassName={styles.header}
        contentClassName={'p-24'}
      >
        <Modal
          header={'Методический материал'}
          visible={modal}
          onHide={() => setModal(false)}
        >
          <ModalContent
            onSubmit={onSubmit}
            subjectId={subject?.subjectId}
            initialValues={
              packageModel
                ? {
                    deadLine: packageModel.deadline
                      ? dayWorker(
                          packageModel.deadline,
                          dateFormatWithTime
                        ).toDate()
                      : new Date(),
                    material: packageModel.material,
                  }
                : null
            }
          />
        </Modal>
        {!allowEdit ? (
          filters ? (
            <>
              <FormItem label={'Выбор группы'}>
                <div className={'flex gap-24'}>
                  <Dropdown
                    filter
                    value={group}
                    options={map(
                      filters.filtersTeacherData.filtersTeacherGroupSubjects,
                      subjects => {
                        return {
                          value: subjects,
                          label: subjects.group.curGroup,
                        }
                      }
                    )}
                    onChange={e => setGroup(e.value)}
                  />
                  {group ? (
                    <Dropdown
                      filter
                      value={subject}
                      options={map(group.filtersTeacherSubjects, subject => {
                        const { subject: subjectName } = subject
                        return {
                          value: subject,
                          label: subjectName,
                        }
                      })}
                      onChange={e => setSubject(e.value)}
                    />
                  ) : null}
                </div>
              </FormItem>
              {subject ? (
                <FormItem label={'Урок №'}>
                  <div className={styles.classWrapper}>
                    {size(subject.filtersTeacherLessons)
                      ? map(
                          subject.filtersTeacherLessons,
                          ({ numberLesson, lessonId, active }) => (
                            <Lesson
                              key={lessonId}
                              disabled={!active}
                              active={lessonId === lessonNumber}
                              onClick={() => setLessonNumber(lessonId)}
                            >
                              {numberLesson}
                            </Lesson>
                          )
                        )
                      : 'Уроки отсутсвуют'}
                  </div>
                </FormItem>
              ) : null}
            </>
          ) : (
            <Loader />
          )
        ) : null}
        {size(nearestLessons) || lessonNumber ? (
          pending ? (
            <Loader />
          ) : (
            <>
              {allowEdit && size(nearestLessons) ? (
                <div className={'grid'}>
                  {map(nearestLessons, lesson => {
                    const { lessons } = lesson
                    return (
                      <div key={lessons.lessonId} className={'col-12 md:col-6'}>
                        <NearestLesson
                          audience={lessons.audienceName}
                          activeLesson={
                            lessons.lessonId === activeLesson?.lessons.lessonId
                          }
                          startDate={lessons.startDate}
                          endDate={lessons.endDate}
                          subject={lessons.subject}
                          group={lessons.group}
                          active={lessons.active}
                          onClick={() => setActiveLesson(lesson)}
                        />
                      </div>
                    )
                  })}
                </div>
              ) : null}
              {activeLesson || lessonNumber ? (
                <FormItem label={'Методический пакет'}>
                  <Button
                    disabled={isFinished === true}
                    onClick={() => setModal(true)}
                  >
                    {activeLesson?.academicDataLessonTeacher
                      ?.methodPackageModel || presentsData?.methodPackageModel
                      ? 'Обновить'
                      : 'Загрузить'}
                  </Button>
                </FormItem>
              ) : null}
              {(activeLesson?.academicDataLessonTeacher?.methodPackageModel ||
                presentsData?.methodPackageModel) &&
              (activeLesson || lessonNumber) ? (
                <FormItem label={'Тип урока'}>
                  <div className={styles.classWrapper}>
                    {map(
                      allowEdit ? typeMarks : filters?.typeMarks,
                      ({ typeId, type }) => (
                        <ClassType
                          key={typeId}
                          active={typeId === classType}
                          onClick={() => setClassType(typeId)}
                        >
                          {type}
                        </ClassType>
                      )
                    )}
                  </div>
                </FormItem>
              ) : null}
              {(activeLesson?.academicDataLessonTeacher?.methodPackageModel ||
                presentsData?.methodPackageModel) &&
              ((activeLesson && activeLesson.lessons.active) ||
                lessonNumber) ? (
                classType || classType === 0 ? (
                  <>
                    {allowEdit ? (
                      <ConfirmButton
                        loading={isFinishedLoading || isFinishLoading}
                        disabled={isFinished === true}
                        onConfirm={onFinish}
                      />
                    ) : null}
                    <AttendantTable
                      statusLoading={allowEdit ? false : areFiltersLoading}
                      disabled={isFinished === true}
                      statusOptions={map(
                        allowEdit ? typeStatuses : filters?.typeStatuses,
                        ({ status, statusId }) => ({
                          value: statusId,
                          label: status,
                        })
                      )}
                      data={data}
                      onStatusChange={(event, rowData) =>
                        onSingleUpdate(rowData.presentStudent.id, {
                          status: event.value,
                        })
                      }
                      onCommentChange={(event, rowData) =>
                        onMultipleUpdate(rowData.presentStudent.id, {
                          comment: event.target.value,
                        })
                      }
                      onRewardChange={(reward, rowData) =>
                        onMultipleUpdate(rowData.presentStudent.id, {
                          reward,
                        })
                      }
                      onMarkChange={(event, rowData) =>
                        onMultipleUpdate(rowData.presentStudent.id, {
                          mark: event.value,
                        })
                      }
                    />
                  </>
                ) : null
              ) : null}
            </>
          )
        ) : allowEdit ? (
          'Нет ближайших уроков'
        ) : null}
      </Card>
    </StudentLayout>
  )
}
