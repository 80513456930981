import React from 'react'
import { baseURL } from '../../http'

interface AvatarProps extends Partial<HTMLImageElement> {
  src: string | undefined
}

export const Avatar = ({ src, ...rest }: AvatarProps) => {
  return (
    <img
      style={{
        aspectRatio: 1,
        borderRadius: '100%',
      }}
      src={`${baseURL}/${src}`}
      {...rest}
    />
  )
}
