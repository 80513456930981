import { IShortInfoData, IGetStudentInfoAction, IInfoData } from './info.types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import http from '../../../http'

export const getStudentShortInfo = createAsyncThunk<IShortInfoData, undefined>(
  'student/info/getStudentShortInfo',
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get('/students/shortInfoData')

      return data
    } catch (e) {
      return thunkAPI.rejectWithValue(
        'Не удалось получить данные пользователя!'
      )
    }
  }
)

export const getStudentInfo = createAsyncThunk<IInfoData, undefined>(
  'student/info/getStudentInfo',
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get('/students/InfoData')

      return data
    } catch (e) {
      return thunkAPI.rejectWithValue(
        'Главная страница не смогла загрузиться, что же делать, что же делать..'
      )
    }
  }
)
