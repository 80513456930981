import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import useAppDispatch from '../../hooks/useAppDispatch'
import useAppSelector from '../../hooks/useAppSelector'
import robot from '../../static/robot.jpg'
import { errorSlice } from '../../store/error/error.slice'
import { logout } from '../../store/user/user.slice'
import style from './ErrorPage.module.scss'

export const ErrorPage: FC = () => {
  const { clearError } = errorSlice.actions
  const error = useAppSelector(state => state.errorReducer.message)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const clickHandler = () => {
    dispatch(clearError())
    navigate('/')
  }

  return (
    <div className={style['error-page']}>
      <div className={style.content}>
        <div className={style.text}>
          <div className={style['text-content']}>
            <h1>{error ? 404 : 200}</h1>
            <div className={style.subtitle}>
              {error ? 'Упс. Что-то пошло не так' : 'Все хорошо'}
            </div>
            <div className={style.message}>
              {error || 'Никаких неполадок не вижу, можешь идти куда шел'}
            </div>
          </div>
          <div className={style['button-container']}>
            <Button onClick={clickHandler}>Вернуться на главную</Button>
            <Button
              className={classNames('p-button-danger', style.logout)}
              onClick={() => dispatch(logout())}
            >
              Выйти из аккаунта
            </Button>
          </div>
        </div>
        <img src={robot} alt="robot" className={style.img} />
      </div>
    </div>
  )
}
