import { parseIOSDate } from './parseIOSDate'

export function getTime(date_: string): string {
  const date = parseIOSDate(date_)

  const hour = date.getHours()
  const minute = date.getMinutes()

  return `${hour >= 10 ? hour : '0' + hour}:${
    minute >= 10 ? minute : '0' + minute
  }`
}
